import Http from '../api/http/';
export default class DataExport extends Http {
  constructor() {
    super();
  }
  /**
   * 添加组织架构
   * @param {Array} departmentDto -  departmentDto
   */
  department_add() {
    return super.post('department/add', ...arguments, {});
  }

  /**
   * 删除组织架构
   * @param {integer} id -  id
   */
  department_delete() {
    return super.post('department/delete', ...arguments);
  }

  /**
   * 获取组织架列表
   * @param {Array} departmentDto -  departmentDto
   */
  department_getDepartmentList() {
    return super.post('department/getDepartmentList', ...arguments, {});
  }

  /**
   * 获取组织架构树
   */
  department_getDepartmentTree() {
    return super.post('department/getDepartmentTree', ...arguments);
  }

  /**
   * 修改组织架构
   * @param {Array} departmentDto -  departmentDto
   */
  department_update() {
    return super.post('department/update', ...arguments, {});
  }
  /**
   * 移动组织架构
   * @param {Array} moveDepartmentDto -  moveDepartmentDto
   */
  department_moveDepartment() {
    return super.post('department/moveDepartment', ...arguments, {});
  }
}
