import Http from '../api/http/';

export default class Content extends Http {
  constructor() {
    super();
  }

  /**
   * 内容列表
   * @param {Array} contentSearchDTO -  contentSearchDTO
   */
  content_list() {
    return super.post('content/list', ...arguments, {});
  }

  /**
   * 内容详情
   * @param {integer} id -  id
   */
  content_getDetail() {
    return super.post('content/getDetail', ...arguments);
  }

  /**
   * 编辑
   * @param {Array} contentEditDTO -  contentEditDTO
   */
  content_edit() {
    return super.post('content/edit', ...arguments, {});
  }
}
