import Http from '../api/http/';

export default class TrainCertificate extends Http {
  constructor() {
    super();
  }
  /**
   * 添加证书
   * @returns
   */
  trainCertificate_add() {
    return super.post('trainCertificate/add', ...arguments);
  }
  /**
   * 批量颁发证书
   * @param { String } uids
   * @param { String } certificateId
   * @returns
   */
  trainCertificate_batchAward() {
    return super.post('trainCertificate/batchAward', ...arguments);
  }
  /**
   * 删除证书
   * @param { String } certificateId
   * @returns
   */
  trainCertificate_delete() {
    return super.post('trainCertificate/delete', ...arguments);
  }
  /**
   * 证书列表
   * @param { String } certificateId
   * @returns
   */
  trainCertificate_detail() {
    return super.post('trainCertificate/detail', ...arguments);
  }
  /**
   * 证书停用
   * @param { String } certificateId
   * @returns
   */
  trainCertificate_disable() {
    return super.post('trainCertificate/disable', ...arguments);
  }
  /**
   * 证书启用
   * @param { String } certificateId
   * @returns
   */
  trainCertificate_enable() {
    return super.post('trainCertificate/enable', ...arguments);
  }
  /**
   * 证书list
   * @param { String } typeId
   * @returns
   */
  trainCertificate_getListByTypeId() {
    return super.post('trainCertificate/getListByTypeId', ...arguments);
  }
  /**
   * 证书列表
   * @returns
   */
  trainCertificate_list() {
    return super.post('trainCertificate/list', ...arguments);
  }
  /**
   * 用户证书吊销
   * @returns
   */
  trainCertificate_revoke() {
    return super.post('trainCertificate/revoke', ...arguments);
  }
  /**
   * 编辑证书
   * @returns
   */
  trainCertificate_update() {
    return super.post('trainCertificate/update', ...arguments);
  }
  /**
   * 获证人数列表
   * @returns
   */
  trainCertificate_userCertificateList() {
    return super.post('trainCertificate/userCertificateList', ...arguments);
  }
  /**
   * 用户获取证书列表
   * @param {string} userId -  用户id
   * @param {integer} certificateStatus -  证书状态 0有效 1无效
   * @param {integer} obtainType -  获取类型 1认证获取 2系统颁发
   * @param {integer} revokeType -  吊销类型 1系统吊销 2管理员吊销
   * @param {string} certificateName -  证书名称
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */
  trainCertificate_getUserCertificateList() {
    return super.post('trainCertificate/getUserCertificateList', ...arguments);
  }
  /**
   * 获取培训证书列表
   */
  trainCertificate_getTrainCertificateTypeIdNameMap() {
    return super.post('trainCertificate/getTrainCertificateTypeIdNameMap', ...arguments);
  }
  /**
   * 获取证书背景图
   * @returns
   */
  trainCertificate_getTrainCertificateBackgroundUrls() {
    return super.post('trainCertificate/getTrainCertificateBackgroundUrls', ...arguments);
  }

  /**
   * 获证人数导出
   * @returns
   */
  trainCertificate_userCertificateOutport() {
    return super.get('trainCertificate/userCertificateOutport', ...arguments, 'download');
  }

  /**
   * 文件导入批量颁发
   * @returns
   */
  trainCertificate_batchImportAward() {
    return super.post('trainCertificate/batchImportAward', ...arguments);
  }
}
