import Http from '../api/http';
export default class Website extends Http {
  constructor() {
    super();
  }
  //官网公告列表
  website_notice_noticeList2P() {
    return super.post('website_notice/noticeList2P', ...arguments);
  }
  //删除官网公告
  website_notice_delete() {
    return super.post('website_notice/delete', ...arguments);
  }
  //官网公告上下线
  website_notice_onOffline() {
    return super.post('website_notice/onOffline', ...arguments);
  }
  //新增官网公告
  website_notice_add() {
    return super.post('website_notice/add', ...arguments, {});
  }
  //公告详情
  website_notice_detail() {
    return super.post('website_notice/detail', ...arguments);
  }
  //官网公告管理修改
  website_notice_update() {
    return super.post('website_notice/update', ...arguments, {});
  }
  /**
   * 官网公告排序
   * @param {array} sortIdList -  需要排序的ids 从小到
   */

  website_notice_sort() {
    return super.post('website_notice/sort', 'formData', ...arguments);
  }
}
