import Http from '../api/http/';
export default class HomePage extends Http {
  constructor() {
    super();
  }
  /***********首页***************/
  /**
   * 首页查看
   */

  homepage_getDataInfo() {
    return super.post('homepage/getDataInfo', ...arguments);
  }

  /**
   * 首页看板
   */
  homepage_dashboard() {
    return super.post('homepage/dashboard', ...arguments);
  }

  /**
   * 帮助列表
   * @returns
   */
  homepage_helpList() {
    return super.post('homepage/helpList', ...arguments);
  }
  /**
   * 更新激励
   * @returns
   */
  homepage_renewRecordList() {
    return super.post('homepage/renewRecordList', ...arguments);
  }
}
