import axios from 'axios';
import Crypto from '@/utils/crypto';

class Behavior {
  constructor() {
    this.env = 'dev';
    this.appCode = 'APP42';
    this.browser = null;
    this.$this = null;
    this.token = null;
    this.s210 = null;
    this.s220 = null;
    this.s501 = null;
    this.key = null;
    this.interval = null;
  }
  init($this) {
    this.$this = $this;
    this.token = $this.api.baseCookie.getCookie('ops_p_token');
    this.env = $this.api.env.getEnv();
    const userInfo = $this.$store.getters.getUserInfo;
    this.s210 = userInfo ? userInfo.companyId : '';
    this.s210 = this.s210 ? this.s210 : '-1';
    this.s220 = userInfo ? userInfo.uid : '';
    this.browser = this._browserInfo();
    this._initKey();
    this.interval = setInterval(() => {
      this._upServiceData();
    }, 60000);
  }
  _initKey() {
    const url = this._keyAddressConfig();
    axios({ method: 'GET', url, params: { appCode: this.appCode } }).then(res => {
      if (res.status == 200) {
        this.key = res.data.result;
      }
    });
  }
  _keyAddressConfig() {
    return 'https://buriedpoint.weihaibi.cn/appCode/getKey';
  }
  _upServiceData() {
    const date = new moment().format('YYYYMMDDHHmmss');
    const nonce = '123456';
    const body = this.$this.$store.getters.getBehaviorBody;
    const url = this._upAddressConfig();
    if (body && body.length) {
      let crypto = new Crypto();
      const sign = crypto.md5(`appCode=${this.appCode}&date=${date}&nonce=${nonce}${this.key}`);
      axios({ method: 'POST', url, data: { body, appCode: this.appCode, date, nonce, sign } }).then(res => {
        if (res.status == 200) {
          if (res.data.code === 0) {
            this.$this.$store.commit('Set_Behavior_Body_Del', body.length);
          }
        }
      });
    }
  }
  _upAddressConfig() {
    return 'https://buriedpoint.weihaibi.cn/lo/re';
  }
  _browserInfo() {
    let userAgent = navigator.userAgent;
    if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
      return 'Opera';
    } else if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1) {
      return 'IE';
    } else if (userAgent.indexOf('Edge') > -1) {
      return 'Edge';
    } else if (userAgent.indexOf('Firefox') > -1) {
      return 'Firefox';
    } else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') == -1) {
      return 'Safari';
    } else if (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1) {
      return 'Chrome';
    } else if (!!window.ActiveXObject || 'ActiveXObject' in window) {
      return 'IE>=11';
    } else {
      return 'unKnown';
    }
  }
  _beforeDataReport(data) {
    const { s500, s503, s531, s502, s504 } = data;
    const s100 = new moment().format('YYYY-MM-DD');
    const s600 = new moment().format('YYYY-MM-DD HH:mm:ss');
    this.$this.$store.commit('Set_Behavior_Body_Add', {
      s100,
      s200: '',
      s210: this.s210, // 租户id
      s220: this.s220, // 用户id
      s230: '',
      s231: '',
      s240: '',
      s300: this.appCode,
      s310: 'v5.0',
      s320: '嗨探P端',
      s330: 'PC',
      s340: this.env == 'pro' ? 'e5' : 'e3',
      s350: 's9',
      s400: '',
      s401: '',
      s402: '',
      s403: '',
      s404: '',
      s405: 'Unknown',
      s406: '',
      s407: this.browser,
      s408: this.browser,
      s409: 'unknown',
      s410: '',
      s411: '',
      s416: '',
      s421: '',
      s422: '',
      s500, // Page_view,page_click,search,ad
      s501: this.s501, // 当前进入页面ID
      s502, // 当前页面ID
      s503, // 数据id
      s504, // 当前页面名称
      s505: '',
      s506: '',
      s507: '',
      s508: '',
      s509: '', // 上级页面ID
      s510: '', // 上级页面名称
      s511: '',
      s512: '',
      s513: '',
      s514: '',
      s515: '',
      s516: '',
      s517: '',
      s518: '',
      s519: 'W1',
      s520: '',
      s531, // 按钮名称
      s600,
      s610: '',
      s620: this.token,
      s630: '',
      s640: '',
      s641: 'F',
    });
  }
  click(data) {
    const { s502, s504 } = data;
    const s500 = 'page_click';
    this._beforeDataReport({ s500, s503: s502, s531: s504 });
  }
  page(to) {
    if (this.$this) {
      const s500 = 'Page_view';
      const s502 = to.name;
      const s504 = to.meta.title;
      this._beforeDataReport({ s500, s502, s504 });
    }
  }
  destroy() {
    this.interval && clearInterval(this.interval);
  }
}
export default {
  install(Vue) {
    if (this.installed) return;
    this.installed = true;
    Vue.prototype.bInstance = new Behavior();
    // 将api属性设置为不可写，为了防止某些插件冲突
    Object.defineProperty(Vue.prototype, 'bInstance', {
      configurable: false, // 不可删除
      writable: false, // 不可写
    });
  },
};
