import Http from '../api/http/';

export default class MonthPlatforminfo extends Http {
  constructor() {
    super();
  }

  //分页查询月度平台统计信息
  monthPlatforminfo_queryListByPagination() {
    return super.post('monthPlatforminfo/queryListByPagination', ...arguments, {});
  }

  //导出月度平台统计信息
  monthPlatforminfo_export() {
    return super.get('monthPlatforminfo/export', ...arguments);
  }

  //按月度查询平台统计明细
  monthDetail_getInfoByMonth() {
    return super.post('monthDetail/getInfoByMonth', ...arguments, {});
  }

  //导出月度平台统计明细
  monthDetail_export() {
    return super.get('monthDetail/export', ...arguments);
  }

  //生成月度平台统计信息刷新 yearMonthDay
  monthPlatforminfo_generateMonthPlatformInfo() {
    return super.post('monthPlatforminfo/generateMonthPlatformInfo', ...arguments);
  }
  // 生成年度平台统计信息刷新 year
  monthPlatforminfo_generateYearPlatformInfo() {
    return super.post('monthPlatforminfo/generateYearPlatformInfo', ...arguments);
  }
}
