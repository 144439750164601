import Http from '../api/http/';
export default class QuestionFeed extends Http {
  constructor() {
    super();
  }

  /**
   * 问题反馈批处理
   * @param {Array} questionFeedbackDealwithDto -  questionFeedbackDealwithDto
   */
  questionFeed_batchDealwith() {
    return super.post('questionFeed/batchDealwith', ...arguments);
  }

  /**
   * 导出问题反馈
   * @param {string} userName -  姓名
   * @param {integer} type -  反馈类型 0定位不准反馈 1帮助反馈 2举报/检举
   * @param {integer} listType -  列表类型 0定位不准反馈 1其他
   * @param {integer} results -  处理结果 0处理中 1已处理
   * @param {integer} calibrationStatus -  处理结果 0处理中 1已处理
   * @param {string} feedbackTimeStart -  反馈时间起始时间
   * @param {string} feedbackTimeEnd -  反馈时间结束时间
   */

  questionFeed_exportQuestionFeed() {
    return super.get('questionFeed/exportQuestionFeed', ...arguments);
  }

  /***********问题反馈管理***************/
  /**
   * 问题反馈处理
   * @param {string} id -  问题反馈id
   */
  questionFeed_dealwith() {
    return super.post('questionFeed/dealwith', ...arguments);
  }

  /**
   * 问题反馈详细
   * @param {string} id -  问题反馈
   */
  questionFeed_detail() {
    return super.post('questionFeed/detail', ...arguments);
  }

  /**
   * 问题反馈列表
   * @param {string} userName -  姓名
   * @param {integer} type -  反馈类型 0定位不准反馈 1帮助反馈
   * @param {integer} results -  处理结果 0处理中 1已处理
   * @param {string} feedbackTimeStart -  反馈时间起始时间
   * @param {string} feedbackTimeEnd -  反馈时间结束时间
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */
  questionFeed_list() {
    return super.post('questionFeed/list', ...arguments);
  }

  /**
   * 意见反馈转发
   * @param {string} id -  问题反馈id
   * @param {string} preHandlerId -  预处理人id
   */

  questionFeed_questionFeedbackTransfer() {
    return super.post('questionFeed/questionFeedbackTransfer', ...arguments);
  }

  /**
   * 意见反馈转发
   * @param {string} id -  问题反馈id
   * @param {string} preHandlerId -  预处理人id
   */

  questionFeed_questionFeedbackTransferNoPermission() {
    return super.post('questionFeed/questionFeedbackTransferNoPermission', ...arguments);
  }

  /**
   * 问题反馈处理
   * @param {string} id -  问题反馈id
   * @param {integer} questionType -  问题分类
   * @param {string} suggestion -  处理意见
   */

  questionFeed_dealwithNoPermission() {
    return super.post('questionFeed/dealwithNoPermission', ...arguments);
  }

  /**
   * 问题反馈详细
   * @param {string} id -  问题反馈
   */

  questionFeed_detailNoPermission() {
    return super.post('questionFeed/detailNoPermission', ...arguments);
  }

  /**
   * 问题反馈列表
   * @param {string} userName -  姓名
   * @param {integer} type -  反馈类型 0定位不准反馈 1帮助反馈 2举报/检举
   * @param {integer} questionType -  问题分类：1系统bug/优化 2任务执行 3客诉问题 4其它问题
   * @param {integer} listType -  列表类型 0定位不准反馈 1其他
   * @param {integer} results -  处理结果 0待处理 1已处理 2处理中
   * @param {integer} calibrationStatus -  处理结果 0处理中 1已处理
   * @param {string} feedbackTimeStart -  反馈时间起始时间
   * @param {string} feedbackTimeEnd -  反馈时间结束时间
   * @param {string} handlerId -  处理人
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */

  questionFeed_getQuestionFeedListNoPermission() {
    return super.post('questionFeed/getQuestionFeedListNoPermission', ...arguments);
  }
}
