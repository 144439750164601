import Http from '../api/http/';

export default class userRemarkLog extends Http {
  constructor() {
    super();
  }
  // 新增用户备注记录
  userRemarkLog_addUserRemarkLog() {
    return super.post('userRemarkLog/addUserRemarkLog', ...arguments);
  }
  // 删除用户备注记录
  userRemarkLog_deleteUserRemarkLog() {
    return super.post('userRemarkLog/deleteUserRemarkLog', ...arguments);
  }
  // 用户备注记录列表
  userRemarkLog_getUserRemarkLogListByPage() {
    return super.post('userRemarkLog/getUserRemarkLogListByPage', ...arguments);
  }
  // 修改用户备注记录
  userRemarkLog_updateUserRemarkLog() {
    return super.post('userRemarkLog/updateUserRemarkLog', ...arguments);
  }
}
