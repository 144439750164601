import Http from '../api/http/';

export default class ActiveScoreGiveAudit extends Http {
  constructor() {
    super();
  }
  /**
   * 积分申请审核
   * @param {integer} id -  id
   * @param {integer} auditType -  审核 1审核通过 2审核不通过
   * @param {string} recommend -  审核意见
   */

  activeScoreGiveAudit_audit() {
    return super.post('activeScoreGiveAudit/audit', ...arguments);
  }

  /**
   * 积分审核详情
   * @param {integer} id -  id
   */

  activeScoreGiveAudit_detail() {
    return super.post('activeScoreGiveAudit/detail', ...arguments);
  }

  /**
   * 积分审核导出
   * @param {string} userName -  访客姓名
   * @param {string} phone -  手机号
   * @param {string} applyName -  申请人姓名
   * @param {integer} scoreType -  赠送类型 15:系统赠送 16:任务补偿 17:活动奖励
   * @param {integer} auditStatus -  审核状态 0审核中 1审核通过 2审核不通过 5删除
   * @param {string} applyTimeStart -  申请开始时间
   * @param {string} applyTimeEnd -  申请结束时间
   */

  activeScoreGiveAudit_export() {
    return super.get('activeScoreGiveAudit/export', ...arguments);
  }
  /**
   * 积分审核列表
   * @param {string} userName -  访客姓名
   * @param {string} phone -  手机号
   * @param {string} applyName -  申请人姓名
   * @param {integer} scoreType -  赠送类型 15:系统赠送 16:任务补偿 17:活动奖励
   * @param {integer} auditStatus -  审核状态 0审核中 1审核通过 2审核不通过 5删除
   * @param {string} applyTimeStart -  申请开始时间
   * @param {string} applyTimeEnd -  申请结束时间
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */

  activeScoreGiveAudit_list() {
    return super.post('activeScoreGiveAudit/list', ...arguments);
  }
  //批量审核
  activeScoreGiveAudit_batchAudit() {
    return super.post('activeScoreGiveAudit/batchAudit', ...arguments);
  }
  //批量导入
  activeScoreGiveAudit_importAuditActiveScore() {
    return super.post('activeScoreGiveAudit/importAuditActiveScore', ...arguments);
  }
}
