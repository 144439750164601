import Http from '../api/http/';
export default class Business extends Http {
  constructor() {
    super();
  }
  //添加商圈
  business_add() {
    return super.post('business/add', ...arguments, {});
  }
  //分页查询
  business_getListByPage() {
    return super.post('business/getListByPage', ...arguments);
  }
  //删除商圈
  business_delete() {
    return super.post('business/delete', ...arguments);
  }
  //通过id查询
  business_getById() {
    return super.post('business/getById', ...arguments);
  }
  //修改商圈
  business_update() {
    return super.post('business/update', ...arguments, {});
  }
  //通过城市下拉商圈
  business_getList() {
    return super.post('business/getList', ...arguments);
  }
  //获取商圈门店列表
  business_getBusinessStoreList() {
    return super.post('business/getBusinessStoreList', ...arguments);
  }
  //批量修改商圈报名信息
  business_batchUpdateBusinessNumber() {
    return super.post('business/batchUpdateBusinessNumber', ...arguments, {});
  }
  /**
   * 修改消息提醒
   * @param {Array} updateBusinessConfigDto -  updateBusinessConfigDto
   */

  business_updateBusinessConfig() {
    return super.post('business/updateBusinessConfig', ...arguments, {});
  }
  /**
   * 获取消息提醒用户id
   */

  business_getReceiveMessageUserIds() {
    return super.post('business/getReceiveMessageUserIds', ...arguments);
  }
}
