/**
 * input限制数字（v-input-max.number）
 * v-input-max
 * v-input-max.minus 支持负数
 * v-input-max = "{max: val}" 限制最大值，如果最大值是负数，就是负数~0
 * v-input-max = "{num: 2}" 限制小数位
 */
export default {
  bind: function () {},
  update: function (el, binding) {
    let input = el;
    if (el.nodeName && el.nodeName.toLocaleUpperCase() != 'TEXTAREA' && el.nodeName.toLocaleUpperCase() != 'INPUT') {
      input = el.querySelector('input,textarea');
    }
    let inputValue = input.value,
      newValue = inputValue,
      flag = '';
    if (inputValue === '' || inputValue === null || inputValue === undefined) {
      //当值为空的时候，不做校验
      return;
    }
    let obj = binding.value ? binding.value : {}; // 绑定值
    if (binding.modifiers.minus || (obj.max && obj.max < 0)) {
      // 支持负数
      if (newValue == '-' || newValue == '-0' || newValue == '-0.') {
        return;
      }
      if (newValue < 0) {
        flag = '-';
      }
    }
    newValue = newValue.replace(/[^\d.]/g, '');
    if (obj.max) {
      // 最大值
      let max = parseFloat(obj.max);
      max = Math.abs(max); //取正数值
      if (max < newValue) {
        newValue = max.toString();
      }
    }
    if (obj.min) {
      //最小值
      let min = parseFloat(obj.min);
      min = Math.abs(min);
      if (min > newValue) {
        newValue = min.toString();
      }
    }
    if (obj.num) {
      // 小数
      let value = parseInt(obj.num);
      newValue = /^\./g.test(newValue) ? '' : newValue;
      let req = new RegExp('^(\\.*)(\\d+)(\\.?)(\\d{0,' + value + '}).*$', 'g');
      newValue = newValue.replace(req, '$2$3$4');
    } else {
      // 整数
      newValue = newValue.replace(/[^\d]+/g, '');
    }
    newValue = flag ? flag + newValue : newValue;
    if (obj.max && obj.max < 0 && !flag) {
      newValue = '0';
    }
    if (inputValue !== newValue) {
      input.value = newValue;
      input.dispatchEvent(new Event('input'));
    }
  },
  unbind: function () {},
};
