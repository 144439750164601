import Http from '../api/http/';

export default class User extends Http {
  constructor() {
    super();
  }
  //add 添加考题
  trainQuestion_add() {
    return super.post('trainQuestion/add', ...arguments);
  }
  //删除考题
  trainQuestion_delete() {
    return super.post('trainQuestion/delete', ...arguments);
  }
  //编辑考题
  trainQuestion_edit() {
    return super.post('trainQuestion/edit', ...arguments);
  }
  //查询考题
  trainQuestion_queryList() {
    return super.post('trainQuestion/queryList', ...arguments);
  }
  //查询考题
  trainQuestion_getById() {
    return super.post('trainQuestion/getById', ...arguments);
  }
  /**
   * 导出试题
   * @param {Array} trainQuestionDto -  trainQuestionDto
   */
  trainQuestion_exportTrainQuestion() {
    return super.post('trainQuestion/exportTrainQuestion', ...arguments, {});
  }
  /**
   * 批量导入试题
   * @param {file} excelFile -  上传文件
   */
  trainQuestion_importTrainQuestion() {
    return super.post('trainQuestion/importTrainQuestion', ...arguments);
  }
  // 答题人数
  trainQuestion_getQuestionRecords() {
    return super.post('trainQuestion/getQuestionRecords', ...arguments);
  }
  // 导出答题人数
  trainQuestion_exportQuestionRecords() {
    return super.post('trainQuestion/exportQuestionRecords', ...arguments);
  }
}
