import Http from '../api/http/';

export default class AppointTask extends Http {
  constructor() {
    super();
  }

  /**
   * 指定进度表-品牌维度指定量
   * @param {Array} appointTaskByBrandDTO -  appointTaskByBrandDTO
   */
  appointTask_appointTaskBrandAssignList() {
    return super.post('appointTask/appointTaskBrandAssignList', ...arguments, {});
  }

  /**
   * 指定进度表-指定人维度指定量导出
   * @param {Array} appointTaskByBrandDTO -  appointTaskByBrandDTO
   */
  appointTask_appointTaskBrandAssignListExport() {
    return super.post('appointTask/appointTaskBrandAssignListExport', ...arguments, {});
  }

  /**
   * 指定进度表-指定人维度
   * @param {Array} appointTaskByAssignUserDTO -  appointTaskByAssignUserDTO
   */
  appointTask_appointTaskByAssignUserList() {
    return super.post('appointTask/appointTaskByAssignUserList', ...arguments, {});
  }

  /**
   * 指定进度表-指定人维度导出
   * @param {Array} appointTaskByAssignUserDTO -  appointTaskByAssignUserDTO
   */
  appointTask_appointTaskByAssignUserListExport() {
    return super.post('appointTask/appointTaskByAssignUserListExport', ...arguments, {});
  }

  /**
   * 指定进度表-品牌维度
   * @param {Array} appointTaskByBrandDTO -  appointTaskByBrandDTO
   */
  appointTask_appointTaskByBrandList() {
    return super.post('appointTask/appointTaskByBrandList', ...arguments, {});
  }

  /**
   * 指定进度表-品牌维度导出
   * @param {Array} appointTaskByBrandDTO -  appointTaskByBrandDTO
   */
  appointTask_appointTaskByBrandListExport() {
    return super.post('appointTask/appointTaskByBrandListExport', ...arguments, {});
  }

  /**
   * 指定进度表-剩余量
   * @param {Array} appointTaskByBrandDTO -  appointTaskByBrandDTO
   */
  appointTask_appointTaskSurplusList() {
    return super.post('appointTask/appointTaskSurplusList', ...arguments, {});
  }

  /**
   * 指定进度表-剩余量导出
   * @param {Array} appointTaskByBrandDTO -  appointTaskByBrandDTO
   */
  appointTask_appointTaskSurplusListExport() {
    return super.post('appointTask/appointTaskSurplusListExport', ...arguments, {});
  }

  /**
   * 指定进度表-指定人维度指定量
   * @param {Array} appointTaskByBrandDTO -  appointTaskByBrandDTO
   */
  appointTask_appointTaskUserAssignList() {
    return super.post('appointTask/appointTaskUserAssignList', ...arguments, {});
  }

  /**
   * 指定进度表-指定人维度指定量导出
   * @param {Array} appointTaskByBrandDTO -  appointTaskByBrandDTO
   */
  appointTask_appointTaskUserAssignListExport() {
    return super.post('appointTask/appointTaskUserAssignListExport', ...arguments, {});
  }
}
