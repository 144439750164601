import Http from '../api/http/';

export default class UserStatistics extends Http {
  constructor() {
    super();
  }
  /**
   * 导出用户注册认证详细列表
   * @param {integer} groupType -  undefined
   * @param {integer} type -  undefined
   * @param {string} startYmd -  undefined
   * @param {string} endYmd -  undefined
   */

  userStatistics_exportUserStatisticDetail() {
    return super.get('userStatistics/exportUserStatisticDetail', ...arguments);
  }
  /**
   * 获取用户注册认证列表
   * @param {Array} userStatisticsDTO -  userStatisticsDTO
   */

  userStatistics_getStatisticsList() {
    return super.post('userStatistics/getStatisticsList', ...arguments, {});
  }
}
