/**
 * @author LB
 * @description 未登录状态查看页面
 */
export default [
  {
    path: '/view/viewReport/:reportId',
    name: 'view_viewReport',
    meta: {
      viewTitle: '嗨探',
      title: '查看报告',
      view: true,
    },
    component: resolve => require(['@/views/viewReport/viewReport'], resolve),
  },
  {
    path: '/view/viewAudio/:reportId',
    name: 'view_viewAudio',
    meta: {
      viewTitle: '嗨探',
      title: '报告录音',
      view: false,
    },
    component: resolve => require(['@/views/viewReport/viewAudio'], resolve),
  },
  {
    path: '/view/helpCenter',
    name: 'view_helpCenter',
    meta: {
      title: '帮助中心',
      view: true,
    },
    component: resolve => require(['@/views/help/helpCenter'], resolve),
  },
  {
    path: '/view/visitorCommitment',
    name: 'view_visitorCommitment',
    meta: {
      title: '用户承诺书',
      view: true,
    },
    component: resolve => require(['@/views/user/visitorUser/visitorCommitment'], resolve),
  },
];
