import Http from '../api/http/';
export default class TargetManage extends Http {
  constructor() {
    super();
  }
  dataIndex_getListByPage() {
    return super.post('dataIndex/getListByPage', ...arguments);
  }
  /**
   * @description 获取指标管理分页数据
   * @param
   */
  export_target() {
    return super.get('/dataIndex/export', ...arguments);
  }
}
