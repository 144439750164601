import Http from '../api/http/';
export default class OvertimeReport extends Http {
  constructor() {
    super();
  }
  /***********超时报告每日详情***************/
  /**
   * 导出超时报告详情
   * @param {string} startDay -  开始日期
   * @param {string} endDay -  结束日期
   * @param {string} startMonth -  开始月份
   * @param {string} endMonth -  结束月份
   * @param {string} statDay -  统计日期
   * @param {string} statMonth -  统计月份
   * @param {integer} model -  模式：0-日报，1-月报
   * @param {integer} type -  类型：0-超时未完成审核，1-驳回，2-超时未交付）
   * @param {string} brandManagerId -  品牌管理员id
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  overtimeReport_exportDetail() {
    return super.get('overtimeReport/exportDetail', ...arguments);
  }

  /**
   * 导出超时报告列表
   * @param {string} startDay -  开始日期
   * @param {string} endDay -  结束日期
   * @param {string} startMonth -  开始月份
   * @param {string} endMonth -  结束月份
   * @param {string} statDay -  统计日期
   * @param {string} statMonth -  统计月份
   * @param {integer} model -  模式：0-日报，1-月报
   * @param {integer} type -  类型：0-超时未完成审核，1-驳回，2-超时未交付）
   * @param {string} brandManagerId -  品牌管理员id
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  overtimeReport_exportList() {
    return super.get('overtimeReport/exportList', ...arguments);
  }
  /**
   * 导出超时报告列表(新)
   * @param {Array} overtimeDto -  overtimeDto
   */
  overtimeReport_exportOvertimeList() {
    return super.post('overtimeReport/exportOvertimeList', ...arguments, {});
  }

  /**
   * 生成超时报告记录
   * @param {string} statDay -  statDay
   */
  overtimeReport_generateOvertimeReport() {
    return super.post('overtimeReport/generateOvertimeReport', ...arguments);
  }

  /**
   * 根据用户及日期查询超时详情
   * @param {Array} overtimeReportQueryDto -  overtimeReportQueryDto
   */
  overtimeReport_getDetailByUser() {
    return super.post('overtimeReport/getDetailByUser', ...arguments);
  }

  /**
   * 按日期范围查询超时列表
   * @param {Array} overtimeReportQueryDto -  overtimeReportQueryDto
   */
  overtimeReport_getPaginationList() {
    return super.post('overtimeReport/getPaginationList', ...arguments);
  }
  /**
   * 按日期范围查询超时列表（新）
   * @param {Array} overtimeDto -  overtimeDto
   */
  overtimeReport_getOvertimeList() {
    return super.post('overtimeReport/getOvertimeList', ...arguments, {});
  }
  /**
   * 查询超时详情按品牌分组
   * @param {Array} overtimeDto -  overtimeDto
   */
  overtimeReport_getOvertimeDetailGroupByBrand() {
    return super.post('overtimeReport/getOvertimeDetailGroupByBrand', ...arguments, {});
  }
  /**
   * 导出超时报表明细
   * @param {Array} overtimeDto -  overtimeDto
   */
  overtimeReport_exportOvertimeDetail() {
    return super.post('overtimeReport/exportOvertimeDetail', ...arguments, {});
  }
}
