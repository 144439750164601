import vue from 'vue';
import vuex from 'vuex';

import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import createLogger from 'vuex/dist/logger';
import Storage from '../api/lib/baseStorage';

import createPersistedState from 'vuex-persistedstate';

vue.use(vuex);

const debug = process.env.NODE_ENV !== 'production';

// const handleStore = store => {  // 这种方式会过滤空值
//     let storage = new Storage();
//     if (storage.localGetItem("store")) {
//         store.replaceState(JSON.parse(storage.localGetItem("store"))) // 初始化store
//     }
//     store.subscribe((mutation, state) => {
//         storage.localSetItem("store", JSON.stringify(state))
//     })
// }

let storage = new Storage();
const handleStore = createPersistedState({
  storage: {
    getItem: key => storage.localGetItem(key),
    setItem: (key, value) => storage.localSetItem(key, value),
    removeItem: key => storage.localRemoveItem(key),
  },
});
export default new vuex.Store({
  state,
  getters,
  mutations,
  actions,
  plugins: debug ? [createLogger(), handleStore] : [handleStore],
});
