import Http from '@/api/http/';

export default class ArticleComment extends Http {
  constructor() {
    super();
  }
  // articleType (integer, optional): 类型1banner2培训内容3培训课程
  /**
   * 新增或者修改评论
   * @returns
   */
  articleComment_addOrUpdatePComment() {
    return super.post('articleComment/addOrUpdatePComment', ...arguments);
  }

  /**
   * 回复评论
   * @returns
   */
  articleComment_addOrUpdatePCommentReply() {
    return super.post('articleComment/addOrUpdatePCommentReply', ...arguments);
  }

  /**
   * 评论列表
   * @returns
   */
  articleComment_commentList() {
    return super.post('articleComment/commentList', ...arguments);
  }

  /**
   * 删除用户评论
   * @returns
   */
  articleComment_deleteComment() {
    return super.post('articleComment/deleteComment', ...arguments);
  }

  /**
   * 删除p端回复
   * @returns
   */
  articleComment_deleteCommentReply() {
    return super.post('articleComment/deleteCommentReply', ...arguments);
  }

  /**
   * 设置app只显示精选评论
   * @returns
   */
  articleComment_setAppOnlyShowSelectedComment() {
    return super.post('articleComment/setAppOnlyShowSelectedComment', ...arguments);
  }

  /**
   * 修改精选状态
   *  @param { body } { isSelected } 是否精选 1是0否
   * @returns
   */
  articleComment_updateCommentSelected() {
    return super.post('articleComment/updateCommentSelected', ...arguments);
  }

  /**
   * 修改置顶状态
   * @param { body } { isTop } 是否置顶 1是0否
   * @returns
   */
  articleComment_updateCommentTop() {
    return super.post('articleComment/updateCommentTop', ...arguments);
  }

  /**
   * 修改点赞状态
   * @param { body } { isDianZan } 是否点赞1是0否
   * @returns
   */
  articleComment_updateDianZanComment() {
    return super.post('articleComment/updateDianZanComment', ...arguments);
  }
}
