import Http from '../api/http/';

export default class CompanyUser extends Http {
  constructor() {
    super();
  }

  /***********企业用户管理***************/
  /**
   * 新增企业用户
   * @param {Array} bUserDto -  bUserDto
   */
  companyUser_add() {
    return super.post('companyUser/add', ...arguments);
  }

  /**
   * 企业用户删除
   * @param {string} id -  id
   */
  companyUser_delete() {
    return super.post('companyUser/delete', ...arguments);
  }

  /**
   * 企业用户详情
   * @param {string} id -  id
   */
  companyUser_getInfo() {
    return super.post('companyUser/getInfo', ...arguments);
  }

  /**
   * 企业用户列表
   * @param {string} loginname -  loginname
   * @param {string} companyId -  companyId
   * @param {integer} rows -  rows
   * @param {integer} page -  page
   */
  companyUser_getUserList() {
    return super.post('companyUser/getUserList', ...arguments);
  }
  /**
   * 修改企业用户
   * @param {Array} bUserDto -  bUserDto
   */
  companyUser_update() {
    return super.post('companyUser/update', ...arguments);
  }

  /**
   * 修改企业用户状态
   * idList 企业用户id，多个用;分割
   * status 用户状态 0启用 1禁用
   */
  companyUser_batchUpdateStatus() {
    return super.post('companyUser/batchUpdateStatus', ...arguments);
  }
  // 导入企业账号
  companyUser_import() {
    return super.post('companyUser/import', ...arguments);
  }
  // 修改手机号
  companyUser_updateUserPhone() {
    return super.post('companyUser/updateUserPhone', ...arguments);
  }
}
