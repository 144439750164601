export default class {
  /************全局方法************/
  /**
   * @param {更新key} value
   * @returns
   */
  updateKeyTimer($event, timer, dialog) {
    $event[timer] = new Date().getTime();
    setTimeout(() => {
      $event[dialog] = true;
    }, 250);
  }
  /**
   * 是否空，不包含0
   * @param {value} value
   */
  isNull(value) {
    return value === '' || value === null || value === undefined;
  }
  /**
   * 复制数组对象，仅对不含方法的对象有效
   * @param {Array} arrObj
   */
  clone(arrObj) {
    return JSON.parse(JSON.stringify(arrObj));
  }
  /**
   * 小数计算结果处理
   * @param {Number} number
   * 这个小数不能超过6位
   */
  decimalFixed(number) {
    if (number) {
      return Number(number.toFixed(6));
    }
    return number;
  }
  /**
   * 是否Number类型
   * @param {Any} value
   */
  isNumber(value) {
    /**
     * 注意：Infinity、NaN 也是Number类型
     */
    return Object.prototype.toString.call(value) === '[object Number]';
  }
  /**
   * 是否String类型
   * @param {Any} value
   */
  isString(value) {
    return Object.prototype.toString.call(value) === '[object String]';
  }
  /**
   * 是否Array类型
   * @param {Any} value
   */
  isArray(value) {
    return Object.prototype.toString.call(value) === '[object Array]';
  }
  /**
   * 严格检测是否json对象
   * @param {Any} obj - 要检测的对象
   */
  isJson(obj) {
    return typeof obj == 'object' && Object.prototype.toString.call(obj).toLowerCase() == '[object object]' && !obj.length;
  }
  /**
   * 乘法
   * @param {Array} arr - 要计算的数组
   */
  multiplication(arr) {
    // 判断传入arry不是数组直接返回
    if (!Array.isArray(arr)) throw new Error('【this.api.baseApi.sum】传入的不是数组');
    // 储存小数点后位数之和（扩大的倍数）
    let pow = 0;
    let newArr = [];
    // 判断数组内部各项是否为数字 判断小数点位数
    for (let i = 0; i < arr.length; i++) {
      // 判断数组中是否为全数字
      let value = arr[i];
      if (typeof value !== 'number' || Number.isNaN(value)) throw new Error(`【this.api.baseApi.sum】数组索引第${i}项不是数字`);
      // 获取小数点后位数
      let len = this.pointLength(value);
      pow += len;
      if (len) {
        newArr.push(this.getEnlarge(value));
      } else {
        newArr.push(value);
      }
    }
    let maxPoint = Math.pow(10, pow);
    let result = newArr.reduce((total, num) => {
      return total * num;
    });
    return result / maxPoint;
  }
  /**
   * 获取小数点之后的数字长度
   * @param {String | Number} value
   * @returns {Number} 小数点后面的数字长度
   */
  pointLength(value) {
    // 验证当前传入值的类型
    if (!this.isNumber(value) && !this.isString(value)) throw new Error(`要验证的值类型不是String | Number`);
    if (this.isNumber(value)) {
      value = String(value);
    }
    let arr = value.split('.');
    if (arr.length === 2) {
      return arr[1].length;
    }
    return 0;
  }
  /**
   * 小数放大整数
   * @param {String | Number} value
   * @returns {Number} 小数点后面的数字长度
   */
  getEnlarge(value) {
    // 验证当前传入值的类型
    if (!this.isNumber(value) && !this.isString(value)) throw new Error(`要验证的值类型不是String | Number`);
    if (this.isNumber(value)) {
      value = String(value);
    }
    let arr = value.split('.');
    if (arr.length === 2) {
      return value.replace('.', '');
    }
    return value;
  }
  /**
   * 总和
   * @param {Array} arr - 要计算的数组
   * @param {Boolean} isSubtract - 是否采用累减计算（默认采用累加计算）
   */
  sum(arr, isSubtract = false) {
    // 判断传入arry不是数组直接返回
    if (!Array.isArray(arr)) throw new Error('【this.api.baseApi.sum】传入的不是数组');
    // 储存小数点后位数
    let point = 0;
    // 判断数组内部各项是否为数字 判断小数点位数
    for (let i = 0; i < arr.length; i++) {
      // 判断数组中是否为全数字
      let value = arr[i];
      if (typeof value !== 'number' || Number.isNaN(value)) throw new Error(`【this.api.baseApi.sum】数组索引第${i}项不是数字`);
      // 获取小数点后位数
      let len = this.pointLength(value);
      if (point < len) point = len;
    }
    // 取整放大倍数
    let maxPoint = Math.pow(10, point);
    // 累加结果字段
    let that = this;
    let sum = arr.reduce(function (prev, cur, index) {
      if (isSubtract) {
        if (index === 0) {
          return that.multiplication([cur, maxPoint]);
        } else {
          return prev - that.multiplication([cur, maxPoint]);
        }
      } else {
        return that.multiplication([cur, maxPoint]) + prev;
      }
    }, 0);
    return sum / maxPoint;
  }
  /**
   * 复制文本
   * @param {*} text
   */
  clipboard(text) {
    let id = 'unique-id-clipboard-copyText';
    let copyText = document.getElementById(id);
    if (!copyText) {
      copyText = document.createElement('textarea');
      copyText.id = id;
      copyText.style = 'position:absolute;left:-9999px';
      document.body.appendChild(copyText);
    }
    copyText.value = text;
    copyText.select();
    copyText.setSelectionRange(0, copyText.value.length);
    document.execCommand('copy');
  }
  /**
   * 去掉前后空格
   * @param {Any} args - 一般传数组或对象
   */
  trim(args) {
    //如果是数组
    if (Array.isArray(args)) {
      args.forEach((ele, index) => {
        if (Array.isArray(ele) || typeof ele === 'object') {
          this.trim(ele);
        } else if (typeof ele === 'string') {
          args[index] = ele.trim();
        }
      });
    } else if (args != null && typeof args === 'object') {
      Object.keys(args).forEach(k => {
        let ele = args[k];
        if (Array.isArray(ele) || typeof ele === 'object') {
          this.trim(ele);
        } else if (typeof ele === 'string') {
          args[k] = ele.trim();
        }
      });
    } else if (typeof args === 'string') {
      return args.trim();
    }
  }
}
