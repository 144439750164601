/**
 * @author LB
 * @description element组件重构
 */
import glDialog from './glDialog';
import glDrawer from './glDrawer';
import glFixTable from './glFixTable';
import glImageViewer from './glImageViewer';
import glLocalTable from './glLocalTable';
import glPreviewImage from './glPreviewImage';
import glSearchForm from './glSearchForm';
import glSelect from './glSelect';
import glTable from './glTable';
import glCard from './glCard';

export default {
  install: function (Vue) {
    Vue.component('gl-table', glTable);
    Vue.component('gl-search-form', glSearchForm);
    Vue.component('gl-dialog', glDialog);
    Vue.component('gl-preview-image', glPreviewImage);
    Vue.component('gl-drawer', glDrawer);
    Vue.component('gl-fix-table', glFixTable);
    Vue.component('gl-local-table', glLocalTable);
    Vue.component('gl-image-viewer', glImageViewer);
    Vue.component('gl-select', glSelect);
    Vue.component('gl-card', glCard);
  },
};
