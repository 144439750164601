import Http from '../api/http/';
export default class DrawConfig extends Http {
  constructor() {
    super();
  }
  // 新增中签算法
  drawConfig_addDrawConfig() {
    return super.post('drawConfig/addDrawConfig', ...arguments);
  }
  // 删除中签算法
  drawConfig_deleteDrawConfig() {
    return super.post('drawConfig/deleteDrawConfig', ...arguments);
  }
  // 获取详细中签算法
  drawConfig_getDrawConfigDetail() {
    return super.post('drawConfig/getDrawConfigDetail', ...arguments);
  }
  // 获取中签算法列表
  drawConfig_getDrawConfigList() {
    return super.post('drawConfig/getDrawConfigList', ...arguments);
  }
  // 获取中签规则
  drawConfig_getDrawRuleList() {
    return super.post('drawConfig/getDrawRuleList', ...arguments);
  }
  // 更新中签算法
  drawConfig_updateDrawConfig() {
    return super.post('drawConfig/updateDrawConfig', ...arguments);
  }
}
