import name from './mutationsName';

export default {
  [name.SET_USER_INFO](state, userInfo) {
    state.userInfo = userInfo;
  },
  [name.Set_Permissions](state, permissions) {
    state.userInfo.permissions = permissions;
  },
  [name.SET_PARAMS](state, params) {
    state.params = params;
  },
  [name.SET_ANALYSIS_VALID](state, analysisValid) {
    state.analysisValid = analysisValid;
  },
  [name.SET_LEFT_ACTION](state, leftAction) {
    state.leftAction = leftAction;
  },
  [name.SET_HEAD_ACTION](state, headAction) {
    state.headAction = headAction;
  },
  [name.SET_NEWS_COUNT](state, newsCount) {
    state.newsCount = newsCount;
  },
  [name.SET_TOTAL_ANALYSIS_NAVMENU](state, totalAnalysisNavmenu) {
    state.totalAnalysisNavmenu = totalAnalysisNavmenu;
  },
  [name.SET_TOTAL_ANALYSIS_NAVMENU_ACTIVEINDEX](state, activeIndex) {
    state.totalAnalysisNavmenu.activeIndex = activeIndex;
  },
  [name.Set_Current_Version](state, currentVersion) {
    state.currentVersion = currentVersion;
  },
  [name.Set_Behavior_Body_Add](state, behaviorBody) {
    state.behaviorBody.push(behaviorBody);
  },
  [name.Set_Behavior_Body_Del](state, index) {
    state.behaviorBody.splice(0, index);
  },
};
