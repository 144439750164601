import Http from '../api/http/';
export default class phoneMessage extends Http {
  constructor() {
    super();
  }
  /**
   * 新增短信发送
   * @param {string} messageId -  消息id,更新的时候需要传
   * @param {string} templateId -  模板id
   * @param {string} tilte -  名称
   * @param {string} phones -  手机号逗号相隔
   * @param {integer} isTimer -  是否定时,1是，0否
   * @param {string} sendTime -  发送时间
   * @param {string} cuid -  undefined
   */
  phoneMessage_addPhoneMessage() {
    return super.post('phoneMessage/addPhoneMessage', ...arguments);
  }

  /**
   * 删除短信发送
   * @param {string} messageId -  messageId
   */
  phoneMessage_deletePhoneMessage() {
    return super.post('phoneMessage/deletePhoneMessage', ...arguments);
  }

  /**
   * 查看手机号列表
   * @param {string} phoneMessageInfoDTO -  phoneMessageInfoDTO
   */
  phoneMessage_getMessageInfoList() {
    return super.post('phoneMessage/getMessageInfoList', ...arguments);
  }

  /**
   * 查询短信发送列表
   * @param {Array} phoneMessageDTO -  phoneMessageDTO
   */
  phoneMessage_getPhoneMessageList() {
    return super.post('phoneMessage/getPhoneMessageList', ...arguments);
  }

  /**
   * 获取模板列表
   */
  phoneMessage_getPhoneMessageTemplateList() {
    return super.post('phoneMessage/getPhoneMessageTemplateList', ...arguments);
  }

  /**
   * 查看短消息发送详细
   * @param {string} messageId -  messageId
   */
  phoneMessage_getPhoneMessageVO() {
    return super.post('phoneMessage/getPhoneMessageVO', ...arguments);
  }

  /**
   * 更新短信发送
   * @param {string} messageId -  消息id,更新的时候需要传
   * @param {string} templateId -  模板id
   * @param {string} tilte -  名称
   * @param {string} phones -  手机号逗号相隔
   * @param {integer} isTimer -  是否定时,1是，0否
   * @param {string} sendTime -  发送时间
   * @param {string} cuid -  undefined
   */
  phoneMessage_updatePhoneMessage() {
    return super.post('phoneMessage/updatePhoneMessage', ...arguments);
  }

  /**
   * 导出名单
   * @param {string} messageId -  消息id
   * @param {string} phone -  手机号
   * @param {integer} sendStatus -  发送状态 0未发送，1发送中，2发送失败，3发送成功
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  phoneMessage_exportConsume() {
    return super.get('phoneMessage/exportConsume', ...arguments);
  }

  /**
   * 查看短消息发送详细
   * @param {string} messageId -  messageId
   */
  phoneMessage_getPhoneMessageEditVO() {
    return super.post('phoneMessage/getPhoneMessageEditVO', ...arguments);
  }
}
