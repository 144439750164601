import Http from '../api/http/';

export default class TrainConfig extends Http {
  constructor() {
    super();
  }
  trainConfig_trainConfigListByPage() {
    return super.post('trainConfig/trainConfigListByPage', ...arguments);
  }
  trainConfig_topOperate() {
    return super.post('trainConfig/topOperate', ...arguments);
  }

  trainConfig_onOffline() {
    return super.post('trainConfig/onOffline', ...arguments);
  }

  trainConfig_delete() {
    return super.post('trainConfig/delete', ...arguments);
  }
  /**
   * 新增培训内容
   * @param {Array} trainConfigAddUpdateDTO -  trainConfigAddUpdateDTO
   */
  trainConfig_addTrainConfig() {
    return super.post('trainConfig/addTrainConfig', ...arguments, {});
  }
  /**
   * 培训内容评论列表
   * @param {integer} id -  id
   */
  trainConfig_getCommentListById() {
    return super.post('trainConfig/getCommentListById', ...arguments);
  }
  /**
   * p端回复app培训内容评论
   * @param {Array} req -  req
   */
  trainConfig_trainConfigCommentReply() {
    return super.post('trainConfig/trainConfigCommentReply', ...arguments, {});
  }

  /**
   * p端删除用户的评论
   * @param {integer} trainCommentId -  trainCommentId
   */
  trainConfig_deleteTrainConfigComment() {
    return super.post('trainConfig/deleteTrainConfigComment', ...arguments);
  }

  /**
   * p端查看培训内容详情
   * @param {integer} id -  id
   */
  trainConfig_getDetailById() {
    return super.post('trainConfig/getDetailById', ...arguments);
  }

  /**
   * 修改培训内容
   * @param {Array} trainConfigAddUpdateDTO -  trainConfigAddUpdateDTO
   */
  trainConfig_updateTrainConfig() {
    return super.post('trainConfig/updateTrainConfig', ...arguments, {});
  }
}
