import Http from '../api/http/';
export default class Help extends Http {
  constructor() {
    super();
  }

  /**
   * 批量排序
   * @param {array} sortIdList -  需要排序的ids 从小到大
   */
  help_sortByBatch() {
    return super.post('help/sortByBatch', ...arguments);
  }

  /**
   * 帮助设置列表
   * @param {string} pid -  标题父级id
   * @param {string} title -  标题名称
   * @param {integer} viewCountSort -  浏览量排序 0降序 1升序
   * @param {integer} sourceClient -  客户端来源
   */

  help_list() {
    return super.post('help/list', ...arguments);
  }
  /**
   * 添加帮助设置
   * @param {integer} type -  类型 1一级标题 2二级标题
   * @param {string} title -  标题名称
   * @param {string} content -  标题内容
   * @param {string} videoUrl -  视频URL
   * @param {string} pid -  标题父级id 最顶级默认传0
   * @param {integer} sort -  标题排序
   * @param {integer} sourceClient -  客户端来源 0-C端,1-B端,2-P端
   */

  help_add() {
    return super.post('help/add', ...arguments);
  }
  /**
   * 删除帮助
   * @param {string} id -  标题id
   */

  help_remove() {
    return super.post('help/remove', ...arguments);
  }

  /**
   * 修改帮助标题
   * @param {integer} type -  类型 1一级标题 2二级标题
   * @param {string} id -  标题id
   * @param {string} content -  标题内容
   * @param {string} title -  标题名称
   */

  help_updateTitle() {
    return super.post('help/updateTitle', ...arguments);
  }

  /**
   * 增加访问量
   * @param {string} id -  id
   */

  help_addViewCount() {
    return super.post('help/addViewCount', ...arguments);
  }
}
