import Http from '../api/http/';

export default class LeavingMessage extends Http {
  constructor() {
    super();
  }

  /***********留言管理***************/
  /**
   * 处理留言
   * @param {string} id -  留言id
   */
  leavingMessage_dealwith() {
    return super.post('leavingMessage/dealwith', ...arguments);
  }

  /**
   * 留言详情
   * @param {string} id -  留言id
   */
  leavingMessage_detail() {
    return super.post('leavingMessage/detail', ...arguments);
  }

  /**
   * 留言列表
   * @param {string} brandName -  品牌名称
   * @param {string} companyName -  企业名称
   * @param {string} phone -  手机号
   * @param {integer} source -  来源 0首页服务咨询 1留言板
   * @param {integer} status -  处理状态 0未处理 1已处理
   * @param {string} messageTimeStart -  留言起始时间
   * @param {string} messageTimeEnd -  留言结束时间
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */
  leavingMessage_list() {
    return super.post('leavingMessage/list', ...arguments);
  }
  /**
   * 导出留言板数据
   * @param {string} brandName -  品牌名称
   * @param {string} companyName -  企业名称
   * @param {string} phone -  手机号
   * @param {integer} source -  来源 0首页服务咨询 1留言板
   * @param {integer} status -  处理状态 0未处理 1已处理
   * @param {string} messageTimeStart -  留言起始时间
   * @param {string} messageTimeEnd -  留言结束时间
   */
  leavingMessage_exportLeavingMessage() {
    return super.get('leavingMessage/exportLeavingMessage', ...arguments);
  }
}
