import store from '../store';

export default {
  bind: function (el, binding, vnode) {
    try {
      if (!binding.value) {
        // 权限值为空时不鉴权
        return;
      }
      let temp = binding.value;
      let isRole = false;
      const roleArray = store.getters.getPermissions;
      if (temp.includes('_')) {
        // 并权限
        temp = temp.split('_');
        isRole = temp.every(item => {
          return roleArray.includes(item);
        });
      } else if (temp.includes('|')) {
        // 或权限
        temp = temp.split('|');
        isRole = temp.some(item => {
          return roleArray.includes(item);
        });
      } else {
        // 单权限
        isRole = roleArray.includes(temp);
      }
      //如果当前权限不存在，则删除元素
      if (isRole === false) {
        //如果没有父节点（无法删除，只能隐藏）
        if (el.parentNode) {
          el.parentNode.removeChild(el);
        } else {
          el.innerHTML = '';
          el.style.display = 'none';
        }
      }
      //回调函数
      if (binding.modifiers['callback']) {
        let m = binding.expression.match(/(\S+)\((\S+)\)/);
        let method = m[1],
          args = m[2];
        vnode.context[method].apply(vnode.context, eval(`[${args},${isRole}]`));
      }
    } catch (ex) {
      console.warn(ex);
    }
  },
};
