import Http from '../api/http';

export default class CompanyRemind extends Http {
  constructor() {
    super();
  }
  /**
   * 通过企业id获取
   * @param {string} companyId -  companyId
   */

  companyRemind_getByCompanyId() {
    return super.post('companyRemind/getByCompanyId', ...arguments);
  }

  /**
   * 修改设置
   * @param {Array} companyRemindDTO -  companyRemindDTO
   */

  companyRemind_updateCompanyRemind() {
    return super.post('companyRemind/updateCompanyRemind', ...arguments, {});
  }
}
