import Http from '../api/http/';

export default class userBrandForbiddenLog extends Http {
  constructor() {
    super();
  }

  /**
   * 获取用户禁用列表
   * @param {string} userId -  用户id
   * @param {string} brandName -  品牌名称
   * @param {integer} operateType -  操作类型 1-禁用 2-解禁
   * @param {integer} page -  页数
   * @param {integer} rows -  容量
   */
  userBrandForbiddenLog_getPageByUserId() {
    return super.post('userBrandForbiddenLog/getPageByUserId', ...arguments);
  }
}
