export default [
  {
    path: '/home/home', // 首页看板
    name: 'home_home',
    meta: {
      isHome: true,
      title: '首页',
    },
    component: resolve => require(['@/views/home/home'], resolve),
  },
  {
    path: '/home/userInfo',
    name: 'home_userInfo',
    meta: {
      keepAlive: true,
      title: '个人信息',
    },
    component: resolve => require(['@/views/home/userInfo'], resolve),
  },
];
