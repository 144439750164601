import Http from '../api/http/';
export default class RecommendTask extends Http {
  constructor() {
    super();
  }

  /***********推荐任务***************/
  /**
   * 新增推荐任务
   * @param {Array} recommendTaskDTO -  recommendTaskDTO
   */

  recommendTask_addRecommendTask() {
    return super.post('recommendTask/addRecommendTask', ...arguments);
  }

  /**
   * 推荐任务详情
   * @param {integer} recommendTaskId -  推荐任务id
   */

  recommendTask_recommendTaskDetail() {
    return super.post('recommendTask/recommendTaskDetail', ...arguments);
  }

  /**
   * 推荐任务列表
   * @param {string} recommendTaskName -  任务名称
   * @param {integer} taskStatus -  任务状态 0未发布 1未开始 2进行中 3已结束
   * @param {string} yearMonth -  时间年月 格式2021-06
   * @param {string} currentUserId -  当前操作人
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */

  recommendTask_recommendTaskList() {
    return super.post('recommendTask/recommendTaskList', ...arguments);
  }

  /**
   * 结束推荐任务
   * @param {integer} recommendTaskId -  推荐任务id
   */

  recommendTask_recommendTaskOver() {
    return super.post('recommendTask/recommendTaskOver', ...arguments);
  }

  /**
   * 推荐任务进度
   * @param {integer} recommendTaskId -  推荐任务主键id
   * @param {string} cityIds -  省市区 前端传输
   * @param {string} recommendNameOrTel -  推荐人姓名/手机号
   * @param {string} refRecommendNameOrTel -  被推荐人姓名/手机号
   * @param {integer} refRecommendUserStatus -  0-未注册 1-已注册未实名 2-已注册已实名
   * @param {integer} refRecommendUserTaskStatus -  0未指定, 1未签到, 2已签到, 3已签出, 4已提交, 5已驳回, 6已通过, 7已打款, 11未测验
   * @param {integer} recommendTaskUserStatus -  0-推荐中, 1-待付款, 2-支付成功(推荐成功)
   * @param {integer} cityId -  城市id 后端处理
   * @param {integer} countyId -  行政区id 后端处理
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */

  recommendTask_recommendTaskProgress() {
    return super.post('recommendTask/recommendTaskProgress', ...arguments);
  }

  /**
   * 推荐任务发布
   * @param {integer} recommendTaskId -  推荐任务id
   */

  recommendTask_recommendTaskPublish() {
    return super.post('recommendTask/recommendTaskPublish', ...arguments);
  }

  /**
   * 推荐任务撤回
   * @param {integer} recommendTaskId -  推荐任务id
   */

  recommendTask_recommendTaskRecall() {
    return super.post('recommendTask/recommendTaskRecall', ...arguments);
  }

  /**
   * 推荐任务删除
   * @param {integer} recommendTaskId -  推荐任务id
   */

  recommendTask_recommendTaskRemove() {
    return super.post('recommendTask/recommendTaskRemove', ...arguments);
  }

  /**
   * 修改推荐任务
   * @param {Array} recommendTaskDTO -  recommendTaskDTO
   */

  recommendTask_updateRecommendTask() {
    return super.post('recommendTask/updateRecommendTask', ...arguments);
  }

  /**
   * 区域任务导入
   * @param {file} uploadFile -  uploadFile
   */

  recommendTask_areaTaskImport() {
    return super.post('recommendTask/areaTaskImport', ...arguments);
  }
  /**
   * 导出推荐任务进度
   * @param {integer} recommendTaskId -  推荐任务主键id
   * @param {string} cityIds -  省市区 前端传输
   * @param {string} recommendNameOrTel -  推荐人姓名/手机号
   * @param {string} refRecommendNameOrTel -  被推荐人姓名/手机号
   * @param {integer} refRecommendUserStatus -  0-未注册 1-已注册未实名 2-已注册已实名
   * @param {integer} recommendTaskUserStatus -  任务状态 0未执行、1执行中、2已执行
   * @param {integer} cityId -  城市id 后端处理
   * @param {integer} countyId -  行政区id 后端处理
   * @param {integer} rewardPayStatus -  支付状态0推荐中1未支付2已支付
   * @param {array} recommendTaskUserIds -  undefined
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  recommendTask_exportRecommendTaskProgress() {
    return super.post('recommendTask/exportRecommendTaskProgress', ...arguments);
  }
  /**
   * 导出任务明细
   * @param {string} taskId -  任务id
   * @param {integer} progressStatus -  状态(0未领取(未中签)、1未签到、2已签到、3已签出、4已提交、5已驳回、6已通过、7已打款、9中签未领取 10已驳回终止 11未测验)
   * @param {string} userName -  嘉宾姓名
   * @param {string} storeName -  门店名称
   * @param {integer} testPassed -  小测验是否通过 0是 1否 null不限
   * @param {string} userPhone -  嘉宾手机
   * @param {integer} cityId -  城市id
   * @param {integer} provinceId -  省份id
   * @param {integer} completeTask -  是否完成过任务 0-否 1-是 有终审通过报告记录为是
   * @param {string} cityName -  城市名称
   * @param {string} countyName -  行政区名称
   * @param {string} businessName -  undefined
   * @param {integer} taskType -   任务类型 1抽签任务 2私探任务 3打卡任务
   * @param {integer} taskStatus -  任务状态 任务状态0-未发布，1-未开始，2-进行中，3-已结束
   * @param {string} companyId -  企业id
   * @param {string} brandId -  品牌id
   * @param {string} storeId -  门店id
   * @param {integer} taskSource -  任务来源 来源 1p端 2b端
   * @param {integer} receiveType -  获取任务的方式：0被指定 1自己领取
   * @param {integer} taskEndTimeYear -  年份
   * @param {integer} taskEndTimeMonth -  月份
   * @param {integer} permissionRange -  用户的数据范围 全部(不要传值) 1我负责的企业 2我负责的品牌
   * @param {string} taskEndTimeStart -  undefined
   * @param {string} taskEndTimeEnd -  undefined
   * @param {array} brandIds -  undefined
   * @param {string} taskName -  undefined
   * @param {array} cityIds -  undefined
   * @param {array} reportLogIds -  undefined
   * @param {ref} recommendTaskUserIds -  推荐任务id
   * @param {integer} recommendTaskId -  推荐任务主键id
   * @param {string} brandName -  品牌名称
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  recommendTaskUser_exportRecommendTaskUserReportLogRelations() {
    return super.get('recommendTaskUser/exportRecommendTaskUserReportLogRelations', ...arguments);
  }
}
