import Http from '@/api/http';

export default class MailConfigXunDian extends Http {
  constructor() {
    super();
  }
  /**
   * 获取邮件提醒
   * @returns
   */
  mailConfigXunDian_getMailConfig() {
    return super.post('mailConfigXunDian/getMailConfig', ...arguments);
  }
  /**
   * 保存邮件设置
   * @returns
   */
  mailConfigXunDian_saveMailConfig() {
    return super.post('mailConfigXunDian/saveMailConfig', ...arguments);
  }
}
