/**
 * @author LB
 * @description 运营路由
 */
export default [
  /***********调研任务************/
  {
    path: '/operation/surveyTask/taskList',
    name: 'operation_taskList',
    meta: {
      title: '任务列表',
    },
    component: resolve => require(['@/views/operation/surveyTask/taskList'], resolve),
  },
  {
    path: '/operation/surveyTask/difficultTask',
    name: 'operation_difficultTask',
    meta: {
      title: '困难任务',
    },
    component: resolve => require(['@/views/operation/surveyTask/difficultTask'], resolve),
  },
  {
    path: '/operation/surveyTask/taskProgress',
    name: 'operation_taskProgress',
    meta: {
      title: '任务进度',
    },
    component: resolve => require(['@/views/operation/surveyTask/taskProgress'], resolve),
  },
  {
    path: '/operation/surveyTask/taskSignature',
    name: 'operation_taskSignature',
    meta: {
      title: '任务补签',
    },
    component: resolve => require(['@/views/operation/surveyTask/taskSignature'], resolve),
  },
  {
    path: '/operation/surveyTask/taskAppeal',
    name: 'operation_taskAppeal',
    meta: {
      title: '任务申诉',
    },
    component: resolve => require(['@/views/operation/surveyTask/taskAppeal'], resolve),
  },
  /***********报告管理************/
  {
    path: '/operation/reportManage/reportAudit',
    name: 'operation_reportAudit',
    meta: {
      title: '报告审核',
    },
    component: resolve => require(['@/views/operation/reportManage/reportAudit'], resolve),
  },
  {
    path: '/operation/reportManage/reportDelivery',
    name: 'operation_reportDelivery',
    meta: {
      title: '报告交付',
    },
    component: resolve => require(['@/views/operation/reportManage/reportDelivery'], resolve),
  },
  {
    path: '/operation/reportManage/reportAppeal',
    name: 'operation_reportAppeal',
    meta: {
      title: '报告申诉',
    },
    component: resolve => require(['@/views/operation/reportManage/reportAppeal'], resolve),
  },
  /***********支付管理************/
  {
    path: '/operation/paymentManage/billBuild',
    name: 'operation_billBuild',
    meta: {
      title: '单据生成',
    },
    component: resolve => require(['@/views/operation/paymentManage/billBuild'], resolve),
  },
  {
    path: '/operation/paymentManage/billPayment',
    name: 'operation_billPayment',
    meta: {
      title: '单据支付',
    },
    component: resolve => require(['@/views/operation/paymentManage/billPayment'], resolve),
  },
  /***********模板管理************/
  {
    path: '/operation/templateManage',
    name: 'operation_templateManage',
    meta: {
      title: '模板管理',
    },
    component: resolve => require(['@/views/operation/templateManage/templateManage'], resolve),
  },
  /***********商务管理************/
  {
    path: '/operation/businessManage/billList',
    name: 'operation_billList',
    meta: {
      title: '账单列表',
    },
    component: resolve => require(['@/views/operation/businessManage/billList'], resolve),
  },
  {
    path: '/operation/businessManage/actualBillList',
    name: 'operation_actualBillList',
    meta: {
      title: '收入列表',
    },
    component: resolve => require(['@/views/operation/businessManage/actualBillList'], resolve),
  },
  {
    path: '/operation/businessManage/invoiceList',
    name: 'operation_invoiceList',
    meta: {
      title: '发票列表',
    },
    component: resolve => require(['@/views/operation/businessManage/invoiceList'], resolve),
  },
  {
    path: '/operation/businessManage/receiveList',
    name: 'operation_receiveList',
    meta: {
      title: '收款列表',
    },
    component: resolve => require(['@/views/operation/businessManage/receiveList'], resolve),
  },
  {
    path: '/operation/businessManage/walletRecharge',
    name: 'operation_walletRecharge',
    meta: {
      title: '钱包充值',
    },
    component: resolve => require(['@/views/operation/businessManage/walletRecharge'], resolve),
  },
  {
    path: '/operation/businessManage/virtualNumber',
    name: 'operation_virtualNumber',
    meta: {
      title: '银行虚拟号配置',
    },
    component: resolve => require(['@/views/operation/businessManage/virtualNumber'], resolve),
  },
  /***********推荐任务************/
  {
    path: '/operation/recommendTask/recommendTaskList',
    name: 'operation_recommendTaskList',
    meta: {
      title: '任务列表',
    },
    component: resolve => require(['@/views/operation/recommendTask/recommendTaskList'], resolve),
  },
  {
    path: '/operation/recommendTask/recommendBill',
    name: 'operation_recommendBill',
    meta: {
      title: '推荐单据',
    },
    component: resolve => require(['@/views/operation/recommendTask/recommendBill'], resolve),
  },
  /***********反馈管理************/
  {
    path: '/operation/feedbackManage/creditScoreApply',
    name: 'operation_creditScoreApply',
    meta: {
      title: '信用分申诉',
    },
    component: resolve => require(['@/views/operation/feedbackManage/creditScoreApply'], resolve),
  },
  /***********兼职管理************/
  {
    path: '/operation/concurrentPost/staffManage',
    name: 'operation_staffManage',
    meta: {
      title: '人员管理',
    },
    component: resolve => require(['@/views/operation/concurrentPost/staffManage'], resolve),
  },
  {
    path: '/operation/concurrentPost/salarySettlement',
    name: 'operation_salarySettlement',
    meta: {
      title: '薪资结算',
    },
    component: resolve => require(['@/views/operation/concurrentPost/salarySettlement'], resolve),
  },
  {
    path: '/operation/concurrentPost/partTimeExpenses',
    name: 'operation_partTimeExpenses',
    meta: {
      title: '兼职费用',
    },
    component: resolve => require(['@/views/operation/concurrentPost/partTimeExpenses'], resolve),
  },
];
