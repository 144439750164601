import Http from '../api/http/';

export default class ReportExperience extends Http {
  constructor() {
    super();
  }

  /***********在线报告用户体验描述管理***************/
  /**
   * 导出word
   * @param {string} reportTemplateId -  在线报告模板id
   * @param {string} reportExperienceId -  用户体验描述id
   */
  reportExperience_exprotWord() {
    return super.get('reportExperience/exprotWord', ...arguments);
  }

  /**
   * 报告用户体验描述管理添加(修改)
   * @param {Array} reportExperienceDto -  reportExperienceDto
   */
  reportExperience_save() {
    return super.post('reportExperience/save', ...arguments);
  }

  // 免登录查看报告
  reportExperience_getOnlineReport() {
    return super.post('reportExperience/getOnlineReport', ...arguments);
  }
  // 删除报告批注
  reportExperience_clearPostil() {
    return super.post('reportExperience/clearPostil', ...arguments);
  }
  /**
   * 获取报告树结构
   * @param {String} reportExperienceId -  reportExperienceId
   */
  reportExperience_getReportExperience() {
    return super.post('reportExperience/getReportExperience', ...arguments);
  }
  /**
   * 保存报告
   * @param {String} reportExperience -  reportExperience
   */
  reportExperience_saveReportExperience() {
    return super.post('reportExperience/saveReportExperience', ...arguments, {});
  }
  /**
   * 分享新在线报告（免登录）
   * @param {string} reportExperienceId -  用户体验描述id
   */

  reportExperience_shareTemplateExperience() {
    return super.post('reportExperience/shareTemplateExperience', ...arguments);
  }
  /**
   * 保存报告树结构或者数组
   * @param {Array} reportExperienceContentVo -  reportExperienceContentVo
   */
  reportExperience_saveReportExperienceContent() {
    return super.post('reportExperience/saveReportExperienceContent', ...arguments, {});
  }
  /**
   * 获取报告树结构或者数组
   * @param {string} reportExperienceId -  用户体验描述id
   * @param {integer} isShowAll -  是否显示所有关联题1是0否
   */
  reportExperience_getReportExperienceContent() {
    return super.post('reportExperience/getReportExperienceContent', ...arguments);
  }
}
