import Http from '../api/http/';

export default class BigEvent extends Http {
  constructor() {
    super();
  }
  /**
   * 添加大事件
   * @param {Array} bigEventDTO -  bigEventDTO
   */

  bigEvent_add() {
    return super.post('bigEvent/add', ...arguments, {});
  }

  /**
   * 删除大事件
   * @param {integer} id -  id
   */

  bigEvent_delete() {
    return super.post('bigEvent/delete', ...arguments);
  }

  /**
   * 获取大事件类型
   */

  bigEvent_getOptionType() {
    return super.post('bigEvent/getOptionType', ...arguments);
  }

  /**
   * 大事件列表
   * @param {integer} eventType -  大事件类型 1企业大事件 2品牌大事件
   * @param {string} refId -  关联id
   * @param {integer} page -  页数
   * @param {integer} rows -  每页行数
   */

  bigEvent_list() {
    return super.post('bigEvent/list', ...arguments);
  }

  /**
   * 修改大事件
   * @param {Array} bigEventDTO -  bigEventDTO
   */

  bigEvent_update() {
    return super.post('bigEvent/update', ...arguments, {});
  }
  /**
   * 大事件详情
   * @param {integer} id -  大事件id
   */

  bigEvent_detail() {
    return super.post('bigEvent/detail', ...arguments);
  }
}
