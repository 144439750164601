import Http from '../api/http/';
export default class AppGoodsConfig extends Http {
  constructor() {
    super();
  }
  /**
   * app商品新增
   * @param {Array} appGoodsConfigDTO -  appGoodsConfigDTO
   */
  appGoodsConfig_addAppGoods() {
    return super.post('appGoodsConfig/addAppGoods', ...arguments, {});
  }

  /**
   * app商品详情
   * @param {string} id -  id
   */
  appGoodsConfig_appGoodsDetail() {
    return super.post('appGoodsConfig/appGoodsDetail', ...arguments);
  }

  /**
   * app商品分页列表
   * @param {Array} appGoodsConfigBO -  appGoodsConfigBO
   */
  appGoodsConfig_appGoodsListByPage() {
    return super.post('appGoodsConfig/appGoodsListByPage', ...arguments, {});
  }

  /**
   * app商品删除
   * @param {string} id -  id
   */
  appGoodsConfig_deleteAppGoods() {
    return super.post('appGoodsConfig/deleteAppGoods', ...arguments);
  }

  /**
   * app商品上下线
   * @param {string} id -  id
   * @param {integer} recordStatus -  recordStatus
   */
  appGoodsConfig_onOffLineAppGoods() {
    return super.post('appGoodsConfig/onOffLineAppGoods', ...arguments);
  }

  /**
   * app商品修改
   * @param {Array} appGoodsConfigDTO -  appGoodsConfigDTO
   */
  appGoodsConfig_updateAppGoods() {
    return super.post('appGoodsConfig/updateAppGoods', ...arguments, {});
  }
}
