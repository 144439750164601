import Http from '../api/http/';

export default class Oapi extends Http {
  constructor() {
    super();
  }

  /***********登录***************/

  /**
   * 行为验证码
   * {captcha} 行为验证码参数
   */
  oapi_captchaGenerate() {
    return super.post('oapi/captchaGenerate', ...arguments);
  }
  /**
   * 手机号验证登录
   * {captcha} 行为验证码
   * {account} 手机号
   */
  oapi_loginIn() {
    return super.post('oapi/loginIn', ...arguments);
  }
  /**
   * 验证码获取
   * {captcha} 行为验证码
   */
  oapi_sendVC() {
    return super.post('oapi/sendVC', ...arguments);
  }
  /**
   * 微信授权登录
   * {code} 微信授权码
   */
  oapi_weixinVerify() {
    return super.post('oapi/weixinVerify', ...arguments);
  }
}
