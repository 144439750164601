import Vue from 'vue';
import Env from './env';
import ossConfig from './ossConfig';
export default class OssService {
  constructor() {
    this.client = null;
  }

  /*******OSS上传图片*******/
  async uploadImage(file, uid = 'noname', progressCallBack) {
    let fileName = file.name,
      ossFileName = `haiTanWeb_P/${uid}/${moment().format('YYYYMMDDHHmmssSSS')}${Math.round(Math.random() * 100)}/${fileName}`;
    if (encodeURIComponent(fileName).length > 350) {
      Vue.api.toast('图片名称过长', 'warning');
      throw new Error('图片名称过长');
    }
    if (!this.client) {
      this.initClient();
    }
    let url = '';
    try {
      await this.client.multipartUpload(ossFileName, file, {
        progress: p => {
          return function (done) {
            if (typeof progressCallBack === 'function') {
              progressCallBack(p);
            }
            done();
          };
        },
      });
      url = this.client.signatureUrl(ossFileName, { expires: 31536000000 });
      url = url.substring(0, url.indexOf('?')); // 去除地址上面的参数
      url = url.replace(/oss-cn-(?:hangzhou|shanghai).aliyuncs/g, 'mwdwz'); // 替换短地址
    } catch (error) {
      Vue.api.toast(error, 'error');
      throw new Error(error);
    }
    return {
      ossFileName: ossFileName,
      url: url,
    };
  }

  /*******OSS下载文件*******/
  async download(file, folder = 'haiTanWeb_P') {
    // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
    const fileUrl = file && decodeURIComponent(file);
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
    const path = fileUrl.substring(fileUrl.indexOf(folder));
    const response = {
      'content-disposition': `attachment; filename=${encodeURIComponent(fileName)}`,
    };
    if (!this.client) {
      await this.initClient();
    }
    const url = this.client.signatureUrl(path, { response });
    return new Promise((resolve, reject) => {
      const iframeId = 'download-iframe-link';
      let iframe = document.getElementById(iframeId);
      if (!iframe) {
        iframe = document.createElement('iframe');
        iframe.id = iframeId;
        iframe.style.display = 'none';
        iframe.setAttribute('data-html2canvas-ignore', true);
        document.body.appendChild(iframe);
      }
      iframe.src = url;
      // 监听加载完成
      let timer = setInterval(() => {
        try {
          let iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
          if (iframeDoc.readyState == 'complete' || iframeDoc.readyState == 'interactive') {
            resolve({ code: 0, msg: '文件下载成功' });
            clearInterval(timer);
          }
        } catch (error) {
          timer && clearInterval(timer);
          iframe.src = '';
          reject(error);
        }
      }, 500);
      iframe.onerror = function (e) {
        reject(e);
      };
    });
  }

  /*******初始化Client*******/
  initClient() {
    let env = new Env();
    this.client = new OSS.Wrapper({
      accessKeyId: 'LTAI4G245gQyjNkdSv4N9jar',
      accessKeySecret: 'f9GKwBult8oHLvL6OijrYwGDsREYpD',
      bucket: ossConfig[env.getEnv()].oss_bucket,
      region: ossConfig[env.getEnv()].oss_region,
      secure: true,
    });
  }
}
