<template>
  <span>
    <span v-popover:popoverContainer class="gl-explain">
      <i class="iconfont" :class="icon"></i>
      <span v-if="title">{{ title }}</span>
    </span>
    <el-popover ref="popoverContainer" :placement="placement" :title="title" width="400" :trigger="trigger" :content="content" popper-class="cm-user-select"></el-popover>
  </span>
</template>

<script>
export default {
  props: {
    content: String,
    title: String,
    icon: {
      type: String,
      default: 'iconshili',
    },
    trigger: {
      type: String,
      default: 'click',
    },
    placement: {
      type: String,
      default: 'top-start',
    },
  },
};
</script>
<style lang="less" scoped>
.gl-explain {
  display: inline-flex;
  align-items: center;
  color: #999;
  line-height: 14px;
  cursor: pointer;
  i {
    font-size: 14px;
    & + span {
      margin-left: 4px;
    }
  }
}
</style>
