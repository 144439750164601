import Http from '../api/http';

export default class CompanyMessage extends Http {
  constructor() {
    super();
  }
  /**
   * 企业消息通知
   * @param {Array} companyMessageDto -  companyMessageDto
   */
  companyMessage_add() {
    return super.post('companyMessage/add', ...arguments);
  }
  /**
   * 企业消息通知修改
   * @param {Array} companyMessageDto -  companyMessageDto
   */
  companyMessage_update() {
    return super.post('companyMessage/update', ...arguments);
  }
  /**
   * 企业消息通知详情
   * @param {Array} companyMessageDetailDto -  companyMessageDetailDto
   */
  companyMessage_detail() {
    return super.post('companyMessage/detail', ...arguments);
  }
  /**
   * 企业消息通知
   * @param {Array} companyMessageQueryDto -  companyMessageQueryDto
   */
  companyMessage_list() {
    return super.post('companyMessage/list', ...arguments);
  }
  /**
   * 获取指定企业
   * @param {Array} companyMessagePageCompanyDto -  companyMessagePageCompanyDto
   */
  companyMessage_getCompanyPage() {
    return super.post('companyMessage/getCompanyPage', ...arguments);
  }

  /**
   * 获取指定企业下用户
   * @param {Array} companyMessageQueryDto -  companyMessageQueryDto
   */
  companyMessage_getPageUser() {
    return super.post('companyMessage/getPageUser', ...arguments);
  }
}
