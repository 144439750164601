import Http from '../api/http/';

export default class DataClassify extends Http {
  constructor() {
    super();
  }
  /***********数据分析分类管理***************/
  /**
   * 分类管理添加
   * @param {Array} dataClassifyDto -  dataClassifyDto
   */
  dataClassify_add() {
    return super.post('dataClassify/add', ...arguments);
  }

  /**
   * 删除
   * @param {string} id -  id
   */
  dataClassify_delete() {
    return super.post('dataClassify/delete', ...arguments);
  }

  /**
   * 通过id查询
   * @param {string} id -  id
   */
  dataClassify_getById() {
    return super.post('dataClassify/getById', ...arguments);
  }

  /**
   * 查询所有（下拉）
   * @param {integer} level -  级别：0：全部；1：一级；2：二级...
   */
  dataClassify_getList() {
    return super.post('dataClassify/getList', ...arguments);
  }

  /**
   * 分类管理分页查询
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   * @param {string} name -  分类名
   * @param {integer} level -  级别：0：全部；1：一级；2：二级...
   */
  dataClassify_getListByPage() {
    return super.post('dataClassify/getListByPage', ...arguments);
  }

  /**
   * 分类管理修改
   * @param {Array} dataClassifyDto -  dataClassifyDto
   */
  dataClassify_update() {
    return super.post('dataClassify/update', ...arguments);
  }

  /**
   * 批量导入
   * @param file
   */
  dataClassify_importExcel() {
    return super.post('dataClassify/importExcel', ...arguments);
  }
  // 获取1、2、3级分类
  dataClassify_getListGroupByLevel() {
    return super.post('dataClassify/getListGroupByLevel', ...arguments);
  }
}
