import Http from '@/api/http/';

export default class AiAudit extends Http {
  constructor() {
    super();
  }
  /**
   * 获取批注
   * @params { reportExperienceInfoId }
   * @returns
   */
  aiAudit_aiPostil() {
    return super.post('aiAudit/aiPostil', ...arguments);
  }

  /**
   * 智能小测验
   * @param {string} taskDesc -  taskDesc
   */
  aiAudit_aiQuiz() {
    return super.post('aiAudit/aiQuiz', ...arguments);
  }
}
