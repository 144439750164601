export default {
  inserted: function (el) {
    try {
      if (!el.previousElementSibling || el.previousElementSibling.style.display == 'none' || el.previousElementSibling.className == 'gl-link-line') {
        if (el.parentNode) {
          el.parentNode.removeChild(el);
        } else {
          el.innerHTML = '';
          el.style.display = 'none';
        }
      }
    } catch (ex) {
      console.warn(ex);
    }
  },
};
