<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      versionTimer: null,
    };
  },
  created() {
    if (this.api.env.getEnv() !== 'dev') {
      this.versionTimer = setInterval(this.versionHandle, 6000);
    }
    this.bInstance.init(this);
  },
  methods: {
    async versionHandle() {
      let result = await axios.get('version.json', {
        params: {
          timestamp: new Date().getTime(),
        },
      });
      let { data } = result;
      let currentVersion = this.$store.getters.getCurrentVersion;
      let serverVersion = data.version;
      if (currentVersion && currentVersion !== serverVersion) {
        this.$store.commit('Set_Current_Version', serverVersion);
        this.api.toast('发现新版本，网页即将自动刷新！', 'warning');
        window.location.reload();
      }
      if (!currentVersion) {
        this.$store.commit('Set_Current_Version', serverVersion);
      }
    },
  },
  beforeDestroy() {
    this.versionTimer && clearInterval(this.versionTimer);
    this.bInstance.destroy();
  },
};
</script>
