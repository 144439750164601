import Http from '@/api/http';

export default class MonthLostReportData extends Http {
  constructor() {
    super();
  }
  /**
   * 流失客户
   * @param {companyId,departmentId,month,year,page,rows}
   * @returns
   */
  monthLostReportData_queryListByPagination() {
    return super.post('monthLostReportData/queryListByPagination', ...arguments);
  }
  /**
   * 流失客户导出
   * @returns
   */
  monthLostReportData_export() {
    return super.blobDownload('monthLostReportData/export', ...arguments);
  }
}
