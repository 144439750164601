import { Message, MessageBox, Loading } from 'element-ui';
export default class {
  toast(title, type = 'success', option = {}) {
    return Message({
      message: title,
      type: type,
      duration: 2000,
      ...option,
    });
  }
  loading(text) {
    return Loading.service({
      lock: true,
      text: text || 'Loading',
      spinner: 'el-icon-loading',
    });
  }
  alert(msg, options, title = '提示') {
    return MessageBox.alert(msg, title, {
      confirmButtonText: '确定',
      ...options,
    });
  }
  confirm(msg, title = '提示', options = {}) {
    return MessageBox.confirm(msg, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      ...options,
    });
  }
  prompt(msg, title = '提示', options = {}) {
    return MessageBox.prompt(msg, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      ...options,
    });
  }
}
