import Http from '../api/http/';
export default class BusinessStore extends Http {
  constructor() {
    super();
  }
  //获取商圈门店列表
  businessStore_getBusinessStoreList() {
    return super.post('businessStore/getBusinessStoreList', ...arguments);
  }
  //获取门店列表
  businessStore_getStoreList() {
    return super.post('businessStore/getStoreList', ...arguments);
  }
  //通过商圈获取到所有的门店
  businessStore_getStoreListByBusinessCity() {
    return super.post('businessStore/getStoreListByBusinessCity', ...arguments);
  }
  //更改商圈下门店
  businessStore_updateBusinessStore() {
    return super.post('businessStore/updateBusinessStore', ...arguments);
  }
  //更新门店所属商圈、
  businessStore_updateStoreBusiness() {
    return super.post('businessStore/updateStoreBusiness', ...arguments);
  }
  //通过门店获取到该城市所有商圈
  businessStore_getBusinessListByStoreCity() {
    return super.post('businessStore/getBusinessListByStoreCity', ...arguments);
  }
  //门店自动绑定商圈
  businessStore_autoBind() {
    return super.post('businessStore/autoBind', ...arguments);
  }
}
