import Http from '../api/http/';
export default class Medal extends Http {
  constructor() {
    super();
  }
  // 创建勋章
  medal_addMedalDetail() {
    return super.post('medal/addMedalDetail', ...arguments);
  }
  // 删除勋章
  medal_deleteMedal() {
    return super.post('medal/deleteMedal', ...arguments);
  }
  // 修改勋章
  medal_updateMedalDetail() {
    return super.post('medal/updateMedalDetail', ...arguments);
  }
  // 上下线勋章
  medal_updateMedalState() {
    return super.post('medal/updateMedalState', ...arguments);
  }
  medal_getMedalListByPage() {
    return super.post('medal/getMedalListByPage', ...arguments);
  }
  //勋章详情
  medal_getMedalDetailById() {
    return super.post('medal/getMedalDetailById', ...arguments);
  }
  // 通过勋章类型下拉类别
  medal_getMedalTypeList() {
    return super.post('medal/getMedalTypeList', ...arguments);
  }
}
