export default {
  pro: {
    oss_bucket: 'manwei01',
    oss_region: 'oss-cn-hangzhou',
  },
  pre: {
    oss_bucket: 'manwei00',
    oss_region: 'oss-cn-shanghai',
  },
  tes: {
    oss_bucket: 'manwei00',
    oss_region: 'oss-cn-shanghai',
  },
  dev: {
    oss_bucket: 'manwei00',
    oss_region: 'oss-cn-shanghai',
  },
};
