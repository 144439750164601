import Http from '../api/http/';
export default class PlatInvite extends Http {
  constructor() {
    super();
  }
  // 关闭链接
  platInvite_colseInvite() {
    return super.post('platInvite/colseInvite', ...arguments);
  }
  // 删除链接
  platInvite_deleteInvite() {
    return super.post('platInvite/deleteInvite', ...arguments);
  }
  // 获取邀请二维码key
  platInvite_generateInviteDetectiveKey() {
    return super.post('platInvite/generateInviteDetectiveKey', ...arguments);
  }
  // 获取邀请列表
  platInvite_getInviteList() {
    return super.post('platInvite/getInviteList', ...arguments);
  }
  // 获取邀请注册人列表
  platInvite_getInviteUserList() {
    return super.post('platInvite/getInviteUserList', ...arguments);
  }
  // 打开链接
  platInvite_openInvite() {
    return super.post('platInvite/openInvite', ...arguments);
  }
  // 编辑邀请
  platInvite_updateInvite() {
    return super.post('platInvite/updateInvite', ...arguments);
  }
  // 导出邀请用户信息
  platInvite_exportInviteUser() {
    return super.get('platInvite/exportInviteUser', ...arguments);
  }
}
