/**
 * @author LB
 * @description 报表路由
 */
export default [
  /*************业务报表**************************************/
  {
    path: '/reportForm/businessReportForm/payDetail',
    name: 'reportForm_payDetail',
    meta: {
      title: '付款明细',
    },
    component: resolve => require(['@/views/reportForm/businessReportForm/payDetail'], resolve),
  },
  {
    path: '/reportForm/businessReportForm/businessRecord',
    name: 'reportForm_businessRecord',
    meta: {
      title: '业务计件表(旧)',
    },
    component: resolve => require(['@/views/reportForm/businessReportForm/businessRecord'], resolve),
  },
  {
    path: '/reportForm/businessReportForm/businessReckonPiece',
    name: 'reportForm_businessReckonPiece',
    meta: {
      title: '业务计件表',
    },
    component: resolve => require(['@/views/reportForm/businessReportForm/businessReckonPiece'], resolve),
  },
  {
    path: '/reportForm/businessReportForm/enterprisePiecework',
    name: 'reportForm_enterprisePiecework',
    meta: {
      title: 'B端计件',
    },
    component: resolve => require(['@/views/reportForm/businessReportForm/enterprisePiecework'], resolve),
  },
  {
    path: '/reportForm/businessReportForm/lostCustomer',
    name: 'reportForm_lostCustomer',
    meta: {
      title: '流失客户表',
    },
    component: resolve => require(['@/views/reportForm/businessReportForm/lostCustomer'], resolve),
  },
  {
    path: '/reportForm/businessReportForm/financialRevenue',
    name: 'reportForm_financialRevenue',
    meta: {
      title: '财务收支',
    },
    component: resolve => require(['@/views/reportForm/businessReportForm/financialRevenue'], resolve),
  },
  {
    path: '/reportForm/businessReportForm/platformStatistics',
    name: 'reportForm_platformStatistics',
    meta: {
      title: '平台统计',
    },
    component: resolve => require(['@/views/reportForm/businessReportForm/platformStatistics'], resolve),
  },
  {
    path: '/reportForm/businessReportForm/overtimeReportForm',
    name: 'reportForm_overtimeReportForm',
    meta: {
      title: '超时报表',
    },
    component: resolve => require(['@/views/reportForm/businessReportForm/overtimeReportForm'], resolve),
  },
  {
    path: '/reportForm/businessReportForm/appointTaskReportForm',
    name: 'reportForm_appointTaskReportForm',
    meta: {
      title: '指定报表',
    },
    component: resolve => require(['@/views/reportForm/businessReportForm/appointTaskReportForm'], resolve),
  },
  {
    path: '/reportForm/businessReportForm/onePassReportForm',
    name: 'reportForm_onePassReportForm',
    meta: {
      title: '报告一次性通过率',
    },
    component: resolve => require(['@/views/reportForm/businessReportForm/onePassReportForm'], resolve),
  },

  /*************用户报表**************************************/
  {
    path: '/reportForm/userReportForm/userRecommend',
    name: 'reportForm_userRecommend',
    meta: {
      title: '用户推荐',
    },
    component: resolve => require(['@/views/reportForm/userReportForm/userRecommend'], resolve),
  },
  {
    path: '/reportForm/userReportForm/taskUserDistribution',
    name: 'reportForm_taskUserDistribution',
    meta: {
      title: '任务用户分布',
    },
    component: resolve => require(['@/views/reportForm/userReportForm/taskUserDistribution'], resolve),
  },
  {
    path: '/reportForm/userReportForm/userPortrait',
    name: 'reportForm_userPortrait',
    meta: {
      title: '用户画像',
    },
    component: resolve => require(['@/views/reportForm/userReportForm/userPortrait'], resolve),
  },
  {
    path: '/reportForm/userReportForm/registeredUser',
    name: 'reportForm_registeredUser',
    meta: {
      title: '注册用户',
    },
    component: resolve => require(['@/views/reportForm/userReportForm/registeredUser'], resolve),
  },
  /*************抽签报表**************************************/
  {
    path: '/reportForm/drawReportForm/businessDistrictRegistration',
    name: 'reportForm_businessDistrictRegistration',
    meta: {
      title: '商圈情况',
    },
    component: resolve => require(['@/views/reportForm/drawReportForm/businessDistrictRegistration'], resolve),
  },
  {
    path: '/reportForm/drawReportForm/winningDetail',
    name: 'reportForm_winningDetail',
    meta: {
      title: '中奖明细',
    },
    component: resolve => require(['@/views/reportForm/drawReportForm/winningDetail'], resolve),
  },
  /*************费用报表**************************************/
  {
    path: '/reportForm/costReportForm/virtualTelephoneStatistic',
    name: 'reportForm_virtualTelephoneStatistic',
    meta: {
      title: '虚拟电话统计',
    },
    component: resolve => require(['@/views/reportForm/costReportForm/virtualTelephoneStatistic'], resolve),
  },
  {
    path: '/reportForm/costReportForm/companyRechargeReport',
    name: 'reportForm_companyRechargeReport',
    meta: {
      title: '企业充值表',
    },
    component: resolve => require(['@/views/reportForm/costReportForm/companyRechargeReport'], resolve),
  },
  {
    path: '/reportForm/dataBoard/reportFormBI/:pathMatch(.*)*',
    name: 'reportForm_reportFormBI',
    meta: {
      title: 'BI报表',
    },
    component: resolve => require(['@/views/reportForm/dataBoard/reportFormBI'], resolve),
  },
  {
    path: '/reportForm/dataBoard/dataSubscribe/:pathMatch(.*)*',
    name: 'reportForm_dataSubscribe',
    meta: {
      title: '数据订阅',
    },
    component: resolve => require(['@/views/reportForm/dataBoard/dataSubscribe'], resolve),
  },
  {
    path: '/reportForm/dataBoard/dataTemplate/:pathMatch(.*)*',
    name: 'reportForm_dataTemplate',
    meta: {
      title: '数据模板',
    },
    component: resolve => require(['@/views/reportForm/dataBoard/dataTemplate'], resolve),
  },
];
