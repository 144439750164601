export default [
  {
    path: '/tourStore/applyTrail',
    name: 'tourStore_applyTrail',
    meta: {
      title: '申请试用',
    },
    component: resolve => require(['@/views/tourStore/applyTrail/applyTrail.vue'], resolve),
  },
];
