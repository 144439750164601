import Http from '../api/http/';

export default class ExportTask extends Http {
  constructor() {
    super();
  }

  //报告交付下载列表
  exportTask_getList() {
    return super.post('exportTask/getList', ...arguments);
  }
}
