import Http from '@/api/http/';

export default class LeavingMessageXunDian extends Http {
  constructor() {
    super();
  }

  /***********申请试用管理***************/
  /**
   * 处理申请试用
   * @param {string} id -  试用id
   */
  leavingMessageXunDian_dealWith() {
    return super.post('leavingMessageXunDian/dealwith', ...arguments);
  }
  /**
   * 申请试用详情
   * @param {string} id -  留言id
   */
  leavingMessageXunDian_detail() {
    return super.post('leavingMessageXunDian/detail', ...arguments);
  }
  /**
   * 申请试用列表
   * @param {formData}
   */
  leavingMessageXunDian_list() {
    return super.post('leavingMessageXunDian/list', ...arguments);
  }
}
