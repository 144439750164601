import Http from '../api/http/';
export default class ClassifyManage extends Http {
  constructor() {
    super();
  }
  dataClassify_getListByPage() {
    return super.post('dataClassify/getListByPage', ...arguments);
  }
  /**
   * @description 获取分类管理数据
   * @param
   */
  dataClassify_update() {
    return super.post('dataClassify/update', ...arguments);
  }
  /**
   * @description 编辑更新分类数据
   * @param 更新id
   */
  dataClassify_delete() {
    return super.post('dataClassify/delete', ...arguments);
  }
  /**
   * @description 删除分类数据
   * @param  删除id
   */
  dataClassify_add() {
    return super.post('dataClassify/add', ...arguments);
  }
}
