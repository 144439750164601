import Http from '../api/http/';
export default class PaymentSubject extends Http {
  constructor() {
    super();
  }
  /***********配置-公司主体配置***************/
  /**
   * 新增支付主体
   * @param {Array} paymentSubjectDTO -  paymentSubjectDTO
   */
  paymentSubject_addPaymentSubject() {
    return super.post('paymentSubject/addPaymentSubject', ...arguments);
  }

  /**
   * 删除支付主体
   * @param {Array} idDto -  idDto
   */
  paymentSubject_deletePaymentSubject() {
    return super.post('paymentSubject/deletePaymentSubject', ...arguments);
  }

  /**
   * 获取主体配置列表
   * @param {Array} PagingDto -  PagingDto
   */
  paymentSubject_getPaymentSubjectList() {
    return super.post('paymentSubject/getPaymentSubjectList', ...arguments);
  }

  /**
   * 修改支付主体
   * @param {Array} paymentSubjectDTO -  paymentSubjectDTO
   */
  paymentSubject_updatePaymentSubject() {
    return super.post('paymentSubject/updatePaymentSubject', ...arguments);
  }
}
