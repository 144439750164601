<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
    <div slot="footer">
      <slot name="footer">
        <el-button type="text" class="gl-btn-plain" @click="dialogVisible = false">关闭</el-button>
      </slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  padding: 20px;
  border-bottom: 1px solid #eaeaea;
}
/deep/ .el-dialog__body {
  padding: 20px;
}
/deep/ .el-dialog__footer {
  padding: 20px;
  border-top: 1px solid #eaeaea;
  text-align: center;
}
</style>
