/**
 * @author LB
 * @description 公共组件
 */
import glBreadcrumb from './glBreadcrumb';
import glIcon from './glIcon';
import glInputDisabled from './glInputDisabled';
import glOperateMenu from './glOperateMenu';

export default {
  install: function (Vue) {
    Vue.component('gl-breadcrumb', glBreadcrumb);
    Vue.component('gl-icon', glIcon);
    Vue.component('gl-input-disabled', glInputDisabled);
    Vue.component('gl-operate-menu', glOperateMenu);
  },
};
