<template>
  <i v-if="type === 'font'" :class="['iconfont', name]" :style="`font-size:${size}px;`" v-bind="$attrs" v-on="$listeners"></i>
  <svg v-else class="svg-icon" aria-hidden="true" :style="`width:${width}px;height:${height}px;`" v-bind="$attrs" v-on="$listeners">
    <use :xlink:href="`#${name}`" />
  </svg>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
    },
    type: {
      type: String,
      default: 'font',
      validator(val) {
        return ['font', 'symbol'].includes(val);
      },
    },
    size: {
      type: [Number, String],
      default() {
        return '14';
      },
      validator(val) {
        return /^\d+$/.test(val);
      },
    },
    width: {
      type: [Number, String],
      default() {
        return '14';
      },
      validator(val) {
        return /^\d+$/.test(val);
      },
    },
    height: {
      type: [Number, String],
      default() {
        return '14';
      },
      validator(val) {
        return /^\d+$/.test(val);
      },
    },
  },
};
</script>
<style>
.svg-icon {
  fill: currentColor;
  overflow: hidden;
}
</style>
