import Http from '../api/http/';
export default class CompanyDetective extends Http {
  constructor() {
    super();
  }
  // 用户标签标记页面全部标签，显示是否已标记
  companyDetective_allUserLabelsSelected() {
    return super.post('companyDetective/allUserLabelsSelected', ...arguments);
  }
  // 批量移出私探
  companyDetective_batchRemovePrivateCustomer() {
    return super.post('companyDetective/batchRemovePrivateCustomer', ...arguments);
  }
  // 批量设为私探
  companyDetective_batchSetPrivateCustomer() {
    return super.post('companyDetective/batchSetPrivateCustomer', ...arguments);
  }
  // 导出嘉宾
  companyDetective_exportCompanyDetective() {
    return super.get('companyDetective/exportCompanyDetective', ...arguments);
  }
  // 获取邀请私探二维码key
  companyDetective_generateInviteDetectiveKey() {
    return super.post('companyDetective/generateInviteDetectiveKey', ...arguments);
  }
  // 嘉宾用户信息
  companyDetective_getCustomerInfo() {
    return super.post('companyDetective/getCustomerInfo', ...arguments);
  }
  // 嘉宾列表
  companyDetective_list() {
    return super.post('companyDetective/list', ...arguments);
  }
  // 移除私探
  companyDetective_removePrivateCustomer() {
    return super.post('companyDetective/removePrivateCustomer', ...arguments);
  }
  // 设为私探
  companyDetective_setPrivateCustomer() {
    return super.post('companyDetective/setPrivateCustomer', ...arguments);
  }
  // 修改用户备注
  companyDetective_updateRemark() {
    return super.post('companyDetective/updateRemark', ...arguments);
  }
  // 更新用户标签
  companyDetective_updateUserLabelMark() {
    return super.post('companyDetective/updateUserLabelMark', ...arguments);
  }
  // 查看用户的任务记录
  companyDetective_userTaskRecord() {
    return super.post('companyDetective/userTaskRecord', ...arguments);
  }
  /**
   * 移除用户标签
   * @param {Array} userLabelDto -  userLabelDto
   */

  companyDetective_removeUserLabelMark() {
    return super.post('companyDetective/removeUserLabelMark', ...arguments);
  }
  /**
   * 添加用户标签
   * @param {Array} userLabelDto -  userLabelDto
   */

  companyDetective_addUserLabelMark() {
    return super.post('companyDetective/addUserLabelMark', ...arguments);
  }
}
