/**
 * @author LB
 * @description 全局组件
 */
import CommonComponents from './commonComponents';
import ElementComponents from './elementComponents';
import OtherComponents from './otherComponents';

export default {
  install: function (Vue) {
    Vue.use(CommonComponents);
    Vue.use(ElementComponents);
    Vue.use(OtherComponents);
  },
};
