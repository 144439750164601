<template>
  <div>
    <el-image :preview-src-list="value" :ref="id" src=""></el-image>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: 'previewImage',
    },
  },
  data() {
    return {};
  },
  mounted() {
    document.addEventListener('click', this.eventListener, false);
  },
  methods: {
    preview() {
      this.$nextTick(() => {
        this.$refs[this.id].$data.showViewer = true;
      });
    },
    eventListener(e) {
      if (e.target.className === 'el-image-viewer__mask') {
        this.$refs[this.id].$data.showViewer = false;
      }
    },
  },
  destroyed() {
    document.removeEventListener('click', this.eventListener);
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-image {
  display: inline;
}
/deep/ .el-image__error {
  display: none;
}
</style>
