import Http from '../api/http/';

export default class TemplatePricingConfig extends Http {
  constructor() {
    super();
  }
  templatePricingConfig_list() {
    return super.post('templatePricingConfig/list', ...arguments, {});
  }
  templatePricingConfig_edit() {
    return super.post('templatePricingConfig/edit', ...arguments, {});
  }
}
