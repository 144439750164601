/**
 * @author LB
 * @description 其他组件编辑
 */
import GlDialogQRCode from './glDialogQRCode';
import GlExplain from './glExplain';
import GlTab from './glTab';
import GlTooltip from './glTooltip';
import GlWangEditor from './glWangEditor';
import GlWebCall from './glWebCall';

export default {
  install: function (Vue) {
    Vue.component('gl-dialog-qr-code', GlDialogQRCode);
    Vue.component('gl-explain', GlExplain);
    Vue.component('gl-tab', GlTab);
    Vue.component('gl-tooltip', GlTooltip);
    Vue.component('gl-wang-editor', GlWangEditor);
    Vue.component('gl-web-call', GlWebCall);
  },
};
