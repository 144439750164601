import Http from '../api/http/';
export default class RecommendReceipts extends Http {
  constructor() {
    super();
  }
  /**
   * 积分支付
   * @param {integer} recommendTaskUserId -  recommendTaskUserId
   */

  recommendReceipts_activeScorePay() {
    return super.post('recommendReceipts/activeScorePay', ...arguments);
  }

  /**
   * 生成支付单据
   * @param {Array} receiptsCreateDTO -  receiptsCreateDTO
   */

  recommendReceipts_addReceipts() {
    return super.post('recommendReceipts/addReceipts', ...arguments, {});
  }

  /**
   * 终止任务
   * @param {integer} recommendTaskUserId -  recommendTaskUserId
   * @param {string} reason -  reason
   */

  recommendReceipts_forceEndUserTask() {
    return super.post('recommendReceipts/forceEndUserTask', ...arguments);
  }

  /**
   * 生成支付单列表
   * @param {Array} idList -  id
   */

  recommendReceipts_getReceiptsPayInfo() {
    return super.post('recommendReceipts/getReceiptsPayInfo', ...arguments, {});
  }

  /**
   * 推荐积分单据列表
   */

  recommendReceipts_getRecommendActiveScoreReceiptsList() {
    return super.post('recommendReceipts/getRecommendActiveScoreReceiptsList', ...arguments, {});
  }
  /**
   * 推荐积分单据导出
   */

  recommendReceipts_exportRecommendActiveScoreReceipts() {
    return super.get('recommendReceipts/exportRecommendActiveScoreReceipts', ...arguments);
  }

  /**
   * 推荐现金单据列表
   */

  recommendReceipts_getRecommendReceiptsList() {
    return super.post('recommendReceipts/getRecommendReceiptsList', ...arguments, {});
  }

  /**
   * 现金单据导出
   */

  recommendReceipts_exportRecommendMoneyReceipts() {
    return super.get('recommendReceipts/exportRecommendMoneyReceipts', ...arguments);
  }

  /**
   * 线下支付
   * @param {integer} recommendTaskUserId -  recommendTaskUserId
   * @param {string} reason -  reason
   */

  recommendReceipts_offlinePay() {
    return super.post('recommendReceipts/offlinePay', ...arguments);
  }
  /**
   * 积分批量支付
   * @param {Array} recommendTaskUserIds -  recommendTaskUserIds
   */

  recommendReceipts_activeScoreBatchPay() {
    return super.post('recommendReceipts/activeScoreBatchPay', ...arguments, {});
  }
}
