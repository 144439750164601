import Http from '../api/http/';

export default class AppContentManage extends Http {
  constructor() {
    super();
  }
  /**
   * 协议列表
   * @param {string} title -  标题
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */

  appContentMange_agreementList() {
    return super.post('appContentMange/agreementList', ...arguments);
  }

  /**
   * 内容详情
   * @param {integer} id -  id
   */

  appContentMange_detail() {
    return super.post('appContentMange/detail', ...arguments);
  }

  /**
   * 规则列表
   * @param {string} title -  标题
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */

  appContentMange_ruleList() {
    return super.post('appContentMange/ruleList', ...arguments);
  }

  /**
   * 内容修改
   * @param {integer} id -  id
   * @param {string} content -  内容
   */

  appContentMange_update() {
    return super.post('appContentMange/update', ...arguments);
  }
  /**
   * 内容修改
   * @param {string} userId -  userId
   * @param {integer} contentType -  6-承诺书 7-自由职业者服务协议
   */

  appContentMange_getAgreementSignByUserId() {
    return super.post('appContentMange/getAgreementSignByUserId', ...arguments);
  }
}
