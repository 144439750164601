import Http from '../api/http';
export default class CompanyPayment extends Http {
  constructor() {
    super();
  }
  // 增加企业虚拟账号配置
  companyPaymentSubjectRelation_addCompanyPaymentSubjectRelation() {
    return super.post('companyPaymentSubjectRelation/addCompanyPaymentSubjectRelation', ...arguments);
  }
  // 获取企业银行虚拟号配置列表
  companyPaymentSubjectRelation_getRelationVOList() {
    return super.post('companyPaymentSubjectRelation/getRelationVOList', ...arguments);
  }
  // 根据主账号获取虚拟账号
  companyPaymentSubjectRelation_getVirtualSubaccountBySubjectId() {
    return super.post('companyPaymentSubjectRelation/getVirtualSubaccountBySubjectId', ...arguments);
  }
  // 虚拟账号同步到psc
  companyPaymentSubjectRelation_transferVirtualSubaccountToPsc() {
    return super.post('companyPaymentSubjectRelation/transferVirtualSubaccountToPsc', ...arguments);
  }
  // 修改企业虚拟账号配置
  companyPaymentSubjectRelation_updateCompanyPaymentSubjectRelation() {
    return super.post('companyPaymentSubjectRelation/updateCompanyPaymentSubjectRelation', ...arguments);
  }
  // 获取主账号列表
  companyPaymentSubjectRelation_getHaveVirtualPaymentSubjectList() {
    return super.post('companyPaymentSubjectRelation/getHaveVirtualPaymentSubjectList', ...arguments);
  }
  // 批量虚拟账号同步到psc
  companyPaymentSubjectRelation_batchTransferVirtualSubaccountToPsc() {
    return super.post('companyPaymentSubjectRelation/batchTransferVirtualSubaccountToPsc', ...arguments);
  }
}
