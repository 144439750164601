import timeout from './timeout';
import input from './input';
import inputMax from './inputMax';
import role from './role';
import lineSplit from './lineSplit';
import watchScroll from './watchScroll';
import paginationResize from './paginationResize';
import watchScrollFix from './watchScrollFix';
import inputLimit from './inputLimit';

export default {
  timeout,
  input,
  inputMax,
  role,
  lineSplit,
  watchScroll,
  paginationResize,
  watchScrollFix,
  inputLimit,
};
