import Http from '../api/http/';
export default class Analysis extends Http {
  constructor() {
    super();
  }

  //报告数据列表
  getReportDataAnalysis() {
    return super.post('getReportDataAnalysis', ...arguments);
  }
  //已经分析报告删除
  deleteAnalyzedResultById() {
    return super.post('deleteAnalyzedResultById', ...arguments);
  }
  //报告数据分析列表 分析详情
  getReportDataAnalysisDetails() {
    return super.post('getReportDataAnalysisDetails', ...arguments);
  }
}
