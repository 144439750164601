import Http from '../api/http/';

export default class SystemHelp extends Http {
  constructor() {
    super();
  }
  //帮助列表
  // help_list() {
  //     return super.post('help/list', ...arguments)
  // }
  //新增标题
  // help_add() {
  //     return super.post('help/add', ...arguments)
  // }
  //移除标题
  // help_remove() {
  //     return super.post('help/remove', ...arguments)
  // }
  //添加内容
  // help_updateContent() {
  //     return super.post('help/updateContent', ...arguments)
  // }
  // //修改title
  // help_updateTitle() {
  //     return super.post('help/updateTitle', ...arguments)
  // }
  // //上移 下移
  // help_sort() {
  //     return super.post('help/sort', ...arguments)
  // }
}
