import Http from '../api/http';
export default class AppNotic extends Http {
  constructor() {
    super();
  }
  //APP公告列表
  app_notice_noticeList2P() {
    return super.post('app_notice/noticeList2P', ...arguments);
  }
  //APP公告管理新增
  app_notice_add() {
    return super.post('app_notice/add', ...arguments, {});
  }
  //APP公告管理修改
  app_notice_update() {
    return super.post('app_notice/update', ...arguments, {});
  }
  //APP公告管理删除
  app_notice_delete() {
    return super.post('app_notice/delete', ...arguments);
  }
  //APP公告上下线
  app_notice_onOffline() {
    return super.post('app_notice/onOffline', ...arguments);
  }
  //APP公告详情
  app_notice_detail() {
    return super.post('app_notice/detail', ...arguments);
  }
  //评论列表
  app_notice_commentList() {
    return super.post('app_notice/commentList', ...arguments);
  }
  //回复评论
  app_notice_commentReply() {
    return super.post('app_notice/commentReply', ...arguments, {});
  }
  //删除回复
  app_notice_deleteNoticeComment() {
    return super.post('app_notice/deleteNoticeComment', ...arguments);
  }
  /**
   * 添加app公告预览
   * @param {Array} previewDTO -  previewDTO
   */
  app_notice_addPreview() {
    return super.post('app_notice/addPreview', ...arguments);
  }
  /**
   * 编辑app公告预览信息
   * @param {Array} previewDTO -  previewDTO
   */
  app_notice_editPreview() {
    return super.post('app_notice/editPreview', ...arguments);
  }
  /**
   * APP官网公告排序
   * @param {array} sortIdList -  需要排序的ids 从小到大
   */

  app_notice_sort() {
    return super.post('app_notice/sort', ...arguments);
  }
}
