<template>
  <el-tooltip effect="light" :content="content" placement="top" :disabled="isDisabled">
    <div class="cm-tooltip" @mouseover="mouseoverHandle">
      <span ref="contentRef">{{ content }}</span>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    content: [String, Number],
  },
  data() {
    return {
      isDisabled: false,
    };
  },
  methods: {
    mouseoverHandle() {
      const parentWidth = this.$refs.contentRef.parentNode.offsetWidth;
      const width = this.$refs.contentRef.offsetWidth;
      this.isDisabled = width <= parentWidth;
    },
  },
};
</script>
