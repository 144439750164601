/**
 * @description  异步修改state，vue中使用 this.$store.dispatch('name').then(() => {})调用
 */
import name from './mutationsName';

export default {
  setUserInfoAsyn({ commit }, userInfo) {
    return new Promise(resolve => {
      commit(name.SET_USER_INFO, userInfo);
      resolve();
    });
  },
  setTotalAnalysisNavmenu({ commit }, nav) {
    commit(name.SET_TOTAL_ANALYSIS_NAVMENU, nav);
  },
  setParams({ commit }, params) {
    commit(name.SET_PARAMS, params);
  },
  setAnalysisValid({ commit }, valid) {
    commit(name.SET_ANALYSIS_VALID, valid);
  },
};
