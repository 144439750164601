import Http from '../api/http/';
export default class BRenewRecord extends Http {
  constructor() {
    super();
  }
  /***********b端更新记录管理***************/
  /**
   * 添加更新记录
   * @param {string} title -  标题
   * @param {string} fileUrl -  文档地址 多个以英文分号隔开
   */
  bRenewRecord_add() {
    return super.post('bRenewRecord/add', ...arguments);
  }

  /**
   * 更新记录详情
   * @param {integer} id -  id
   */
  bRenewRecord_detail() {
    return super.post('bRenewRecord/detail', ...arguments);
  }

  /**
   * 更新记录列表
   * @param {string} title -  标题
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */
  bRenewRecord_list() {
    return super.post('bRenewRecord/list', ...arguments);
  }

  /**
   * 删除更新记录
   * @param {integer} id -  id
   */
  bRenewRecord_remove() {
    return super.post('bRenewRecord/remove', ...arguments);
  }

  /**
   * 修改更新记录
   * @param {integer} id -  id
   * @param {string} title -  标题
   * @param {string} fileUrl -  文档地址
   */
  bRenewRecord_update() {
    return super.post('bRenewRecord/update', ...arguments);
  }
}
