import Http from '../api/http/';
export default class Receipts extends Http {
  constructor() {
    super();
  }
  /***********报告-单据生成***************/
  /**
   * 生成支付单据
   * @param {string} reportIdList -  报告id
   * @param {string} name -  单据名称
   * @param {integer} userCount -  涉及用户数量
   * @param {integer} reportCount -  涉及报告数量
   * @param {number} sumMoney -  总金额
   * @param {string} cuid -  undefined
   */

  receipts_addReceipts() {
    return super.post('receipts/addReceipts', ...arguments);
  }

  /**
   * 单据导出
   * @param {string} reportIdList -  报告id
   */
  receipts_exportReceipts() {
    return super.get('receipts/exportReceipts', ...arguments);
  }
  /**
   * 单据支付列表
   * @param {string} reportIdList -  报告id
   */

  receipts_getReceipts() {
    return super.post('receipts/getReceipts', ...arguments);
  }

  /**
   * 单据生成列表
   * @param {integer} taskSource -  单据任务来源 1p端 2b端
   * @param {string} companyName -  企业名称
   * @param {string} storeName -  门店名称
   * @param {string} storeAreaName -  门店区域
   * @param {integer} reportSendStatus -  下发状态0未发送 1已发送
   * @param {string} brandId -  品牌id
   * @param {string} brandName -  品牌名字
   * @param {integer} receiptsType -  单据生成状态（0-未生成，1-已生成）
   * @param {integer} payStatus -  支付状态 0-未支付，1-已支付，2-支付失败
   * @param {string} nickName -  访客名称
   * @param {string} reportName -  报告名称
   * @param {integer} markDelete -  删除状态
   * @param {integer} permissionRange -  用户的数据范围 全部(不要传值) 1我负责的企业 2我负责的品牌
   * @param {integer} bankType -  银行类型 0国内银行 1国外银行
   * @param {string} userId -  undefined
   * @param {array} brandIds -  undefined
   * @param {ref} storeList -  数据权限门店id集合，后端赋值，前端不用传参
   * @param {ref} areaList -  数据权限区域id集合，后端赋值，前端不用传参
   * @param {string} companyId -  企业id
   * @param {string} operateUserId -  操作用户id
   * @param {integer} operateUserType -  用户类型(1-P端，2-B端)
   * @param {integer} downloadStatus -  下载状态(0-未下载，1-已下载)
   * @param {string} checkStartTime -  检查开始时间
   * @param {string} checkEndTime -  检查结束时间
   * @param {ref} payStatusList -  状态 0未生成、1未生成（已驳回）、2未支付、3支付失败、4线上支付、5线下支付
   * @param {ref} payWayList -  收款方式 0国外银行、1国内银行、2支付宝
   * @param {string} payTimeStart -  支付开始时间 yyyy-MM-dd
   * @param {string} payTimeEnd -  支付结束时间 yyyy-MM-dd
   * @param {integer} isExport -  undefined
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */

  receipts_getReportReceiptsList() {
    return super.post('receipts/getReportReceiptsList', ...arguments);
  }

  // 撤回支付单据
  receipts_recallReceipts() {
    return super.post('receipts/recallReceipts', ...arguments);
  }
  /**
   * 审核兼职报告
   * @param {Array} req -  req
   */

  report_auditPartTimeReport() {
    return super.post('report/auditPartTimeReport', ...arguments, {});
  }
}
