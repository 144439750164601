<template>
  <div class="g-tab-ul">
    <div class="g-tab-li" @click="selected = item.value" :class="{ active: item.value == selected }" v-for="item in tabList" :key="item.value">{{ item.label }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    tabList: { type: Array, default: () => [] },
  },
  data() {
    return {};
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped lang="less">
.g-tab-ul {
  display: flex;
}
.g-tab-li {
  font-size: 16px;
  line-height: 16px;
  color: #333;
  height: 28px;
  cursor: pointer;
  position: relative;
  &.active {
    color: #905220;
    &::after {
      content: '';
      width: 18px;
      height: 4px;
      border-radius: 2px;
      background-color: #905220;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  &:hover {
    color: #905220;
  }
  & + .g-tab-li {
    margin-left: 20px;
  }
}
</style>
