<template>
  <el-dialog :title="title" v-model="value" :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false" v-bind="$attrs" v-on="$listeners">
    <div class="gl-qrcode-footer">
      <slot name="qrHead">
        <p>扫码查看</p>
      </slot>
      <canvas class="qrImg" ref="imageQR"></canvas>
      <slot name="qrfooter">
        <p>{{ urlParam }}</p>
      </slot>
    </div>
    <div slot="footer" class="gl-qrcode-footer">
      <el-button @click="onCancel" size="mini">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import QRCode from 'qrcode';

export default {
  props: {
    title: {
      type: String,
      default: '扫码分享',
    },
    value: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    urlParam: {
      get() {
        let paramString = [];
        Object.keys(this.params).forEach(item => {
          paramString.push(`${item}=${this.params[item]}`);
        });
        return paramString.length > 0 ? `${this.url}?${paramString.join('&')}` : this.url;
      },
    },
    dialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    createCode() {
      this.$nextTick(() => {
        if (/^((https|http):\/\/)+[^\s]*/i.test(this.url)) {
          let canvasQR = this.$refs.imageQR;
          QRCode.toCanvas(canvasQR, this.urlParam, function (error) {
            if (error) {
              console.error('二维码生成失败：', error);
            }
          });
        } else {
          this.dialogVisible = false;
          this.api.toast('地址不对，请检查后重试', 'error');
        }
      });
    },
    onCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style></style>
