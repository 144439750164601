import Http from '../api/http/';
export default class exampleManage extends Http {
  constructor() {
    super();
  }
  dataExamine_getListByPage() {
    return super.post('dataExamine/getListByPage', ...arguments);
  }
  /**
   * @description 获取考核点管理分页数据
   * @param
   */
  dataExamine_delete() {
    return super.post('dataExamine/delete', ...arguments);
  }
  /**
   * @description 删除考核点
   * @param 删除的id
   */
  dataExamine_add() {
    return super.post('dataExamine/add', ...arguments);
  }
  /**
   * @description 添加考核点
   * @param
   */
  dataExamine_update() {
    return super.post('dataExamine/update', ...arguments);
  }
  /**
   * @description 编辑考核点
   * @param
   */
  export_examine() {
    return super.get('dataExamine/export', ...arguments);
  }
}
