import Http from '../api/http/';
export default class LocationCalibration extends Http {
  constructor() {
    super();
  }

  /**
   * 问题反馈列表
   * @param {string} userName -  姓名
   * @param {integer} type -  反馈类型 0定位不准反馈 1帮助反馈
   * @param {integer} results -  处理结果 0处理中 1已处理
   * @param {string} feedbackTimeStart -  反馈时间起始时间
   * @param {string} feedbackTimeEnd -  反馈时间结束时间
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */
  locationCalibration_list() {
    return super.post('locationCalibration/list', ...arguments);
  }
  // 定位反馈位置详情
  locationCalibration_locationDetail() {
    return super.post('locationCalibration/locationDetail', ...arguments);
  }
  // 门店校准
  locationCalibration_locationCalibration() {
    return super.post('locationCalibration/locationCalibration', ...arguments);
  }
}
