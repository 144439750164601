import Vue from 'vue';
import Router from 'vue-router';

import Storage from '../api/lib/baseStorage';
import Store from '../store';

import Home from './home';
import Operation from './operation';
import TourStore from './tourStore';
import Enterprise from './enterprise';
import User from './user';
import Market from './market';
import Configure from './configure';
import ReportForm from './reportForm';
import View from './view';

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/home/home',
    },
    {
      path: '/login',
      name: 'login',
      meta: { title: '用户登录' },
      component: resolve => require(['@/views/login/login'], resolve),
    },
    ...View,
    {
      path: '/layout',
      name: 'layout',
      meta: { title: '框架' },
      component: resolve => require(['@/views/login/layout'], resolve),
      children: [...Home, ...Operation, ...TourStore, ...Enterprise, ...User, ...Market, ...Configure, ...ReportForm],
    },
  ],
});

router.beforeEach((to, from, next) => {
  //来自于哪个页面
  router['referrer'] = from;
  if (to.matched.length === 0) {
    // 判断路由是否匹配，不匹配则跳转首页
    next({ path: '/' });
    return;
  }
  let storage = new Storage();
  // 微信回调强制刷新页面
  if (to.query.code && !storage.localGetItem('weiXin')) {
    storage.localSetItem('weiXin', to.query.code);
    window.location.reload();
    return;
  }
  document.title = to.meta.viewTitle ? to.meta.viewTitle : '嗨探运营平台';
  if (to.meta && to.meta.view) {
    // 预览页面
    next();
    return;
  }
  // 记录活动菜单
  let path = to.name.split('_');
  Store.commit('SET_HEAD_ACTION', path[0] ? path[0] : '');
  Store.commit('SET_LEFT_ACTION', path[1] ? path[1] : '');
  next();
});

router.onError(error => {
  const pattern = /Loading chunk chunk-([0-9a-zA-Z])+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    //如果出现载入失败，则进行版本校验，然后给出提示
    (async () => {
      location.reload();
    })();
  }
});

export default router;
