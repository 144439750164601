<template>
  <div>
    <div :ref="id" :wordNumber="wordNumber"></div>
    <div class="editor-word-number" v-if="isCount">
      <span>字数统计：</span>
      <span>{{ count }} / {{ wordNumber }}</span>
    </div>
  </div>
</template>

<script>
import E from 'wangeditor';

export default {
  props: {
    value: String,
    id: { type: String, default: 'wangEditorEl' },
    isCount: { type: Boolean, default: true }, // 是否统计字数
    wordNumber: { type: Number, default: 500 }, // 字数限制
    height: { type: Number, default: 200 },
    disabled: { typeof: Boolean, default: false }, // 是否禁止输入
    placeholder: { type: String, default: '请输入' },
    isVideo: { typeof: Boolean, default: false }, // 是否支持上传视频
  },
  data() {
    return {
      count: 0,
      editor: {},
    };
  },
  mounted() {
    let editor = new E(this.$refs[this.id]);
    this.editor = editor;
    editor.config.onchange = html => {
      // 监听值得变化
      let count = this.totalCount();
      if (this.isCount && this.wordNumber < count) {
        editor.txt.html(this.value);
        return;
      }
      this.count = count;
      this.$emit('input', count ? html : '');
    };
    editor.config.zIndex = 1000;
    editor.config.placeholder = this.placeholder;
    editor.config.focus = false;
    editor.config.height = this.height;
    editor.config.menus = ['head', 'bold', 'fontSize', 'fontName', 'italic', 'underline', 'strikeThrough', 'foreColor', 'backColor', 'link', 'list', 'justify'];
    // 自定义字体颜色
    editor.config.colors = ['#D07323', '#F9431E', '#00B8A6', '#4A84FF', '#FDB525', '#333333', '#666666', '#999999', '#BBBBBB', '#FFFFFF'];
    // 自定义上传图片
    editor.config.menus.push('image'); // 插入图片
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024; // 限制上传图片大小
    editor.config.customUploadImg = (files, insert) => {
      let file = files[0];
      this.api.ossService.uploadImage(file, this.$store.getters.getUserInfo.uid).then(res => {
        insert(res.url);
      });
    };
    // 自定义上传视频
    if (this.isVideo) {
      editor.config.menus.push('video'); // 插入视频
      editor.config.uploadVideoMaxSize = 2 * 1024 * 1024 * 1024; // 2G
      editor.config.uploadVideoAccept = ['mp4', 'MP4', 'mov', 'MOV', 'wmv', 'WMV']; // 限制上传视频格式
      editor.config.customUploadVideo = (files, insertVideoFn) => {
        // files 是 input 中选中的文件列表
        let file = files[0];
        this.api.ossService.uploadImage(file, this.$store.getters.getUserInfo.uid).then(res => {
          // 上传视频，返回结果，将视频地址插入到编辑器中
          insertVideoFn(res.url);
        });
      };
    }
    editor.config.menus.push(...['table', 'undo', 'redo']); // 插入表格,撤销,重做
    editor.config.pasteFilterStyle = false;
    editor.config.showFullScreen = true;
    editor.create();
    editor.txt.html(this.value);
    this.count = this.totalCount();
    // 设置禁止输入
    if (this.disabled) {
      this.editor.disable();
    }
    // edge浏览器bug,windows输入法不触发onchange
    // 决解第一次鼠标全删除不触发onchange事件
    this.editor.$textElem.on('compositionend', () => {
      // 输入法结束输入
      this.editor.config.onchange(this.editor.txt.html());
      this.editor.compositionend = true;
    });
  },
  methods: {
    readyHtml() {
      this.editor.txt.html(this.value);
      this.count = this.totalCount();
    },
    totalCount() {
      let text = this.editor.txt.text();
      text = text.replace(/\&nbsp\;/gi, ' ');
      return text.length;
    },
    getText() {
      let text = this.editor.txt.text();
      text = text.replace(/\&nbsp\;/gi, ' ');
      return text;
    },
    setEditable(flag = 'true') {
      if (flag) {
        this.editor.enable();
      } else {
        this.editor.disable();
      }
    },
  },
  beforeDestroy() {
    this.editor.destroy();
    this.editor = null;
  },
};
</script>
<style lang="less" scoped>
/deep/.w-e-droplist ul.w-e-block li.w-e-item:last-child {
  background: #ebeef5;
}
/deep/ .w-e-text {
  padding: 8px 10px;
  p {
    margin: 0px;
    font-size: 14px !important;
  }
}
.editor-word-number {
  border-bottom: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  color: #666;
  line-height: 1;
  font-size: 14px;
}
</style>
