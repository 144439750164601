import Http from '../api/http/';
export default class recommendTaskDetail extends Http {
  constructor() {
    super();
  }

  /***********推荐任务详情***************/
  /**
   * 推荐任务详情分页列表(查看进度里调用-选择推荐任务)
   * @param {integer} recommendTaskId -  推荐任务主键id
   * @param {string} recommendTaskName -  任务名称
   * @param {integer} taskStatus -  任务状态 0未发布 1未开始 2进行中 3已结束
   * @param {string} cityIds -  省市区 前端传输
   * @param {integer} cityId -  城市id 后端处理
   * @param {integer} countyId -  行政区id 后端处理
   * @param {integer} page -  当前页
   * @param {integer} rows -  数量
   */

  recommendTaskDetail_getRecommendTaskByTaskIdGroupList() {
    return super.post('recommendTaskDetail/getRecommendTaskByTaskIdGroupList', ...arguments);
  }

  /**
   * 推荐任务指定用户
   * @param {integer} recommendTaskId -  推荐任务主键id
   * @param {integer} cityId -  城市Id
   * @param {integer} countyId -  区县Id
   * @param {string} recommendUserId -  推荐人id
   * @param {string} refRecommendName -  被推荐人姓名
   * @param {string} refRecommendTel -  被推荐人手机号
   */

  recommendTaskDetail_recommendTaskAssignUser() {
    return super.post('recommendTaskDetail/recommendTaskAssignUser', ...arguments);
  }
}
