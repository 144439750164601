import Http from '../api/http/';
export default class Appeal extends Http {
  constructor() {
    super();
  }

  //获取报告申诉列表
  appeal_appealList() {
    return super.post('appeal/appealList', ...arguments);
  }
  //下载申诉报告
  appeal_export() {
    return super.get('appeal/export', ...arguments);
  }

  //获取申诉详情
  appeal_detail() {
    return super.post('appeal/detail', ...arguments);
  }
  //审核申诉
  appeal_examine() {
    return super.post('appeal/examine', ...arguments, {});
  }
}
