<template>
  <el-drawer
    :visible.sync="drawer"
    :direction="direction"
    :size="size"
    :wrapperClosable="wrapperClosable"
    :append-to-body="appendToBody"
    v-bind="$attrs"
    v-on="$listeners"
    :style="{ width: direction === 'ttb' ? width : '', margin: direction === 'ttb' ? '0 auto' : '' }"
  >
    <div slot="title">
      <slot name="title">{{ title }}</slot>
    </div>
    <div class="gl-drawer-body" :style="{ 'padding-bottom': showBottom ? '86px' : '20px' }">
      <slot></slot>
    </div>
    <div class="gl-btn-bottom" v-if="showBottom">
      <slot name="button">
        <el-button type="text" class="gl-btn-plain" @click="drawer = false">关闭</el-button>
      </slot>
    </div>
  </el-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: 'rtl',
    },
    size: {
      type: String,
      default: '496px',
    },
    wrapperClosable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '80%',
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    showBottom: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    drawer: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>
