import Http from '../api/http/';

export default class DataAnalysis extends Http {
  constructor() {
    super();
  }

  /***********数据分析***************/

  /**
   * @description 获取数据分析列表
   * @pram
   * areaName (string, optional): 区域名称 ,
   * brandId (string, optional): 品牌id ,
   * endTime (string, optional): 结束时间 ,
   * page (integer, optional): 页数 ,
   * rows (integer, optional): 行数 ,
   * startTime (string, optional): 开始时间 ,
   * storeName (string, optional): 门店名称 ,
   * taskName (string, optional): 任务名称 ,
   * type (integer, optional): 分析类型 0-旧线下报告 1-新在线报告
   */
  dataAnalysis_getDataAnalysis() {
    return super.post('getDataAnalysis', ...arguments);
  }

  /***
   * @description 获取数据详情列表
   * @param
   * areaId (string, optional): 区域id ,
   * brandId (string, optional): 品牌id ,
   * page (integer, optional): 页数 ,
   * rows (integer, optional): 行数 ,
   * storeId (string, optional): 门店id ,
   * taskId (string, optional): 任务id ,
   * templateId (string, optional): 模板id
   */
  dataAnalysis_getDataDetails() {
    return super.post('getDataDetails', ...arguments);
  }

  /**
   * @description 获取区域和门店
   * @param
   * areaName (string, optional): 区域名称 ,
   * brandId (string, optional): 品牌id ,
   * reportId (string, optional): 报告id
   */
  dataAnalysis_getAreaStore() {
    return super.post('getAreaStore', ...arguments);
  }

  /**
   * @description 获取区域的门店
   * @param
   * areaName (string, optional): 区域名称 ,
   * brandId (string, optional): 品牌id ,
   * reportId (string, optional): 报告id
   */
  dataAnalysis_getStoreByArea() {
    return super.post('getStoreByArea', ...arguments);
  }

  /**
   * @description 已分析报告列表
   * @param
   * brandId (string, optional): 品牌Id ,
   * endTime (string, optional): 结束时间 ,
   * name (string, optional): 分析报告名称 ,
   * page (integer, optional): 第几页 ,
   * rows (integer, optional): 每页数据条数 ,
   * startTime (string, optional): 开始时间
   */
  dataAnalysis_analyzedResultList() {
    return super.post('analyzedResultList', ...arguments);
  }

  /***
   * @description 删除已分析报告
   * @param
   * id (String)
   */
  dataAnalysis_deleteAnalyzedResultById() {
    return super.post('deleteAnalyzedResultById', ...arguments);
  }
  /***
   * @description 已分析报告详情
   * @param
   * id (String)
   */
  dataAnalysis_getAnalyzedResultById() {
    return super.post('getAnalyzedResultById', ...arguments);
  }
  //汇总分析
  dataAnalysis_summarizeAnalysis() {
    return super.post('summarizeAnalysis', ...arguments);
  }
  //汇总分析整体分析门店评分数据列表
  dataAnalysis_getSummarizStoreScore() {
    return super.post('getSummarizStoreScore', ...arguments);
  }
  //汇总分析整体分析门店评级数据列表
  dataAnalysis_getSummarizStoreGrade() {
    return super.post('getSummarizStoreGrade', ...arguments);
  }
  //汇总分析整体分析数据列表
  dataAnalysis_getDataSummariz() {
    return super.post('getDataSummariz', ...arguments);
  }

  //得分详情
  dataAnalysis_singleScoreDetailList() {
    return super.post('singleScoreDetailList', ...arguments);
  }

  //指标详情
  dataAnalysis_singleDataIndexDetailList() {
    return super.post('singleDataIndexDetailList', ...arguments);
  }
  //汇总分析 判断数据格式
  dataAnalysis_summarizValidate() {
    return super.post('summarizValidate', ...arguments);
  }
  //汇总分析 保存
  dataAnalysis_saveAnalyzedResult() {
    return super.post('saveAnalyzedResult', ...arguments);
  }
  //得分详情导出
  dataAnalysis_singleScoreDetailExport() {
    return super.post('singleScoreDetailExport', ...arguments);
  }
  //指标详情导出
  dataAnalysis_singleDataIndexDetailExport() {
    return super.post('singleDataIndexDetailExport', ...arguments);
  }
  //数据分析整体导出
  dataAnalysis_dataSummarizExport() {
    return super.post('dataSummarizExport', ...arguments);
  }

  //导出汇总分析整体分析门店评级图表列表
  dataAnalysis_exportSummarizStoreGrade() {
    return super.post('exportSummarizStoreGrade', ...arguments);
  }

  //导出汇总分析整体分析门店评分图表列表
  dataAnalysis_exportSummarizStoreScore() {
    return super.post('exportSummarizStoreScore', ...arguments);
  }
  // 导出汇总分析整体分析品牌区域评分图表列表
  dataAnalysis_exportSummarizDynamicScore() {
    return super.post('exportSummarizDynamicScore', ...arguments);
  }
  // 导出汇总分析整体分析品牌区域评级图表列表
  dataAnalysis_exportSummarizDynamicGrade() {
    return super.post('exportSummarizDynamicGrade', ...arguments);
  }

  // 导出汇总分析整体分析门店评分图表列表与上月对比
  exportSummarizStoreScoreByCompare() {
    return super.post('exportSummarizStoreScoreByCompare', ...arguments);
  }
  // 导出汇总分析整体分析门店评级图表列表与上月对比（评级）
  exportSummarizStoreGradeByCompare() {
    return super.post('exportSummarizStoreGradeByCompare', ...arguments);
  }
  // 汇总分析整体分析品牌评分图表列表与上月对比
  exportSummarizBrandScoreByCompare() {
    return super.post('exportSummarizBrandScoreByCompare', ...arguments);
  }
  // 导出汇总分析整体分析品牌评级图表列表与上月对比
  exportSummarizBrandGradeByCompare() {
    return super.post('exportSummarizBrandGradeByCompare', ...arguments);
  }
  // 导出汇总分析整体分析区域评级图表列表与上月对比
  exportSummarizAreaGradeByCompare() {
    return super.post('exportSummarizAreaGradeByCompare', ...arguments);
  }
  // 汇总分析整体分析区域评分图表列表与上月对比
  exportSummarizAreaScoreByCompare() {
    return super.post('exportSummarizAreaScoreByCompare', ...arguments);
  }

  // 汇总分析整体分析区域评级图表列表
  dataAnalysis_getSummarizAreaGrade() {
    return super.post('getSummarizAreaGrade', ...arguments);
  }
  // 汇总分析整体分析区域评分图表列表
  dataAnalysis_getSummarizAreaScore() {
    return super.post('getSummarizAreaScore', ...arguments);
  }
  // 汇总分析整体分析品牌评级图表列表
  dataAnalysis_getSummarizBrandGrade() {
    return super.post('getSummarizBrandGrade', ...arguments);
  }
  // 汇总分析整体分析品牌评分图表列表
  dataAnalysis_getSummarizBrandScore() {
    return super.post('getSummarizBrandScore', ...arguments);
  }
  // 单项分析考核维度
  getDimension() {
    return super.post('getDimension', ...arguments);
  }
  //  单项分析考核维度去重
  getDistinctDimension() {
    return super.post('getDistinctDimension', ...arguments);
  }
  // 单项分析得分分析
  singleStoreScore() {
    return super.post('singleStoreScore', ...arguments);
  }
  // 单项分析得分分析上月对比
  singleStoreScoreByCompare() {
    return super.post('singleStoreScoreByCompare', ...arguments);
  }
  // 单项分析按时间维度得分分析
  singleTimeScore() {
    return super.post('singleTimeScore', ...arguments);
  }
  // 单项分析按时间维度得分分析与上月对比
  singleTimeScoreByCompare() {
    return super.post('singleTimeScoreByCompare', ...arguments);
  }
  //数据分析门店下拉
  getStoreList() {
    return super.post('getStoreList', ...arguments);
  }
  //单项分析门店得分详情导出
  exportSingleStoreScore() {
    return super.post('exportSingleStoreScore', ...arguments);
  }
  // 上月对比单项分析门店得分详情导出
  exportSingleStoreScoreByCompare() {
    return super.post('exportSingleStoreScoreByCompare', ...arguments);
  }
  // 单项分析时间得分详情导出
  exportSingleTimeScore() {
    return super.post('exportSingleTimeScore', ...arguments);
  }
  // 上月对比单项分析时间得分详情导出
  exportSingleTimeScoreByCompare() {
    return super.post('exportSingleTimeScoreByCompare', ...arguments);
  }
  // 汇总分析整体分析门店评分图表列表与上月对比
  getSummarizStoreScoreByCompare() {
    return super.post('getSummarizStoreScoreByCompare', ...arguments);
  }
  // 汇总分析整体分析门店评级图表列表与上月对比（评级）
  getSummarizStoreGradeByCompare() {
    return super.post('getSummarizStoreGradeByCompare', ...arguments);
  }
  // 汇总分析整体分析品牌评分图表列表与上月对比
  getSummarizBrandScoreByCompare() {
    return super.post('getSummarizBrandScoreByCompare', ...arguments);
  }
  // 汇总分析整体分析品牌评分图表列表与上月对比
  getSummarizAreaScoreByCompare() {
    return super.post('getSummarizAreaScoreByCompare', ...arguments);
  }
  // 获取单项分析按门店情感分析指标维度
  getSingleStoreEmotionDimension() {
    return super.post('getSingleStoreEmotionDimension', ...arguments);
  }
  // 单项分析按门店情感分析
  singleStoreEmotionAnalysis() {
    return super.post('singleStoreEmotionAnalysis', ...arguments);
  }
  // 单项分析情感分析
  singleEmotionAnalysis() {
    return super.post('singleEmotionAnalysis', ...arguments);
  }
  // 单项分析情感分析导出
  exportSingleEmotionAnalysis() {
    return super.post('exportSingleEmotionAnalysis', ...arguments);
  }
  // 单项分析按门店情感分析导出
  exportSingleStoreEmotionAnalysis() {
    return super.post('exportSingleStoreEmotionAnalysis', ...arguments);
  }
  //单项分析小项得分分析
  singleScore() {
    return super.post('singleScore', ...arguments);
  }
  //导出考核小项得分率详情
  exportSingleScore() {
    return super.post('exportSingleScore', ...arguments);
  }
  // 汇总分析整体分析品牌评分图表列表与上月对比
  getSummarizBrandGradeByCompare() {
    return super.post('getSummarizBrandGradeByCompare', ...arguments);
  }
  // 汇总分析整体分析区域评级图表列表与上月对比
  getSummarizAreaGradeByCompare() {
    return super.post('getSummarizAreaGradeByCompare', ...arguments);
  }
}
