import Http from '../api/http/';

export default class UserRisk extends Http {
  constructor() {
    super();
  }
  /**
   * 风险用户分页列表
   * @param {string} userId -  userId
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */

  userRisk_listByPage() {
    return super.post('userRisk/listByPage', ...arguments);
  }
}
