import Http from '../api/http/';

export default class Role extends Http {
  constructor() {
    super();
  }
  /***********权限管理***************/
  /**
   * 增加自定义角色/权限
   * @param {string} roleName -  角色名称
   * @param {string} desc -  角色描述
   * @param {string} menuList -  菜单列表
   */
  role_addUserDefinedRole() {
    return super.post('role/addUserDefinedRole', ...arguments);
  }

  /**
   * 获取角色列表
   * @param {string} name -  角色名称
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */
  role_getRolePage() {
    return super.post('role/getRolePage', ...arguments);
  }
  /**
   * 获取全部菜单
   */
  role_getAllMenuList() {
    return super.post('role/getAllMenuList', ...arguments);
  }

  /**
   * 获取角色详细信息
   * @param {string} id -  角色Id
   */
  role_getRoleDetail() {
    return super.post('role/getRoleDetail', ...arguments);
  }

  /**
   * 删除角色
   * @param {string} ids -  角色Id
   */
  role_updateRoleStatus() {
    return super.post('role/updateRoleStatus', ...arguments);
  }

  /**
   * 修改自定义角色/权限
   * @param {string} id -  角色Id
   * @param {string} roleName -  角色名称
   * @param {string} desc -  角色描述
   * @param {string} menuList -  菜单列表
   */
  role_updateUserDefinedRole() {
    return super.post('role/updateUserDefinedRole', ...arguments);
  }
  //获取角色列表
  role_getRoleList() {
    return super.post('role/getRoleList', ...arguments);
  }
}
