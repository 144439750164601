/**
 * @author LB
 * @description 用户路由
 */
export default [
  {
    path: '/user/visitorUser',
    name: 'user_visitorUser',
    meta: {
      title: '访客用户',
    },
    component: resolve => require(['@/views/user/visitorUser/visitorUser'], resolve),
  },
  {
    path: '/user/messageSend',
    name: 'user_messageSend',
    meta: {
      title: '消息发送',
    },
    component: resolve => require(['@/views/user/messageSend/messageSend'], resolve),
  },
  {
    path: '/user/privateExplorationAudit',
    name: 'user_privateExplorationAudit',
    meta: {
      title: '私探审核',
    },
    component: resolve => require(['@/views/user/privateExplorationAudit/privateExplorationAudit'], resolve),
  },
  {
    path: '/user/integralAudit',
    name: 'user_integralAudit',
    meta: {
      title: '积分审核',
    },
    component: resolve => require(['@/views/user/integralAudit/integralAudit'], resolve),
  },
  {
    path: '/user/blackListAudit',
    name: 'user_blackListAudit',
    meta: {
      title: '黑名单审核',
    },
    component: resolve => require(['@/views/user/blackListAudit/blackListAudit'], resolve),
  },
  {
    path: '/user/visitorCancelList',
    name: 'user_visitorCancelList',
    meta: {
      title: '访客注销名单',
    },
    component: resolve => require(['@/views/user/visitorCancelList/visitorCancelList'], resolve),
  },
  {
    path: '/user/authenticationFail',
    name: 'user_authenticationFail',
    meta: {
      title: '认证失败',
    },
    component: resolve => require(['@/views/user/authenticationFail/authenticationFail'], resolve),
  },
  {
    path: '/user/applyManualCertification',
    name: 'user_applyManualCertification',
    meta: {
      title: '申请人工认证',
    },
    component: resolve => require(['@/views/user/applyManualCertification/applyManualCertification'], resolve),
  },
  {
    path: '/user/overseasUserBind',
    name: 'user_overseasUserBind',
    meta: {
      title: '海外用户换绑',
    },
    component: resolve => require(['@/views/user/overseasUserBind/overseasUserBind'], resolve),
  },
  {
    path: '/user/loginDeviceList',
    name: 'user_loginDeviceList',
    meta: {
      title: '登录设备记录',
    },
    component: resolve => require(['@/views/user/loginDevice/loginDeviceList'], resolve),
  },
];
