<template>
  <div>
    <el-table :ref="name" :data="tb.data" v-loading="tb.loading" size="medium" class="gl-table" style="width: 100%" v-bind="$attrs" v-on="$listeners" tooltip-effect="light" border stripe>
      <slot name="index">
        <el-table-column label="序号" type="index" align="center" :index="index" width="60"></el-table-column>
      </slot>
      <slot></slot>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      :disabled="tb.loading"
      @current-change="handleCurrentChange"
      :current-page="tb.page"
      :page-sizes="pageSizes"
      :page-size="tb.size"
      :pager-count="5"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tb.total"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: 'glTable',
    },
    load: {
      type: Function,
    },
    firstLoad: {
      type: Boolean,
      default: true,
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 20, 30, 50, 100, 200],
    },
  },
  data() {
    return {
      tb: {
        condition: {},
        data: [],
        loading: false,
        page: 1,
        size: 10,
        total: 0,
      },
      dataAll: [],
      fieldSort: {},
    };
  },
  created() {
    if (this.firstLoad) this._loadData();
  },
  methods: {
    /*********外部可调用方法*************/
    //刷新当前数据
    refresh() {
      this._loadData();
    },
    //搜索
    search(condition) {
      this.tb.condition = condition;
      this.reload();
    },
    //重新加载数据
    reload() {
      this.tb.page = 1;
      this._loadData();
    },
    sortTable(obj) {
      this.fieldSort = JSON.parse(JSON.stringify(obj));
      this._sortTable();
      this._changePageData();
    },
    // 序号
    index(num) {
      return (this.tb.page - 1) * this.tb.size + num + 1;
    },
    // 状态
    getLoading() {
      return this.tb.loading;
    },
    /*********内部私有方法*************/
    handleSizeChange(val) {
      this.tb.size = val;
      this.tb.page = 1;
      this._changePageData();
    },
    handleCurrentChange(val) {
      this.tb.page = val;
      this._changePageData();
    },
    _loadData() {
      if (this.tb.loading) return;
      this.tb.loading = true;
      try {
        this.load(this.tb)
          .then(res => {
            if (res) {
              this.dataAll = res.data;
              this.tb.total = res.data.length;
              this._sortTable();
              this._changePageData();
            }
          })
          .finally(() => {
            this.tb.loading = false;
          });
      } catch (error) {
        this.api.toast(error, 'error');
        this.tb.loading = false;
      }
    },
    _changePageData() {
      // 切换分页加载数
      let maxTotal = this.tb.page * this.tb.size;
      this.tb.data = this.dataAll.slice((this.tb.page - 1) * this.tb.size, maxTotal > this.tb.total ? this.tb.total : maxTotal);
    },
    _sortTable() {
      if (this.fieldSort.key && this.fieldSort.val) {
        if (this.fieldSort.val === 1) {
          // 升序
          this.dataAll.sort((a, b) => {
            return a[this.fieldSort.key] - b[this.fieldSort.key];
          });
        } else {
          // 降序
          this.dataAll.sort((a, b) => {
            return b[this.fieldSort.key] - a[this.fieldSort.key];
          });
        }
      }
    },
  },
};
</script>
