export default [
  {
    path: '/configure/accountManage',
    name: 'configure_accountManage',
    meta: { title: '账户管理' },
    component: resolve => require(['@/views/configure/accountManage/accountManage'], resolve),
  },
  {
    path: '/configure/basicConfig',
    name: 'configure_basicConfig',
    meta: { title: '基本配置' },
    component: resolve => require(['@/views/configure/basicConfig/basicConfig'], resolve),
  },
  {
    path: '/configure/roleManage',
    name: 'configure_roleManage',
    meta: { title: '角色管理' },
    component: resolve => require(['@/views/configure/roleManage/roleManage'], resolve),
  },
  {
    path: '/configure/organizationManage',
    name: 'configure_organizationManage',
    meta: { title: '组织架构' },
    component: resolve => require(['@/views/configure/organizationManage/organizationManage'], resolve),
  },
  {
    path: '/configure/companySystemConfig',
    name: 'configure_companySystemConfig',
    meta: { title: '第三方配置' },
    component: resolve => require(['@/views/configure/companySystemConfig/companySystemConfig'], resolve),
  },
  {
    path: '/configure/templatePrice',
    name: 'configure_templatePrice',
    meta: { title: '模板定价' },
    component: resolve => require(['@/views/configure/templatePrice/templatePrice'], resolve),
  },
  {
    path: '/configure/labelConfig',
    name: 'configure_labelConfig',
    meta: { title: '标签配置' },
    component: resolve => require(['@/views/configure/labelConfig/labelConfig'], resolve),
  },
  {
    path: '/configure/subjectConfigure',
    name: 'configure_subjectConfigure',
    meta: { title: '公司主体配置' },
    component: resolve => require(['@/views/configure/subjectConfigure/subjectConfigure'], resolve),
  },
  {
    path: '/configure/customerServiceConfigure',
    name: 'configure_customerServiceConfigure',
    meta: { title: '客服配置' },
    component: resolve => require(['@/views/configure/customerServiceConfigure/customerServiceConfigure'], resolve),
  },
  {
    path: '/configure/appVersionConfigure',
    name: 'configure_appVersionConfigure',
    meta: { title: 'APP版本配置' },
    component: resolve => require(['@/views/configure/appVersionConfigure/appVersionConfigure'], resolve),
  },
  {
    path: '/configure/auditLanguageConfigure',
    name: 'configure_auditLanguageConfigure',
    meta: { title: '审核语配置' },
    component: resolve => require(['@/views/configure/auditLanguageConfigure/auditLanguageConfigure'], resolve),
  },
  {
    path: '/configure/businessDistrictConfigure',
    name: 'configure_businessDistrictConfigure',
    meta: { title: '商圈配置' },
    component: resolve => require(['@/views/configure/businessDistrictConfigure/businessDistrictConfigure'], resolve),
  },
  {
    path: '/configure/algorithmConfigure',
    name: 'configure_algorithmConfigure',
    meta: { title: '中签算法权重配置' },
    component: resolve => require(['@/views/configure/algorithmConfigure/algorithmConfigure'], resolve),
  },
];
