<template>
  <el-card class="gl-card">
    <div slot="header" class="gl-breadcrumb" v-if="isShow">
      <div class="gl-breadcrumb-item">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="(item, index) in data" :key="index">{{ item.title }}</el-breadcrumb-item>
        </el-breadcrumb>
        <slot name="breadcrumbItem"></slot>
      </div>
      <div>
        <slot name="breadcrumbBtn"></slot>
        <a href="javascript:;" v-show="showBack" class="goBack" @click="backClick">
          <i class="el-icon-back"></i>
          &nbsp;返回
        </a>
      </div>
    </div>
    <slot></slot>
  </el-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    backClick() {
      if (this._events.back) {
        this.$emit('back');
      } else {
        history.back();
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  font-size: 20px;
  font-weight: 500;
  color: #535353;
}
/deep/ .el-card__body {
  padding: 10px;
}
</style>
