import Http from '../api/http/';

export default class WeiXin extends Http {
  /***********微信管理***************/
  /**
   * 微信授权
   * @param {string} code -  code
   * @param {Array} model -  model
   */
  weixin_bindWeixinLogin() {
    return super.post('weixin/weixin/bindWeixinLogin', ...arguments);
  }

  /**
   * 微信网页授权
   * @param {string} code -  code
   * @param {Array} model -  model
   */
  weixin_bindWeixinWebLogin() {
    return super.post('weixin/weixin/bindWeixinWebLogin', ...arguments);
  }
}
