import Http from '../api/http/';

export default class userPartTimeFee extends Http {
  constructor() {
    super();
  }
  /***********兼职人员费用管理***************/

  /**
   * 导出费用
   * @returns
   */
  userPartTimeFee_exportFee() {
    return super.post('userPartTimeFee/exportFee', ...arguments, {});
  }
  /**
   * 导出费用明细
   * @returns
   */
  userPartTimeFee_exportFeeDetail() {
    return super.get('userPartTimeFee/exportFeeDetail', ...arguments);
  }
  /**
   * 费用列表
   * @param {string} yearMonth -  年月
   * @param {string} deptName -  部门名称
   * @param {string} inchargeName -  负责人名称
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */
  userPartTimeFee_getFeeList() {
    return super.post('userPartTimeFee/getFeeList', ...arguments, {});
  }
  /**
   * 费用明细：合计
   * @returns
   */
  userPartTimeFee_feeDetail() {
    return super.post('userPartTimeFee/feeDetail', ...arguments);
  }
  /**
   * 审核费用列表
   * @returns
   */
  userPartTimeFee_getFeeAuditList() {
    return super.post('userPartTimeFee/getFeeAuditList', ...arguments);
  }
  /**
   * 费用奖惩列表
   * @returns
   */
  userPartTimeFee_getRewardPunishmentList() {
    return super.post('userPartTimeFee/getRewardPunishmentList', ...arguments);
  }
}
