import Http from '../api/http/';
export default class QuestionAnalysis extends Http {
  constructor() {
    super();
  }

  // 问卷式统计导出
  questionAnalysis_export() {
    return super.post('questionAnalysis/export', ...arguments);
  }
  // 问卷式数据分析--->汇总分析
  questionAnalysis_questionSummarizeAnalysis() {
    return super.post('questionAnalysis/questionSummarizeAnalysis', ...arguments);
  }
}
