import Http from '../api/http/';

export default class CompanyArea extends Http {
  constructor() {
    super();
  }
  /***********企业区域***************/
  /**
   * 删除
   * @param {string} id -  id
   */
  companyArea_delete() {
    return super.post('companyArea/delete', ...arguments);
  }

  /**
   * 通过id查询
   * @param {string} id -  id
   */
  companyArea_getById() {
    return super.post('companyArea/getById', ...arguments);
  }

  /**
   * 查询集合（下拉）
   * @param {string} companyId -  companyId
   */
  companyArea_getList() {
    return super.post('companyArea/getList', ...arguments);
  }

  /**
   * 分页查询
   * @param {string} areaName -  areaName
   * @param {integer} rows -  rows
   * @param {integer} page -  page
   * @param {string} companyId -  companyId
   */
  companyArea_getListByPage() {
    return super.post('companyArea/getListByPage', ...arguments);
  }

  /**
   * 修改企业区域
   * @param {Array} companyAreaDto -  companyAreaDto
   */
  companyArea_update() {
    return super.post('companyArea/update', ...arguments);
  }

  /**
   * 批量导入区域
   * @param file
   * @param companyId
   */
  companyArea_importExcel() {
    return super.post('companyArea/importExcel', ...arguments);
  }
  /**
   * 区域树结构
   * @returns
   */
  companyArea_getCompanyAreaTreeVo() {
    return super.post('companyArea/getCompanyAreaTreeVo', ...arguments);
  }

  /**
   * 关联门店
   * @returns
   */
  companyArea_relateAreaAndStore() {
    return super.post('companyArea/relateAreaAndStore', ...arguments);
  }

  /**
   * 区域下拉
   * @returns
   */
  companyArea_getCompanyAreaList() {
    return super.post('companyArea/getCompanyAreaList', ...arguments);
  }
  // 导入修改区域模板下载
  companyArea_exportAreaExcel() {
    return super.get('companyArea/exportAreaExcel', ...arguments);
  }
}
