import Http from '../api/http/';

export default class userPartTime extends Http {
  constructor() {
    super();
  }
  /***********兼职人员管理***************/
  /**
   * 新增兼职用户
   * @param {Array} userPartTimeDTO -  userPartTimeDTO
   */

  userPartTime_addUserPartTime() {
    return super.post('userPartTime/addUserPartTime', ...arguments);
  }

  /**
   * 兼职用户详情
   * @param {integer} id -  id
   */

  userPartTime_getById() {
    return super.post('userPartTime/getById', ...arguments);
  }

  /**
   * 兼职用户分页列表
   * @param {Array} userPartTimeBO -  userPartTimeBO
   */

  userPartTime_getListByPage() {
    return super.post('userPartTime/getListByPage', ...arguments);
  }

  /**
   * 移除兼职用户
   * @param {integer} id -  id
   */

  userPartTime_removeUserPartTime() {
    return super.post('userPartTime/removeUserPartTime', ...arguments);
  }

  /**
   * 修改兼职用户
   * @param {Array} userPartTimeDTO -  userPartTimeDTO
   */

  userPartTime_updateUserPartTime() {
    return super.post('userPartTime/updateUserPartTime', ...arguments);
  }

  /**
   * 兼职用户列表 根据品牌
   * @param {string} brandId -  brandId
   */
  userPartTime_getListByBrandId() {
    return super.post('userPartTime/getListByBrandId', ...arguments);
  }

  /**
   * 复制签约地址
   */
  userPartTime_generateUrlBySignStatus() {
    return super.post('userPartTime/generateUrlBySignStatus', ...arguments);
  }

  /**
   * 发送兼职审核短信
   * @param {string} userId -  userId
   */
  userPartTime_sendSms() {
    return super.post('userPartTime/sendSms', ...arguments);
  }
}
