import Http from '../api/http/';

export default class TaskAppeal extends Http {
  constructor() {
    super();
  }
  taskAppeal_list() {
    return super.post('taskAppeal/list', ...arguments);
  }
  taskAppeal_detail() {
    return super.post('taskAppeal/detail', ...arguments);
  }

  taskAppeal_audit() {
    return super.post('taskAppeal/audit', ...arguments, {});
  }
}
