/**
 * 多重继承
 */
function classMixin(...mixins) {
  class Mix {
    constructor() {
      for (let mixin of mixins) {
        copyProperties(this, new mixin()); // 拷贝实例属性
      }
    }
  }
  for (let mixin of mixins) {
    copyProperties(Mix, mixin); // 拷贝静态属性
    copyProperties(Mix.prototype, mixin.prototype); // 拷贝原型属性
  }
  return Mix;
}
function copyProperties(target, source) {
  // Reflect.ownKeys 返回所有属性的key
  // Object.keys 返回属性key， 不包含不可枚举的
  for (const key of Reflect.ownKeys(source)) {
    if (key !== 'constructor' && key !== 'prototype' && key !== 'name') {
      // Object.getOwnPropertyDescriptor 返回指定对象上的一个自有属性对应得属性描述符
      // 自有属性是指直接赋予该对象得属性，不需要从原型链上查找的属性
      const desc = Object.getOwnPropertyDescriptor(source, key);
      Object.defineProperty(target, key, desc);
    }
  }
}

export { classMixin, copyProperties };
