import Http from '../api/http/';

export default class AppThemeConfig extends Http {
  constructor() {
    super();
  }
  /**
   * 新增主题
   * @param {Array} appThemeConfigDTO -  appThemeConfigDTO
   */

  appThemeConfig_addAppThemeConfig() {
    return super.post('appThemeConfig/addAppThemeConfig', ...arguments, {});
  }

  /**
   * 删除主题
   * @param {integer} id -  id
   */

  appThemeConfig_deleteAppThemeConfig() {
    return super.post('appThemeConfig/deleteAppThemeConfig', ...arguments);
  }

  /**
   * app主题分页列表
   * @param {Array} appThemeConfigBO -  appThemeConfigBO
   */

  appThemeConfig_getAppThemeConfigListByPage() {
    return super.post('appThemeConfig/getAppThemeConfigListByPage', ...arguments, {});
  }

  /**
   * 修改主题
   * @param {Array} appThemeConfigDTO -  appThemeConfigDTO
   */

  appThemeConfig_updateAppThemeConfig() {
    return super.post('appThemeConfig/updateAppThemeConfig', ...arguments, {});
  }

  /**
   * 主题详情
   * @param {integer} id -  id
   */

  appThemeConfig_getById() {
    return super.post('appThemeConfig/getById', ...arguments);
  }
}
