import Http from '../api/http/';
export default class PhoneManualAuthenticationApply extends Http {
  constructor() {
    super();
  }
  // 审核
  phoneManualAuthenticationApply_audit() {
    return super.post('phoneManualAuthenticationApply/audit', ...arguments);
  }
  // 详情
  phoneManualAuthenticationApply_detail() {
    return super.post('phoneManualAuthenticationApply/detail', ...arguments);
  }
  //列表
  phoneManualAuthenticationApply_list() {
    return super.post('phoneManualAuthenticationApply/list', ...arguments);
  }
  //修改申请人信息
  phoneManualAuthenticationApply_updateUserInfo() {
    return super.post('phoneManualAuthenticationApply/updateUserInfo', ...arguments);
  }
}
