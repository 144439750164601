import { debounce } from '../utils/debounce';

export default {
  bind: function (el, binding) {
    let obj = binding.value ? binding.value : {}, // 绑定值
      container = obj.container ? obj.container : '.el-drawer__body',
      className = obj.className ? obj.className : 'cm-fix-head',
      top = obj.top ? obj.top : 64,
      scrollDom = document.querySelector(container);
    let containerDom = scrollDom ? scrollDom : window;
    if (!containerDom) {
      console.error(`[watch scroll] Element '${container}' was not found. `);
      return;
    }
    let element = el;
    // 获取滚动到顶部的距离
    const getScrollTop = function (e) {
      if (container) return e.scrollTop;
      let scrollTop = document.documentElement.scrollTop == 0 ? document.body.scrollTop : document.documentElement.scrollTop;
      return scrollTop;
    };
    // 获取元素离顶部的距离
    const getOffsetTopByEl = function () {
      let yPosition = 0;
      let nextElement = element;
      while (nextElement) {
        yPosition += nextElement.offsetTop;
        nextElement = nextElement.offsetParent;
      }
      return yPosition;
    };
    // 滚动处理， 节流
    const handleScroll = debounce(function () {
      let scrollTop = getScrollTop(scrollDom);
      let result = getOffsetTopByEl();
      if (result <= scrollTop + top) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
    }, 200);
    containerDom.removeEventListener('scroll', handleScroll, false);
    containerDom.addEventListener('scroll', handleScroll, false);
  },
  unbind: function () {},
};
