import Http from '../api/http/';

export default class BusinessFeedback extends Http {
  constructor() {
    super();
  }

  /**
   * 商圈反馈List
   */
  businessFeedback_getListByPage() {
    return super.post('businessFeedback/getListByPage', ...arguments);
  }
  /**
   * 商圈反馈详情
   */
  businessFeedback_detail() {
    return super.post('businessFeedback/detail', ...arguments);
  }

  /**
   * 商圈处理
   * id
   * businessId
   * cityId
   * calibrationStatus
   */
  businessFeedback_calibration() {
    return super.post('businessFeedback/calibration', ...arguments);
  }
}
