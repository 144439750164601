import Http from '../api/http/';

export default class BusinessStatistics extends Http {
  constructor() {
    super();
  }
  /**
   * 商圈情况报表
   * @param {Array} businessStatisticDto -  businessStatisticDto
   */

  businessStatistics_businessStatistic() {
    return super.post('businessStatistics/businessStatistic', ...arguments, {});
  }

  /**
   * 导出商圈情况报表
   * @param {string} yearMonth -  年月 格式如201910
   * @param {string} businessName -  商圈名称
   * @param {integer} cityId -  城市
   * @param {integer} maxNumberSort -  最大报名数 0:正序；1：倒序
   * @param {integer} joinNumberSort -  报名数 0:正序；1：倒序
   * @param {integer} drawNumberSort -  开奖数 0:正序；1：倒序
   * @param {integer} storeNumberSort -  门店数 0:正序；1：倒序
   * @param {integer} finishTaskNumberSort -  完成任务数 0:正序；1：倒序
   * @param {integer} taskNumberSort -  发布任务数 0:正序；1：倒序
   * @param {integer} matchPercentSort -  匹配率 0:正序；1：倒序
   * @param {integer} needTaskMultipleDesc -  最小下限 0:正序；1：倒序
   * @param {integer} needMinNumberDesc -  最少人数 0:正序；1：倒序
   * @param {integer} taskIngNumberDesc -  进行中任务数 0:正序；1：倒序
   * @param {integer} lessTaskMultiple -  报名人数是否小于最小下限 0:否；1：是
   * @param {integer} lessMinNumber -  报名人数是否小于最少人数 0:否；1：是
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */

  businessStatistics_exportBusinessStatistic() {
    return super.get('businessStatistics/exportBusinessStatistic', ...arguments);
  }

  /**
   * 导出商圈进行中的任务
   * @param {string} businessId -  businessId
   */

  businessStatistics_exportTaskIng() {
    return super.get('businessStatistics/exportTaskIng', ...arguments);
  }

  /**
   * 根据商圈id获取进行中的任务
   * @param {string} businessId -  businessId
   */

  businessStatistics_getTaskIngList() {
    return super.post('businessStatistics/getTaskIngList', ...arguments);
  }
}
