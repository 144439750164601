import Http from '@/api/http';

export default class CompanyRoleInner extends Http {
  constructor() {
    super();
  }
  /**
   * 获取巡店菜单权限树
   * @param {companyId}
   * @returns
   */
  companyRoleInner_getAllMenuListTree() {
    return super.post('companyRoleInner/getAllMenuListTree', ...arguments);
  }
  /**
   * 获取巡店角色下拉
   * @param {companyId, needSuperAdmin}
   * @returns
   */
  companyRoleInner_getCompanyRoleList() {
    return super.post('companyRoleInner/getCompanyRoleList', ...arguments);
  }
}
