<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    urlList: {
      type: Array,
      default: () => [],
    },
    zIndex: {
      type: Number,
      default: 2000,
    },
    onSwitch: {
      type: Function,
      default: () => {},
    },
    initialIndex: {
      type: Number,
      default: 0,
    },
  },
  render(h) {
    if (this.isShow) {
      return h(ElImageViewer, {
        props: {
          urlList: this.urlList,
          zIndex: this.zIndex,
          onSwitch: this.onSwitch,
          onClose: this.onClose,
          initialIndex: this.initialIndex,
          appendToBody: false,
        },
      });
    }
    return h();
  },
  computed: {
    isShow: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    onClose() {
      this.isShow = false;
    },
    eventListener(e) {
      if (e.target.className === 'el-image-viewer__mask') {
        this.isShow = false;
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.eventListener, false);
  },
  destroyed() {
    document.removeEventListener('click', this.eventListener, false);
  },
};
</script>
