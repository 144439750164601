import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

/********** 引入element-ui组件 *************/
import ElementUi from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUi);

/********** 全局样式 *************/
import './style/index.less';
import './assets/font/iconfont/iconfont.css';
import './assets/font/iconfont/iconfont.js';

/********** 引入element-ui组件 *************/
import api from './api';
Vue.use(api);

/********** 全局过滤器、指令 *************/
import filters from './filters';
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

import directives from './directives';
Object.keys(directives).forEach(key => {
  Vue.directive(key, directives[key]);
});

/********** 插件 *************/
import './plugins/index';

//引入自定义组件
import components from './globalComponents';
Vue.use(components);

// 通过监听storage改变
window.addEventListener(
  'storage',
  function (e) {
    if (e.key == Vue.api.baseStorage.baseName + '_loginChange') {
      // 当key为loginChange，说明正在登录
      location.href = location.pathname + '#/login';
    }
  },
  false
);

// 全局错误获取
// const errorHandler = (error, vm, info)=>{
//   console.error("全局捕获异常: ", error, vm, info);
// }
// Vue.config.errorHandler = errorHandler;

/**********用户行为数据*************/
import behavior from './utils/behavior';
Vue.use(behavior);
router.afterEach(to => {
  Vue.prototype.bInstance.page(to);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
