import Http from '../api/http/';
export default class RecommendTaskUser extends Http {
  constructor() {
    super();
  }

  /***********推荐任务用户***************/
  /**
   * 给被推荐任务用户指定暗访任务
   * @param {Array} recommendTaskUserDTO -  recommendTaskUserDTO
   */

  recommendTaskUser_assignUserReport() {
    return super.post('recommendTaskUser/assignUserReport', ...arguments);
  }

  /**
   * 终止用户任务
   * @param {integer} recommendTaskUserId -  推荐任务用户id
   * @param {string} reason -  结束原因
   */

  recommendTaskUser_forceEndUserTask() {
    return super.post('recommendTaskUser/forceEndUserTask', ...arguments);
  }

  /**
   * 修改报酬
   * @param {integer} recommendTaskUserId -  推荐任务用户id
   * @param {integer} rewardType -  报酬类型 1积分 2现金
   * @param {number} reward -  报酬
   */

  recommendTaskUser_updateReward() {
    return super.post('recommendTaskUser/updateReward', ...arguments);
  }
  /**
   * 任务明细
   * @param {integer} recommendTaskUserId -  推荐任务用户id
   */
  recommendTaskUser_getRecommendTaskUserReportLogRelations() {
    return super.post('recommendTaskUser/getRecommendTaskUserReportLogRelations', ...arguments, {});
  }
}
