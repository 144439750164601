export default class {
  getEnv() {
    if (location.hostname === 'p.haitan.shop') {
      return 'pro';
    }
    if (location.hostname === 'tes.p.haitan.shop') {
      return 'tes';
    }
    if (location.hostname === 'pre.p.haitan.shop') {
      return 'pre';
    }
    return 'dev';
  }
}
