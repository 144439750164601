import Http from '../api/http/';
export default class DataExport extends Http {
  constructor() {
    super();
  }
  dataExport_exportCountExcel() {
    return super.post('dataExport/exportCountExcel', ...arguments);
  }
  dataExport_exportEmotionExcel() {
    return super.post('dataExport/exportEmotionExcel', ...arguments);
  }
  dataExport_exportScoreExcel() {
    return super.post('dataExport/exportScoreExcel', ...arguments);
  }
  //门店分析导出
  dataExport_exportStoreScoreExcel() {
    return super.post('dataExport/exportStoreScoreExcel', ...arguments);
  }
  //按判断次数导出门店
  dataExport_exportCountStoreEmotionExcel() {
    return super.post('dataExport/exportCountStoreEmotionExcel', ...arguments);
  }
  //区域品牌得分导出
  dataExport_exportSummarizDynamicScore() {
    return super.post('exportSummarizDynamicScore', ...arguments);
  }
  //区域品牌评级导出
  dataExport_exportSummarizDynamicGrade() {
    return super.post('exportSummarizDynamicGrade', ...arguments);
  }
  // 数据分析导出word图表
  dataExportAnalysisExport() {
    return super.post('dataExport/analysisExport', ...arguments);
  }
  //考核维度门店得分详情导出
  exportOriginStoreScore() {
    return super.post('dataExport/exportOriginStoreScore', ...arguments);
  }
  //考核小项得分率详情
  exportSingleScore() {
    return super.post('dataExport/exportSingleScore', ...arguments);
  }
}
