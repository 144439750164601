import Http from '../api/http/';

export default class TemplateManage extends Http {
  constructor() {
    super();
  }
  reportTemplate_getListByPage() {
    return super.post('reportTemplate/getListByPage', ...arguments);
  }
  /**
   * @description 获取模板列表分页数据
   * @param
   */
  reportTemplate_delete() {
    return super.post('reportTemplate/delete', ...arguments);
  }
  /**
   * @description 删除模板列表模板
   * @param 模板ID
   */
  reportTemplate_getById() {
    return super.post('reportTemplate/getById', ...arguments);
  }
  dataClassify_getList() {
    return super.post('dataClassify/getList', ...arguments);
  }
  // 通过品牌id查询其所在企业的品牌线上的报告模板
  reportTemplate_getOnlineEnterpriseTemplateListByBrandId() {
    return super.post('reportTemplate/getOnlineEnterpriseTemplateListByBrandId', ...arguments);
  }
  //创建模板引用列表
  reportTemplate_getQuoteList() {
    return super.post('reportTemplate/getQuoteList', ...arguments);
  }
}
