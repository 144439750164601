import Http from '@/api/http/';

export default class DailyQuestion extends Http {
  constructor() {
    super();
  }
  /**
   * 每日一题：获取分页列表
   * @param {body}
   * @returns
   */
  dailyQuestion_getDailyQuestionList() {
    return super.post('dailyQuestion/getDailyQuestionList', ...arguments);
  }

  /**
   * 每日一题：获取详情
   * @param { id }
   * @returns
   */
  dailyQuestion_getDailyQuestion() {
    return super.post('dailyQuestion/getDailyQuestion', ...arguments);
  }

  /**
   * 每日一题：上线
   * @param { id }
   * @returns
   */
  dailyQuestion_onlineDailyQuestion() {
    return super.post('dailyQuestion/onlineDailyQuestion', ...arguments);
  }

  /**
   * 每日一题：下线
   * @param { id }
   * @returns
   */
  dailyQuestion_offlineDailyQuestion() {
    return super.post('dailyQuestion/offlineDailyQuestion', ...arguments);
  }

  /**
   * 每日一题：删除
   * @param { id }
   * @returns
   */
  dailyQuestion_deleteDailyQuestion() {
    return super.post('dailyQuestion/deleteDailyQuestion', ...arguments);
  }

  /**
   * 每日一题：编辑/新增
   * @param {body}
   * @returns
   */
  dailyQuestion_editDailyQuestion() {
    return super.post('dailyQuestion/editDailyQuestion', ...arguments);
  }

  /**
   * 每日一题：参与人数统计
   * @param {body}
   * @returns
   */
  dailyQuestion_getDailyQuestionStatisticsList() {
    return super.post('dailyQuestion/getDailyQuestionStatisticsList', ...arguments);
  }

  /**
   * 每日一题：批量导入
   * @returns
   */
  dailyQuestion_importDailyQuestion() {
    return super.post('dailyQuestion/importDailyQuestion', ...arguments);
  }

  /**
   * 每日一题：导出
   * @returns
   */
  dailyQuestion_exportDailyQuestion() {
    return super.post('dailyQuestion/exportDailyQuestion', ...arguments, {});
  }

  /**
   * 参与人数：导出
   * @returns
   */
  dailyQuestion_exportDailyQuestionStatistics() {
    return super.post('dailyQuestion/exportDailyQuestionStatistics', ...arguments);
  }
}
