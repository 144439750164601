import Http from '../api/http/';
export default class RenewRecord extends Http {
  constructor() {
    super();
  }

  /***********更新记录管理***************/
  /**
   * 添加更新记录
   * @param {string} title -  标题
   * @param {string} fileUrl -  文档地址 多个以英文分号隔开
   */

  renewRecord_add() {
    return super.post('renewRecord/add', ...arguments);
  }

  /**
   * 更新记录详情
   * @param {integer} id -  id
   */

  renewRecord_detail() {
    return super.post('renewRecord/detail', ...arguments);
  }

  /**
   * 更新记录列表
   * @param {string} title -  标题
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */

  renewRecord_list() {
    return super.post('renewRecord/list', ...arguments);
  }

  /**
   * 删除更新记录
   * @param {integer} id -  id
   */

  renewRecord_remove() {
    return super.post('renewRecord/remove', ...arguments);
  }

  /**
   * 修改更新记录
   * @param {integer} id -  id
   * @param {string} title -  标题
   * @param {string} fileUrl -  文档地址
   */

  renewRecord_update() {
    return super.post('renewRecord/update', ...arguments);
  }
}
