import Http from '../api/http/';

export default class userLoginDeviceLog extends Http {
  constructor() {
    super();
  }
  /**
   * 获取用户登录轨迹列表
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   * @param {string} userId -  获取用户登录记录轨迹(排除pc端)
   */
  userLoginDeviceLog_getListByPage() {
    return super.post('userLoginDeviceLog/getListByPage', ...arguments);
  }
  // 登录设备记录包含c端登录记录
  userLoginDeviceLog_getDeviceLogListByPage() {
    return super.post('userLoginDeviceLog/getDeviceLogListByPage', ...arguments);
  }
  // 用户设备列表详情导出
  userLoginDeviceLog_exportDeviceLogByUser() {
    return super.get('userLoginDeviceLog/exportDeviceLogByUser', ...arguments);
  }
  userLoginDeviceLog_exportDeviceLogByParam() {
    return super.get('userLoginDeviceLog/exportDeviceLogByParam', ...arguments);
  }
  // 可疑账号
  userLoginDeviceLog_getLoginDeviceRelations() {
    return super.post('userLoginDeviceLog/getLoginDeviceRelations', ...arguments);
  }
}
