/**
 * 最大值最小值和小数位限制，
 * bind的值不是双向绑定，只是第一次创建初始化参数
 * v-input-limit 数值限制
 * v-input-limit="{min: 2}" 限制最小值为2
 * v-input-limit="{max: 2}" 限制最大值为2
 * v-input-limit="{num: 2}" 限制小数位2
 * v-input-limit.positive 限制为正数
 * v-input-limit="{num: 0}" 限制整数
 */
export default {
  bind: function (el, binding) {
    let inputNode = el;
    if (el.nodeName && el.nodeName.toLocaleUpperCase() != 'TEXTAREA' && el.nodeName.toLocaleUpperCase() != 'INPUT') {
      inputNode = el.querySelector('input,textarea');
    }
    let inputLimit = {
      min: '', // 最小值
      max: '', // 最大值
      num: '', // 小数位
      positive: false,
      blurHandle: function (e) {
        let value = e.target.value;
        if (value === '' || value === null || value === undefined) {
          return;
        }
        value = Number(value);
        // 数值处理
        if (isNaN(value)) {
          e.target.value = '';
          inputLimit.changeHandle();
          return;
        }
        // 点开始处理
        if (/^\./g.test(value)) {
          e.target.value = '';
          inputLimit.changeHandle();
          return;
        }
        // 正数限制
        if (inputLimit.positive && value < 0) {
          e.target.value = '';
          inputLimit.changeHandle();
          return;
        }
        // 小数位限制 isNaN(value)会拦截非法数字
        if (inputLimit.num !== '') {
          const num = parseInt(inputLimit.num, 10);
          let req = new RegExp('^(-?)(\\d+)(\\.?)(\\d{0,' + num + '}).*$', 'g');
          value = value.toString().replace(req, num ? '$1$2$3$4' : '$1$2');
          value = Number(value);
        }
        // 最小值限制
        if (inputLimit.min !== '' && value < inputLimit.min) {
          value = inputLimit.min;
        }
        // 最大值限制
        if (inputLimit.max !== '' && value > inputLimit.max) {
          value = inputLimit.max;
        }
        e.target.value = value;
        inputLimit.changeHandle();
      },
      changeHandle: function () {
        // 触发双向绑定
        inputNode.dispatchEvent(new Event('input'));
        // 触发change事件，input上有change事件，修改后的数据触发change事件
        inputNode.dispatchEvent(new Event('change'));
      },
    };
    let bindValue = binding.value ? binding.value : {}; // 绑定值
    inputLimit.min = bindValue.min === undefined ? '' : bindValue.min;
    inputLimit.max = bindValue.max === undefined ? '' : bindValue.max;
    inputLimit.num = bindValue.num === undefined ? '' : bindValue.num;
    inputLimit.positive = binding.modifiers.positive ? true : false;
    inputNode && inputNode.addEventListener('blur', inputLimit.blurHandle, false);
  },
  unbind: function () {},
};
