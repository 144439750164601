import serviceConfig from '@/service/service.json';
import { classMixin } from '@/utils/classMixin';

// 引用需要继承的类
const classArray = [];
serviceConfig.forEach(io => {
  const classObj = require(`@/service/${io}`);
  classArray.push(classObj.default);
});

/**
 * 所有接口
 */
export default class service extends classMixin(...classArray) {}
