import Http from '@/api/http';

export default class MonthStatisticsNew extends Http {
  constructor() {
    super();
  }
  /**
   * 业务统计
   * @param {companyId,departmentId,month,year,type,page,rows}
   * @returns
   */
  monthStatisticsNew_list() {
    return super.post('monthStatisticsNew/list', ...arguments);
  }
  /**
   * 业务统计导出
   * @returns
   */
  monthStatisticsNew_export() {
    return super.blobDownload('monthStatisticsNew/export', ...arguments);
  }
  /**
   * 报告数据列表
   * @returns
   */
  monthStatisticsNew_reportCountList() {
    return super.post('monthStatisticsNew/reportCountList', ...arguments);
  }
  /**
   * 报告数据导出
   * @returns
   */
  monthStatisticsNew_exportReportCount() {
    return super.blobDownload('monthStatisticsNew/exportReportCount', ...arguments);
  }
  /**
   * 员工计件-费用列表
   * @returns
   */
  monthStatisticsNew_getCategroyFreeList() {
    return super.post('monthStatisticsNew/getCategroyFreeList', ...arguments);
  }
  /**
   * 员工计件-费用列表导出
   * @returns
   */
  monthStatisticsNew_exportCategroyFreeList() {
    return super.blobDownload('monthStatisticsNew/exportCategroyFreeList', ...arguments);
  }
  /**
   * 实销-费用列表
   * @returns
   */
  monthStatisticsNew_getCategroyPayFreeList() {
    return super.post('monthStatisticsNew/getCategroyPayFreeList', ...arguments);
  }
  /**
   * 实销-费用列表导出
   * @returns
   */
  monthStatisticsNew_exportCategroyPayFreeList() {
    return super.blobDownload('monthStatisticsNew/exportCategroyPayFreeList', ...arguments);
  }
  /**
   * 实销 预付 账单列表
   * @returns
   */
  monthStatisticsNew_getBillDetail() {
    return super.post('monthStatisticsNew/getBillDetail', ...arguments);
  }
  /**
   * 实销 预付 账单导出
   * @returns
   */
  monthStatisticsNew_exportBillDetail() {
    return super.blobDownload('monthStatisticsNew/exportBillDetail', ...arguments);
  }
  /**
   * 开票信息列表
   * @returns
   */
  monthStatisticsNew_getInvoiceAndPreStatementDetail() {
    return super.post('monthStatisticsNew/getInvoiceAndPreStatementDetail', ...arguments);
  }
  /**
   * 开票信息导出
   * @returns
   */
  monthStatisticsNew_exportInvoiceAndPreStatementDetail() {
    return super.blobDownload('monthStatisticsNew/exportInvoiceAndPreStatementDetail', ...arguments);
  }
  /**
   * 银行流水详情
   * @returns
   */
  monthStatisticsNew_getBankStatementDetail() {
    return super.post('monthStatisticsNew/getBankStatementDetail', ...arguments);
  }
  /**
   * 银行流失导出
   * @returns
   */
  monthStatisticsNew_exportBankStatementDetail() {
    return super.blobDownload('monthStatisticsNew/exportBankStatementDetail', ...arguments);
  }
  /**
   * 员工计件税费列表
   * @returns
   */
  monthStatisticsNew_getTaxMoneyList() {
    return super.post('monthStatisticsNew/getTaxMoneyList', ...arguments);
  }
  /**
   * 实报实销税费列表
   * @returns
   */
  monthStatisticsNew_getTaxMoneyPayList() {
    return super.post('monthStatisticsNew/getTaxMoneyPayList', ...arguments);
  }
  /**
   * 导出税费用列表
   * @returns
   */
  monthStatisticsNew_exportTaxMoneyList() {
    return super.blobDownload('monthStatisticsNew/exportTaxMoneyList', ...arguments);
  }
  /**
   * 导出实报实销税费用列表
   * @returns
   */
  monthStatisticsNew_exportTaxMoneyPayList() {
    return super.blobDownload('monthStatisticsNew/exportTaxMoneyPayList', ...arguments);
  }
}
