<template>
  <el-col :span="span">
    <el-form-item :label="label">
      <el-input v-model="value" size="small" disabled></el-input>
    </el-form-item>
  </el-col>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    span: {
      type: Number,
      default: 6,
    },
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  border-color: #fff;
  color: #e9e9e9;
  cursor: default;
}
</style>
