export default {
  // 格式化时间
  date(value, format = 'YYYY-MM-DD') {
    if (!value) return '';
    return new moment(value).format(format);
  },
  // 数字加千分位符
  numberFormat(s) {
    if ((!s && s !== 0) || /[^0-9.]/.test(s)) return '';
    let arr = s.toString().split('.');
    s = arr[0].replace(/(\d)(?=(\d{3})+(\d{3})?$)/g, '$1,');
    if (arr.length == 2) {
      s += '.' + arr[1];
    }
    return s;
  },
  // 文件名称
  fileNameFilter(value) {
    if (!value) {
      return '';
    }
    let index = value.lastIndexOf('/');
    if (index < 1) {
      return value;
    }
    return decodeURIComponent(value.substring(index + 1));
  },
};
