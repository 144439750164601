import Http from '../api/http/';

export default class BaiduMap extends Http {
  constructor() {
    super();
  }
  /**
   * 通过地址逆向解析
   * @param {string} address -  address
   */

  baiduMap_reverseAddress() {
    return super.post('baiduMap/reverseAddress', ...arguments);
  }
}
