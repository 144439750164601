import BaseApi from './baseApi';
import BaseCookie from './baseCookie';
import BaseStorage from './baseStorage';
import Element from './element';
import Env from './env';
import OssService from './ossService';

export default class extends BaseApi {
  constructor() {
    super();
    this.baseApi = new BaseApi();
    this.baseCookie = new BaseCookie();
    this.baseStorage = new BaseStorage();
    this.element = new Element();
    this.env = new Env();
    this.ossService = new OssService();
  }
}
