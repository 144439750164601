import Http from '../api/http/';

export default class userRelation extends Http {
  constructor() {
    super();
  }
  /**
   * 获取用户关联账号（申请移除/添加黑名单调用）
   * @param {string} userId -  用户id
   * @param {integer} applyType -  申请类型（1-拉黑 2-移除）
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */
  userRelation_getUserRelationPageList() {
    return super.post('userRelation/getUserRelationPageList', ...arguments);
  }

  /**
   * 获取用户关联账号（查看移除/添加黑名单调用）
   * @param {string} tagId -  标识id
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */
  userRelation_queryUserRelationPageList() {
    return super.post('userRelation/queryUserRelationPageList', ...arguments);
  }

  /**
   * 获取关联对象信息
   * @param {string} uid -  uid
   */
  userRelation_getRelateUser2Levellist() {
    return super.post('userRelation/getRelateUser2Levellist', ...arguments);
  }
}
