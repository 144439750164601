<template>
  <div class="phone" :style="phoneStyle">
    <span>{{ phone }}</span>
    <span v-if="phone">
      <i v-role="'web:call:flag'" @click="webCall" class="iconfont icondianhuabohao2x1 call-icon"></i>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    phone: [String, Number],
    phoneStyle: [String, Object],
  },
  methods: {
    // 隐私通话
    webCall() {
      this.api
        .confirm(`确定拨打【${this.phone}】这个号码吗？`)
        .then(() => {
          this.api.service.callChat_heliCallByPhone({ phone: this.phone }).then(res => {
            if (res.code == 0) {
              this.api.toast('电话在拨打中，请等待接通...');
            } else {
              if (res.result?.funtion == 8) {
                this.api
                  .confirm(
                    `<div style="font-weight:bold;">${res.msg}</div>
                    <div style="color:#666;">温馨提示:如还未下载安装留客电话，请先进行下载并解压缩包，打开microsip软件；已下载软件的请检查是否打开文件夹中的microsip软件</div>`,
                    '开启提示',
                    {
                      dangerouslyUseHTMLString: true,
                      confirmButtonText: '下载安装',
                      showCancelButton: true,
                    }
                  )
                  .then(() => {
                    this.api.service.callChat_getSipClientUrl({}, 'download');
                  })
                  .catch(() => {});
              } else {
                this.api.toast(res.msg, 'error');
              }
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.phone {
  display: flex;
  align-items: center;
}
.call-icon {
  cursor: pointer;
  color: #03c35b;
  margin-left: 4px;
}
</style>
