import Http from '../api/http/';

export default class CompanyRole extends Http {
  constructor() {
    super();
  }
  // 获取企业下角色菜单
  companyRole_getCompanyRoleMenuList() {
    return super.post('companyRole/getCompanyRoleMenuList', ...arguments);
  }
  // 角色列表
  companyRole_getList() {
    return super.post('companyRole/getList', ...arguments);
  }
  // 获取全部菜单
  companyRole_getAllMenuList() {
    return super.post('companyRole/getAllMenuList', ...arguments);
  }
  // 修改自定义角色/权限
  companyRole_updateUserDefinedRole() {
    return super.post('companyRole/updateUserDefinedRole', ...arguments);
  }
  // 获取角色详情
  companyRole_getRoleDetail() {
    return super.post('companyRole/getRoleDetail', ...arguments);
  }
  // 查询企业角色菜单合集
  companyRole_listRoleDetail() {
    return super.post('companyRole/listRoleDetail', ...arguments);
  }
  // 保存企业菜单权限
  companyRole_saveCompanyMenuPermission() {
    return super.post('companyRole/saveCompanyMenuPermission', ...arguments);
  }
  /**
   * 查询默认企业角色菜单权限
   * @param {integer} operationType -  0代运营 1自运营 2自+代运营
   * @param {integer} roleId -  角色
   */
  companyRole_listDefaultCompanyRolePermission() {
    return super.post('companyRole/listDefaultCompanyRolePermission', ...arguments);
  }
  // 角色下拉
  companyRole_getCompanyRoleList() {
    return super.post('companyRole/getCompanyRoleList', ...arguments);
  }
}
