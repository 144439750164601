import Http from '../api/http/';

export default class RequireFeedback extends Http {
  constructor() {
    super();
  }

  // b端需求详情
  requireFeedback_getDetailById() {
    return super.post('requireFeedback/getDetailById', ...arguments);
  }

  // b端需求列表
  requireFeedback_getListByLimit() {
    return super.post('requireFeedback/getListByLimit', ...arguments);
  }

  // 处理B端需求
  requireFeedback_handle() {
    return super.post('requireFeedback/handle', ...arguments);
  }
}
