<template>
  <div v-watchScroll>
    <el-table
      :ref="name"
      :data="tb.data"
      v-loading="tb.loading"
      size="medium"
      class="gl-table gl-table-component"
      style="width: 100%"
      v-bind="$attrs"
      v-on="$listeners"
      tooltip-effect="light"
      border
      stripe
    >
      <slot name="index">
        <el-table-column label="序号" type="index" align="center" :index="index" width="60"></el-table-column>
      </slot>
      <slot></slot>
    </el-table>
    <div v-if="pagination">
      <div class="gl-pagination-fix-line"></div>
      <div class="gl-pagination-fix" v-paginationResize>
        <div class="gl-pagination-btn">
          <slot name="paginationBtn"></slot>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          :disabled="tb.loading"
          @current-change="handleCurrentChange"
          :current-page="tb.page"
          :page-sizes="pageSizes"
          :page-size="tb.size"
          :pager-count="5"
          :layout="layout"
          :total="tb.total"
        >
          <span>{{ tb.page }}/{{ Math.ceil(tb.total / tb.size) }}</span>
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: 'glTable',
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    load: {
      type: Function,
    },
    firstLoad: {
      type: Boolean,
      default: true,
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 20, 30, 50, 100, 200],
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, slot, next, jumper',
    },
  },
  data() {
    return {
      tb: {
        condition: {},
        data: [],
        loading: false,
        page: 1,
        size: 10,
        total: 0,
      },
    };
  },
  created() {
    if (this.firstLoad) this._loadData();
  },
  methods: {
    /*********外部可调用方法*************/
    //刷新当前数据
    refresh() {
      this._loadData();
    },
    //搜索
    search(condition) {
      this.tb.condition = condition;
      this.reload();
    },
    //重新加载数据
    reload() {
      this.tb.page = 1;
      this._loadData();
    },
    // 序号
    index(num) {
      return (this.tb.page - 1) * this.tb.size + num + 1;
    },
    // 状态
    getLoading() {
      return this.tb.loading;
    },
    /*********内部私有方法*************/
    handleSizeChange(val) {
      this.tb.size = val;
      this.tb.page = 1;
      this._loadData();
    },
    handleCurrentChange(val) {
      this.tb.page = val;
      this._loadData();
    },
    _loadData() {
      if (this.tb.loading) return;
      this.tb.loading = true;
      try {
        this.load(this.tb)
          .then(res => {
            if (res) {
              //如果页码大于1，而data没有数据，则向前返回一页
              if (this.tb.page > 1 && res.data.length === 0 && res.total > this.tb.size) {
                this.tb.loading = false;
                this.tb.page = this.tb.page - 1;
                this._loadData();
                return;
              }
              this.tb.data = res.data;
              this.tb.total = res.total;
            }
          })
          .finally(() => {
            this.tb.loading = false;
          });
      } catch (error) {
        this.api.toast(error, 'error');
        this.tb.loading = false;
      }
    },
  },
};
</script>
