import Http from '../api/http/';

export default class AppVersion extends Http {
  constructor() {
    super();
  }
  /**
   * 配置更新
   * @param {Array} appversionDTO -  appversionDTO
   */
  appversion_deployAppversion() {
    return super.post('appversion/deployAppversion', ...arguments, {});
  }

  /**
   * 通过id查询
   * @param {integer} id -  id
   */
  appversion_getById() {
    return super.post('appversion/getById', ...arguments);
  }

  /**
   * 分页查询
   * @param {string} appid -  appid
   * @param {string} version -  version
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */
  appversion_getListByPage() {
    return super.post('appversion/getListByPage', ...arguments);
  }

  /**
   * 修改配置更新
   * @param {Array} appversionDTO -  appversionDTO
   */
  appversion_updateAppversion() {
    return super.post('appversion/updateAppversion', ...arguments, {});
  }
}
