export default {
  bind: function (el, binding) {
    el.handler = () => {
      //点击超时，防止重复点击
      el.classList.add('is-disabled');
      el.setAttribute('disabled', 'disabled');
      let time = 2000;
      if (/^\d+$/.test(binding.value)) time = parseInt(binding.value);
      setTimeout(() => {
        el.classList.remove('is-disabled');
        el.removeAttribute('disabled');
      }, time);
    };
    el.addEventListener('click', el.handler, false);
  },
  unbind: function (el) {
    el.removeEventListener('click', el.handler, false);
  },
};
