import Http from '../api/http/';

export default class userBlackApply extends Http {
  constructor() {
    super();
  }
  /**
   * 校验用户是否能申请添加黑名单
   * @param {string} userId -  用户id
   */
  userBlackApply_checkUser() {
    return super.post('userBlackApply/checkUser', ...arguments);
  }

  /**
   * 申请添加/移除黑名单
   * @param {Array} userBlackApplyDTO -  userBlackApplyDTO
   */
  userBlackApply_applyBlack() {
    return super.post('userBlackApply/applyBlack', ...arguments);
  }

  /**
   * 申请添加/移除黑名单列表
   * @param {string} guestUserName -  访客用户姓名
   * @param {string} phone -  访客手机号
   * @param {string} applyUserName -  申请用户姓名
   * @param {integer} auditStatus -  0-待审核 1-已通过 2-不通过
   * @param {integer} applyType -  申请类型（1-拉黑 2-移除）
   * @param {string} startTime -  开始时间
   * @param {string} endTime -  结束时间
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  userBlackApply_applyBlackListByPage() {
    return super.post('userBlackApply/applyBlackListByPage', ...arguments);
  }

  /**
   * 获取用户申请详情
   * @param {integer} id -  申请主键id
   */
  userBlackApply_getApplyDetailById() {
    return super.post('userBlackApply/getApplyDetailById', ...arguments);
  }

  /**
   * 审核拉黑/移除申请
   * @param {string} tagId -  标识id
   * @param {integer} auditStatus -  审核状态（1-已通过 2-不通过）
   * @param {string} auditReason -  审核说明
   */
  userBlackApply_auditApply() {
    return super.post('userBlackApply/auditApply', ...arguments);
  }

  /**
   * 查看用户拉黑/移除记录列表
   * @param {string} guestUserId -  访客用户id
   * @param {string} guestUserName -  访客用户姓名
   * @param {string} phone -  访客手机号
   * @param {string} applyUserName -  申请用户姓名
   * @param {integer} auditStatus -  0-待审核 1-已通过 2-不通过
   * @param {integer} applyType -  申请类型（1-拉黑 2-移除）
   * @param {string} startTime -  开始时间
   * @param {string} endTime -  结束时间
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  userBlackApply_getApplyBlackRecordByPage() {
    return super.post('userBlackApply/getApplyBlackRecordByPage', ...arguments);
  }

  /**
   * 导出申请添加/移除黑名单列表
   * @param {Array} userBlackApplySearchDTO -  userBlackApplySearchDTO
   */

  userBlackApply_exportApplyBlackList() {
    return super.get('userBlackApply/exportApplyBlackList', ...arguments);
  }
}
