import Http from '../api/http/';

export default class CompanyInvoice extends Http {
  constructor() {
    super();
  }

  // 发票邮寄,发票处理
  companyInvoice_handleInvoice() {
    return super.post('companyInvoice/handleInvoice', ...arguments, {});
  }
  // 获取发票邮寄,发票处理信息
  companyInvoice_getInvoiceHandleInfo() {
    return super.post('companyInvoice/getInvoiceHandleInfo', ...arguments, {});
  }
  // 修改发票的处理信息
  companyInvoice_updateInvoiceHandleInfo() {
    return super.post('companyInvoice/updateInvoiceHandleInfo', ...arguments, {});
  }
  // 驳回发票
  companyInvoice_rejectBillInvoiceApply() {
    return super.post('companyInvoice/rejectBillInvoiceApply', ...arguments);
  }
  // 获取发票列表
  companyInvoicev2_getList() {
    return super.post('companyInvoicev2/getList', ...arguments);
  }
  // 发票 提交
  companyInvoicev2_submit() {
    return super.post('companyInvoicev2/submit', ...arguments);
  }
  // 发票 删除
  companyInvoicev2_deleteCompanyInvoice() {
    return super.post('companyInvoicev2/deleteCompanyInvoice', ...arguments);
  }
  // 发票 撤销
  companyInvoicev2_cancel() {
    return super.post('companyInvoicev2/cancel', ...arguments);
  }
  // 发票 申请红冲
  companyInvoicev2_reversalApply() {
    return super.post('companyInvoicev2/reversalApply', ...arguments);
  }
  // 发票 详情
  companyInvoicev2_detail() {
    return super.post('companyInvoicev2/detail', ...arguments);
  }
  // 获取账单某发票的可开金额
  companyInvoicev2_getUnAmountByType() {
    return super.post('companyInvoicev2/getUnAmountByType', ...arguments);
  }
  // 获取开票项类目，和业务类别
  companyInvoicev2_getInvoiceItems() {
    return super.post('companyInvoicev2/getInvoiceItems', ...arguments);
  }
  // 新增 开票
  companyInvoicev2_addCompanyInvoice() {
    return super.post('companyInvoicev2/addCompanyInvoice', ...arguments);
  }
  // 修改 开票
  companyInvoicev2_updateCompanyInvoice() {
    return super.post('companyInvoicev2/updateCompanyInvoice', ...arguments);
  }
  // 自动开票
  companyInvoicev2_autoAddInvoiceInfos() {
    return super.post('companyInvoicev2/autoAddInvoiceInfos', ...arguments);
  }
  // 申请发票--导出
  companyInvoicev2_exportList() {
    return super.post('companyInvoicev2/exportList', ...arguments);
  }
  // 发票列表--导出
  companyInvoicev2_exportByInvoiceList() {
    return super.post('companyInvoicev2/exportByInvoiceList', ...arguments);
  }
  // 发票--引用-账单
  companyInvoicev2_quoteInvoices() {
    return super.post('companyInvoicev2/quoteInvoices', ...arguments);
  }
  // 发票--批量导入
  companyInvoicev2_import() {
    return super.post('companyInvoicev2/import', ...arguments);
  }
  // 发票--批量下载
  companyInvoicev2_getInvoicesForZip() {
    return super.post('companyInvoicev2/getInvoicesForZip', ...arguments);
  }
  // 发票--批量更新发票
  companyInvoicev2_batchUpdateInfo() {
    return super.post('companyInvoicev2/batchUpdateInfo', ...arguments);
  }
}
