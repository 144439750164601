import Http from '../api/http/';
export default class MedalAwardUser extends Http {
  constructor() {
    super();
  }
  // 批量颁发勋章
  medalAwardUser_batchAward() {
    return super.post('medalAwardUser/batchAward', ...arguments);
  }
  // 批量导入颁发勋章
  medalAwardUser_batchImportMedalAward() {
    return super.post('medalAwardUser/batchImportMedalAward', ...arguments);
  }
  // 勋章颁发用户分页列表
  medalAwardUser_getMedalAwardListByPage() {
    return super.post('medalAwardUser/getMedalAwardListByPage', ...arguments);
  }
  // 用户勋章导出
  medalAwardUser_medalAwardUserExport() {
    return super.get('medalAwardUser/medalAwardUserExport', ...arguments);
  }
}
