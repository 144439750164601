<template>
  <div class="card">
    <div class="card-header" :class="{ 'card-sticky': isSticky }">
      <div class="left">
        <slot name="title">{{ title }}</slot>
      </div>
      <div class="right">
        <slot name="button"></slot>
        <el-link :underline="false" v-if="isExpand" @click="flag = !flag">
          <span class="title">{{ flag ? '展开' : '收起' }}</span>
          <i :class="flag ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
        </el-link>
      </div>
    </div>
    <div class="card-body" :style="cardBodyStyle">
      <el-collapse-transition>
        <div v-show="!flag">
          <slot></slot>
        </div>
      </el-collapse-transition>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    isExpand: {
      type: Boolean,
      default: true,
    },
    cardBodyStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      flag: false,
    };
  },
};
</script>
<style lang="less" scoped>
.card {
  border: 1px solid #e9e9e9;
  box-shadow: 0 18px 20px 0 rgba(87, 95, 153, 0.05);
  border-radius: 8px;

  background: #fff;
  & + .card {
    margin-top: 16px;
  }
}
.card-header {
  height: 48px;
  background-image: linear-gradient(180deg, #ffffff 0%, #f8f9fc 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-radius: 8px 8px 0 0;
}
.card-sticky {
  position: sticky;
  top: -20px;
  z-index: 9;
}
.left {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-left: 10px;
  &::before {
    content: '';
    display: block;
    background-color: #905220;
    width: 2px;
    height: 12px;
    border-radius: 2px;
    margin-right: 8px;
    margin-left: -10px;
  }
}
.right {
  display: flex;
  align-items: center;
  .title {
    padding-right: 8px;
  }
}
.card-body {
  padding: 20px 24px;
}
</style>
