export default {
  bind: function () {},
  update: function (el, binding) {
    let input = el;
    if (el.nodeName && el.nodeName.toLocaleUpperCase() != 'TEXTAREA' && el.nodeName.toLocaleUpperCase() != 'INPUT') {
      input = el.querySelector('input,textarea');
    }
    let inputValue = input.value,
      newValue = inputValue,
      flag = '';
    if (!inputValue) {
      //当值为空的时候，不做校验
      return;
    }
    if (!binding.modifiers.number) {
      // 判断修饰对象是否式number
      return;
    }
    if (binding.modifiers.minus) {
      // 支持负数
      if (newValue == '-' || newValue == '-0' || newValue == '-0.') {
        return;
      }
      if (newValue < 0) {
        flag = '-';
      }
    }
    newValue = newValue.replace(/[^\d.]/g, '');

    let value = binding.value; // 绑定值
    if (value) {
      // 小数
      value = parseInt(value);
      newValue = /^\./g.test(newValue) ? '' : newValue;
      let req = new RegExp('^(\\.*)(\\d+)(\\.?)(\\d{0,' + value + '}).*$', 'g');
      newValue = newValue.replace(req, '$2$3$4');
    } else {
      // 整数
      newValue = newValue.replace(/[^\d]+/g, '');
    }
    if (binding.modifiers.minus && flag) {
      newValue = flag + newValue;
    }
    if (inputValue !== newValue) {
      input.value = newValue;
      input.dispatchEvent(new Event('input'));
    }
  },
  unbind: function () {},
};
