import Http from '../api/http/';

export default class userMedalDetail extends Http {
  constructor() {
    super();
  }
  /***********用户勋章***************/
  /**
   * getUserHaveMedal
   * @param {string} userId -  用户id
   */
  userMedalDetail_getUserHaveMedal() {
    return super.post('userMedalDetail/getUserHaveMedal', ...arguments);
  }
}
