import Http from '../api/http/';
export default class AppHome extends Http {
  constructor() {
    super();
  }
  /**
   * appHomePageDetail
   * @param {integer} homePageId -  homePageId
   */
  appHomePage_appHomePageDetail() {
    return super.post('appHomePage/appHomePageDetail', ...arguments);
  }

  /**
   * listByPage
   * @param {string} homePageName -  homePageName
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */
  appHomePage_listByPage() {
    return super.post('appHomePage/listByPage', ...arguments);
  }

  /**
   * updateAppHomePageConfig
   * @param {Array} appHomePageDTO -  appHomePageDTO
   */
  appHomePage_updateAppHomePageConfig() {
    return super.post('appHomePage/updateAppHomePageConfig', ...arguments, {});
  }
  /**
   * 页面来源分页列表
   * @param {string} pageName -  pageName
   * @param {integer} page -  page
   * @param {integer} rows -  rows
   */
  appHomePage_appPageSourceListByPage() {
    return super.post('appHomePage/appPageSourceListByPage', ...arguments);
  }
  /**
   * 新增首页配置
   * @param {Array} appHomePageDTO -  appHomePageDTO
   */
  appHomePage_addAppHomePageConfig() {
    return super.post('appHomePage/addAppHomePageConfig', ...arguments, {});
  }

  /**
   * 首页配置删除
   * @param {integer} homePageId -  首页配置主键Id
   */
  appHomePage_appHomePageDelete() {
    return super.post('appHomePage/appHomePageDelete', ...arguments);
  }
  /**
   * 首页配置上下线 将之前上线的数据改为下线
   * @param {integer} homePageId -  首页配置主键Id
   * @param {integer} recordStatus -  1-上线 2-下线
   */
  appHomePage_appHomePageOnOffline() {
    return super.post('appHomePage/appHomePageOnOffline', ...arguments);
  }
}
