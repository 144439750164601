<!-- form 不能设置size，默认lin-height=40，input 设置size为small 这样缩放网页不出错位 -->
<template>
  <div>
    <el-form ref="searchForm" :model="value" :rules="rules" @submit.native.prevent class="gl-search-form">
      <el-row>
        <slot></slot>
        <div v-show="!flag">
          <slot name="hiddenSearch"></slot>
        </div>
        <el-col :span="searchBtnSpan">
          <div class="gl-search-form-btn">
            <el-button @click="searchClick" type="text" class="gl-btn-primary" :disabled="disabled">查询</el-button>
            <el-button @click="resetClick" type="text" class="gl-btn-plain" :disabled="disabled">重置</el-button>
            <el-link v-if="isExpand" type="primary" :underline="false" class="gl-search-expand" @click="flag = !flag">
              {{ flag ? '展开' : '收起' }}
              <i :class="['el-icon-view', flag ? 'el-icon-arrow-down' : 'el-icon-arrow-up']"></i>
            </el-link>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Object,
      default: () => {},
    },
    searchBtnSpan: {
      type: Number,
      default: 4,
    },
    isExpand: {
      // 是否展示收缩
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disabled: false,
      flag: true, // 收缩，默认收缩
    };
  },
  methods: {
    // 搜索事件
    searchClick() {
      this.$refs['searchForm'].validate(valid => {
        if (valid) {
          this.disabled = true;
          this.$emit('searchClick'); //调用父组件的查询方法
          setTimeout(() => {
            this.disabled = false;
          }, 3000);
        }
      });
    },
    // 重置事件
    resetClick() {
      this.disabled = true;
      let search = {};
      for (let k in this.value) {
        let value = this.value[k];
        if (value instanceof Date) {
          search[k] = '';
        } else if (value instanceof Array) {
          search[k] = [];
        } else if (typeof value === 'object' && value != null) {
          search[k].value = '';
        } else {
          search[k] = '';
        }
      }
      this.$emit('input', search);
      this.$emit('resetClick'); //调用父组件的重新查询方法
      setTimeout(() => {
        this.disabled = false;
      }, 3000);
    },
  },
};
</script>
