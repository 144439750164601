import Http from '../api/http/';
export default class File extends Http {
  constructor() {
    super();
  }
  // 文件内容
  file_getOneFileVoByOriginId() {
    return super.post('file/getOneFileVoByOriginId', ...arguments);
  }
}
