import Http from '../api/http/';

export default class CreditScoreApply extends Http {
  constructor() {
    super();
  }
  /***********信用分申诉***************/
  /**
   * audit
   * @param {Array} creditScoreApplyAudit -  creditScoreApplyAudit
   */
  creditScoreApply_audit() {
    return super.post('creditScoreApply/audit', ...arguments);
  }

  /**
   * detail
   * @param {integer} creditScoreApplyId -  信用分申诉id
   */
  creditScoreApply_detail() {
    return super.post('creditScoreApply/detail', ...arguments);
  }

  /**
   * getListByPage
   * @param {Array} creditScoreApply -  creditScoreApply
   */
  creditScoreApply_getListByPage() {
    return super.post('creditScoreApply/getListByPage', ...arguments);
  }
}
