let container = '.gl-main-scroll',
  element = null;
const elementResizeDetectorMaker = require('element-resize-detector'); // 监听元素的宽度变化
const handleResize = function (e) {
  let minWidth = document.body.clientWidth - e.offsetLeft;
  let width = minWidth - 24;
  element.style.right = document.querySelector('.gl-main-scroll').scrollHeight > document.querySelector('.gl-main-scroll').clientHeight ? '30px' : '12px';
  width = document.querySelector('.gl-main-scroll').scrollHeight > document.querySelector('.gl-main-scroll').clientHeight ? `${width - 18}px` : `${width}px`;
  element.style.width = width < 1065 ? 1065 : width;
};

export default {
  inserted: function (el) {
    let scrollDom = document.querySelector(container);
    if (!scrollDom) {
      console.error(`[pagination resize] Element '${container}' was not found. `);
      return;
    }
    element = el;
    elementResizeDetectorMaker().listenTo(scrollDom, handleResize);
  },
  updated(el) {
    let scrollDom = document.querySelector(container);
    if (!scrollDom) {
      console.error(`[pagination resize] Element '${container}' was not found. `);
      return;
    }
    element = el;
    elementResizeDetectorMaker().listenTo(scrollDom, handleResize);
  },
  unbind: function (el) {
    let scrollDom = document.querySelector(container);
    if (!scrollDom) {
      return;
    }
    element = el;
    elementResizeDetectorMaker().removeListener(scrollDom, handleResize);
  },
};
