let container = '.gl-main-scroll';
let scrollDom = null,
  element = null;
const getScrollTop = function (e) {
  if (container) return e.scrollTop;
  let scrollTop = document.documentElement.scrollTop == 0 ? document.body.scrollTop : document.documentElement.scrollTop;
  return scrollTop;
};
const handleScroll = function () {
  let scrollTop = getScrollTop(scrollDom);
  let result = getOffsetTopByEl();
  if (result <= scrollTop + 48) {
    element.classList.add('gl-table-fix-head');
  } else {
    element.classList.remove('gl-table-fix-head');
  }
};

const getOffsetTopByEl = function () {
  let yPosition = 0;
  let nextElement = element;
  while (nextElement) {
    yPosition += nextElement.offsetTop;
    nextElement = nextElement.offsetParent;
  }
  return yPosition;
};

export default {
  inserted: function (el) {
    scrollDom = document.querySelector(container);
    let containerDom = scrollDom ? scrollDom : window;
    if (!containerDom) {
      console.error(`[watch scroll] Element '${container}' was not found. `);
      return;
    }
    element = el;
    containerDom.addEventListener('scroll', handleScroll, false);
  },
  updated(el) {
    scrollDom = document.querySelector(container);
    let containerDom = scrollDom ? scrollDom : window;
    if (!containerDom) {
      console.error(`[watch scroll] Element '${container}' was not found. `);
      return;
    }
    element = el;
    containerDom.addEventListener('scroll', handleScroll, false);
  },
  unbind: function () {
    let containerDom = scrollDom ? scrollDom : window;
    if (scrollDom) {
      containerDom.removeEventListener('scroll', handleScroll, false);
    }
    element = null;
  },
};
