import Http from '../api/http/';
export default class Company extends Http {
  constructor() {
    super();
  }

  /***********企业管理***************/
  /**
   * 编辑企业的基本信息
   * 新增时id为空，修改必须要有id
   * @returns
   */
  company_editCompanyInfo() {
    return super.post('company/editCompanyInfo', ...arguments);
  }
  /**
   * 获取企业基本信息
   * @param {string} id -  企业Id
   */
  company_getCompanyInfo() {
    return super.post('company/getCompanyInfo', ...arguments);
  }
  /**
   * 获取暗访系统权限
   * @param {string} id -  企业Id
   */
  company_getCompanyDefaultRoleMenuPermissionList() {
    return super.post('company/getCompanyDefaultRoleMenuPermissionList', ...arguments);
  }
  /**
   * 更新暗访系统权限
   * @returns
   */
  company_updateCompanyDefaultRoleMenuPermissionList() {
    return super.post('company/updateCompanyDefaultRoleMenuPermissionList', ...arguments);
  }
  /**
   * 查询企业私探邀请
   * @param {companyId}
   * @returns
   */
  company_getCompanyPrivateInfo() {
    return super.post('company/getCompanyPrivateInfo', ...arguments);
  }
  /**
   * 修改私探邀请
   * @param {body}
   * @returns
   */
  company_updateCompanyPrivateInfo() {
    return super.post('company/updateCompanyPrivateInfo', ...arguments);
  }
  /**
   * 查询企业报告配置
   * @param {companyId}
   * @returns
   */
  company_getCompanyField() {
    return super.post('company/getCompanyField', ...arguments);
  }
  /**
   * 保存企业报告配置
   * @param {body}
   * @returns
   */
  company_saveCompanyField() {
    return super.post('company/saveCompanyField', ...arguments);
  }
  /**
   * 查询企业报价
   * @param {companyId}
   * @returns
   */
  company_getCompanyCommissionForC() {
    return super.post('company/getCompanyCommissionForC', ...arguments);
  }
  /**
   * 修改企业报价
   * @param {body}
   * @returns
   */
  company_updateCompanyCommissionForC() {
    return super.post('company/updateCompanyCommissionForC', ...arguments);
  }
  /**
   * 企业操作记录列表
   * @param {string} companyId -  企业id
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */
  company_listOperateByPage() {
    return super.post('company/listOperateByPage', ...arguments);
  }
  /**
   * 获取企业巡店权限信息
   * @param {companyId} 企业id
   * @returns
   */
  company_getXdCompanyDefaultRoleMenuPermissionList() {
    return super.post('company/getXdCompanyDefaultRoleMenuPermissionList', ...arguments);
  }
  /**
   * 保存企业巡店权限信息
   * @param {body}
   * @returns
   */
  company_updateXdCompanyDefaultRoleMenuPermissionList() {
    return super.post('company/updateXdCompanyDefaultRoleMenuPermissionList', ...arguments);
  }

  /**
   * 企业名称校验
   * @param {string} companyId -  品牌id
   * @param {string} companyName -  品牌名称
   */
  company_checkCompanyName() {
    return super.post('company/checkCompanyName', ...arguments);
  }
  /**
   * 获取企业名称
   */
  company_getCompanyName() {
    return super.post('company/getCompanyName', ...arguments);
  }
  /**
   * 获取企业列表
   * @param {string} name -  企业名称
   * @param {string} nature -  企业性质
   * @param {string} status -  企业状态
   * @param {string} contact -  联系人
   * @param {string} preside -  负责人
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */
  company_getCompanyPage() {
    return super.post('company/getCompanyPage', ...arguments, {});
  }
  /**
   * 下拉查询所有企业（数据权限）
   * @param {integer} forbiddenComapny -  是否展示禁用其余1为不显示，0为显示，默认不传为显示
   */
  company_getList() {
    return super.post('company/getList', ...arguments);
  }
  /**
   * 企业禁用启用
   * @param {string} id -  id
   * @param {integer} status -  （0:启用  2:禁用）
   */
  company_companyOnOff() {
    return super.post('company/companyOnOff', ...arguments);
  }
  /**
   * 企业删除
   * @param {string} id -  id
   */
  company_delete() {
    return super.post('company/delete', ...arguments);
  }
  //企业嘉宾列表
  company_guest_list() {
    return super.post('company/guest/list', ...arguments);
  }
  //导出企业嘉宾
  company_guest_outport() {
    return super.get('company/guest/outport', ...arguments);
  }

  // 增加员工
  company_employee_add() {
    return super.post('company/employee/add', ...arguments);
  }
  //删除员工
  company_employee_delete() {
    return super.post('company/employee/delete', ...arguments);
  }
  //  员工详情
  company_employee_detail() {
    return super.post('company/employee/detail', ...arguments);
  }
  //  导入企业员工
  company_employee_import() {
    return super.post('company/employee/import', ...arguments);
  }
  //  员工查询
  company_employee_list() {
    return super.post('company/employee/list', ...arguments);
  }
  //  导出企业员工
  company_employee_outport() {
    return super.get('company/employee/outport', ...arguments);
  }
  //  修改员工
  company_employee_update() {
    return super.post('company/employee/update', ...arguments);
  }
  company_employee_batchDelete() {
    return super.post('company/employee/batchDelete', ...arguments);
  }
  // 企业启用(树状调用接口)
  company_operateCompany() {
    return super.post('company/operateCompany', ...arguments);
  }
  /**
   * 获取企业详情
   * @param {string} id -  企业Id
   */
  company_getCompanyVO() {
    return super.post('company/getCompanyVO', ...arguments);
  }
  //获取企业标签
  company_getAllLabelsByCompanyId() {
    return super.post('company/getAllLabelsByCompanyId', ...arguments);
  }
  //添加企业标签
  company_addCompanyLabel() {
    return super.post('company/addCompanyLabel', ...arguments);
  }
  //删除企业标签
  company_deleteCompanyLabel() {
    return super.post('company/deleteCompanyLabel', ...arguments);
  }
  //获取到所有的企业数据（无数据权限）
  company_getListAll() {
    return super.post('company/getListAll');
  }
  /**
   * 获取全部企业列表
   * @param {string} name -  企业名称
   * @param {integer} status -  企业状态
   * @param {string} userId -  负责人
   * @param {string} authorizationModule -  授权模块(0-未授权 1-暗访 2-巡店 1,2-暗访/巡店)
   * @param {string} companyCode -  企业代码
   * @param {integer} page -  页数
   * @param {integer} rows -  页面容量
   */

  company_getAllCompanyPage() {
    return super.post('company/getAllCompanyPage', ...arguments, {});
  }
  /**
   * 下拉我负责的所有企业
   * @param {integer} forbiddenComapny -  是否包括禁用企业（可不传，不传是包括） 1不包括 0包括
   */

  company_getMyList() {
    return super.post('company/getMyList', ...arguments);
  }
  //充值明细列表
  company_getWalletRechargeList() {
    return super.post('company/getWalletRechargeList', ...arguments);
  }
  //消费明细列表
  company_getWalletChangeItemList() {
    return super.post('company/getWalletChangeItemList', ...arguments);
  }
  // 导出充值明细
  company_exportRecharge() {
    return super.get('company/exportRecharge', ...arguments);
  }
  // 导出消费明细
  company_exportConsume() {
    return super.get('company/exportConsume', ...arguments);
  }
  company_exportWalletChangeItemDetails() {
    return super.get('company/exportWalletChangeItemDetails', ...arguments);
  }
  // 报酬明细
  company_getItemDetailList() {
    return super.post('company/getItemDetailList', ...arguments);
  }
  // 服务费明细
  company_getItemTemplateService() {
    return super.post('company/getItemTemplateService', ...arguments);
  }
  //  账单明细
  company_getBillDetailsByChangeItemId() {
    return super.post('company/getBillDetailsByChangeItemId', ...arguments);
  }
  /**
   * 下拉查询账号同部门的企业
   * @param {integer} forbiddenComapny -  是否包括禁用企业（可不传，不传是包括） 1不包括 0包括
   */
  company_listDepartmentCompany() {
    return super.post('company/listDepartmentCompany', ...arguments);
  }
  /**
   * 修改账号有效期，账号数
   * @param {Array} companyValidateParam -  companyValidateParam
   */
  company_updateCompanyValidate() {
    return super.post('company/updateCompanyValidate', ...arguments, {});
  }

  /**
   * 通过信用代码回显
   * @param {string} creditCode -  信用代码
   */
  company_getInnerCompanyByCreditCode() {
    return super.post('company/getInnerCompanyByCreditCode', ...arguments);
  }
  /**
   * 所有字段
   */
  company_getAllField() {
    return super.post('company/getAllField', ...arguments);
  }
  /**
   * 选中字段
   * @param {string} companyId -  企业id
   */
  company_getSelectedField() {
    return super.post('company/getSelectedField', ...arguments);
  }
  /**
   * 恢复默认
   * @param {string} companyId -  企业id
   */
  company_getDefaultFieldGroupByRoleId() {
    return super.post('company/getDefaultFieldGroupByRoleId', ...arguments);
  }
  /**
   * 企业签约合同
   * companyId 企业id
   * @returns
   */
  company_getCompanyContractList() {
    return super.post('company/getCompanyContractList', ...arguments);
  }
  /**
   * 企业服务
   * companyId 企业id
   * @returns
   */
  company_getProductBusinessLine() {
    return super.post('company/getProductBusinessLine', ...arguments);
  }
  /**
   * 合同附件list
   * @returns
   */
  company_getCompanyContractFileList() {
    return super.post('company/getCompanyContractFileList', ...arguments);
  }
  /**
   * 获取产品服务列表
   * @returns
   */
  company_productServiceList() {
    return super.post('company/productServiceList', ...arguments);
  }

  /**
   * 获取税号
   * @param {string} name -  企业名称
   * @returns
   */
  company_queryCreditCode() {
    return super.post('company/queryCreditCode', ...arguments);
  }
}
