import BaseLib from './lib/index';
import BaseApi from './lib/baseApi';
import Element from './lib/element';
import Service from './service/index';
import { classMixin } from '@/utils/classMixin';

class API extends classMixin(BaseLib, BaseApi, Element) {
  constructor() {
    super();
    this.service = new Service();
  }
}

export default {
  install(Vue) {
    if (this.installed) return;
    this.installed = true;

    Vue.api = Vue.prototype.api = new API();
    //将api属性设置为不可写，为了防止某些插件冲突
    Object.defineProperty(Vue, 'api', {
      configurable: false, // 不可删除
      writable: false, // 不可写
    });
    Object.defineProperty(Vue.prototype, 'api', {
      configurable: false, // 不可删除
      writable: false, // 不可写
    });
  },
};
