/**
 * @author LB
 * @description 企业路由
 */
export default [
  {
    path: '/enterprise/enterpriseManage',
    name: 'enterprise_enterpriseManage',
    meta: {
      title: '企业管理',
    },
    component: resolve => require(['@/views/enterprise/enterpriseManage/enterpriseManage'], resolve),
  },
  {
    path: '/enterprise/brandManage',
    name: 'enterprise_brandManage',
    meta: {
      title: '品牌管理',
    },
    component: resolve => require(['@/views/enterprise/brandManage/brandManage'], resolve),
  },
  {
    path: '/enterprise/storeManage',
    name: 'enterprise_storeManage',
    meta: {
      title: '门店管理',
    },
    component: resolve => require(['@/views/enterprise/storeManage/storeManage'], resolve),
  },
  {
    path: '/enterprise/storeCalibration',
    name: 'enterprise_storeCalibration',
    meta: {
      title: '门店校准',
    },
    component: resolve => require(['@/views/enterprise/storeCalibration/storeCalibration'], resolve),
  },
  {
    path: '/enterprise/companyMessage',
    name: 'enterprise_companyMessage',
    meta: {
      title: '企业通知',
    },
    component: resolve => require(['@/views/enterprise/companyMessage/companyMessage'], resolve),
  },
];
