import Crypto from 'crypto-js';

export default class {
  constructor(key = 'c7c5e84511fa4a4h') {
    this.crypto = Crypto;
    this.encoded = this.crypto.enc.Utf8;
    this.padding = this.crypto.pad.Pkcs7;
    this.key = key;
    this.iv = '9f98f515e4764f1e';
  }
  /**
   * 加密
   */
  encrypt(data) {
    let result = this.crypto.AES.encrypt(data, this.encoded.parse(this.key), {
      iv: this.encoded.parse(this.iv),
      padding: this.padding,
    }).toString();
    return result;
  }
  /**
   * 解密
   */
  decrypt(data) {
    let bytes = this.crypto.AES.decrypt(data, this.encoded.parse(this.key), {
      iv: this.encoded.parse(this.iv),
      padding: this.padding,
    });
    return bytes.toString(this.encoded);
  }
  /**
   * MD5
   * @param {*} data
   * @returns
   */
  md5(data) {
    let result = this.crypto.MD5(data);
    return result.toString();
  }
}
