import Http from '../api/http/';
export default class MailConfig extends Http {
  constructor() {
    super();
  }
  /**
   * 获取邮箱配置
   */
  mailConfig_getMailConfig() {
    return super.post('mailConfig/getMailConfig', ...arguments);
  }

  /**
   * 邮件配置
   * @param {Array} mailDetailDTO -  mailDetailDTO
   */
  mailConfig_saveMailConfig() {
    return super.post('mailConfig/saveMailConfig', ...arguments, {});
  }
}
