(function () {
  Number.prototype.toFixed = function (n) {
    let number = this + '';
    if (!n) {
      n = 0;
    }
    if (number.indexOf('.') == -1) {
      number += '.';
    }
    number += new Array(n + 1).join('0'); //后面补0
    if (new RegExp('^(-|\\+)?(\\d+(.\\d{0,' + (n + 1) + '})?)\\d*$').test(number)) {
      number = '0' + RegExp.$2;
      let pm = RegExp.$1;
      let a = RegExp.$3.length;
      let b = true;
      if (a == n + 2) {
        a = number.match(/\d/g);
        if (parseInt(a[a.length - 1]) > 4) {
          for (let i = a.length - 2; i >= 0; i--) {
            a[i] = parseInt(a[i]) + 1;
            if (a[i] == 10) {
              a[i] = 0;
              b = i != 1;
            } else break;
          }
        }
        number = a.join('').replace(new RegExp('(\\d+)(\\d{' + n + '})\\d$'), '$1.$2');
      }
      if (b) number = number.substr(1);
      return (pm + number).replace(/\.$/, '');
    }
    return this + '';
  };
})();
