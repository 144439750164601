import Http from '../api/http/';
export default class PrivateCustomerApproval extends Http {
  constructor() {
    super();
  }
  /***********私探审核***************/
  /**
   * 审核
   * @param {string} applyId -  申请Id
   * @param {integer} approvalStatus -  审核状态，1通过,2不通过
   * @param {string} approvalOpinion -  审核意见
   */
  privateCustomerApproval_approval() {
    return super.post('privateCustomerApproval/approval', ...arguments);
  }

  /**
   * 查看-审核意见
   * @param {string} applyId -  申请Id
   */
  privateCustomerApproval_approvalRecordList() {
    return super.post('privateCustomerApproval/approvalRecordList', ...arguments);
  }

  /**
   * 批量审核
   * @param {Array} privateDetectiveApprovalDTO -  privateDetectiveApprovalDTO
   */
  privateCustomerApproval_batchApproval() {
    return super.post('privateCustomerApproval/batchApproval', ...arguments);
  }

  /**
   * 用户信息
   * @param {string} uid -  uid
   */
  privateCustomerApproval_getCustomerInfo() {
    return super.post('privateCustomerApproval/getCustomerInfo', ...arguments);
  }

  /**
   * 获取关联对象信息
   * @param {string} uid -  uid
   */
  privateCustomerApproval_getRelateUserlist() {
    return super.post('privateCustomerApproval/getRelateUserlist', ...arguments);
  }

  /**
   * 私探审核列表
   * @param {array} companyIds -  undefined
   * @param {string} companyId -  企业id
   * @param {string} realName -  姓名
   * @param {string} nikeName -  昵称
   * @param {string} phone -  手机号
   * @param {string} cityId -  城市id
   * @param {integer} approvalStatus -  审核状态0待审核，1通过，2未通过
   * @param {string} applyTimeBegin -  申请开始时间,yyyy-MM-dd
   * @param {string} applyTimeEnd -  申请结束时间,yyyy-MM-dd
   * @param {integer} applyOrigin -  0 APP申请,1二维码邀请
   * @param {string} cityName -  城市名称
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  privateCustomerApproval_list() {
    return super.post('privateCustomerApproval/list', ...arguments);
  }

  /**
   * 导出
   * @param {array} companyIds -  undefined
   * @param {string} companyId -  企业id
   * @param {string} realName -  姓名
   * @param {string} nikeName -  昵称
   * @param {string} phone -  手机号
   * @param {string} cityId -  城市id
   * @param {integer} approvalStatus -  审核状态0待审核，1通过，2未通过
   * @param {string} applyTimeBegin -  申请开始时间,yyyy-MM-dd
   * @param {string} applyTimeEnd -  申请结束时间,yyyy-MM-dd
   * @param {integer} applyOrigin -  0 APP申请,1二维码邀请
   * @param {string} cityName -  城市名称
   * @param {integer} page -  页数
   * @param {integer} rows -  行数
   */
  privateCustomerApproval_export() {
    return super.get('privateCustomerApproval/export', ...arguments);
  }
}
