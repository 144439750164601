import Http from '@/api/http';

export default class ThirdAppConfig extends Http {
  constructor() {
    super();
  }

  /**
   * 第三方列表
   * @returns
   */
  thirdAppConfig_getThirdAppConfigList() {
    return super.post('thirdAppConfig/getThirdAppConfigList', ...arguments);
  }
  /**
   * 新增
   * @returns
   */
  thirdAppConfig_addThirdAppConfig() {
    return super.post('thirdAppConfig/addThirdAppConfig', ...arguments);
  }
  /**
   * 修改
   * @returns
   */
  thirdAppConfig_updateThirdAppConfig() {
    return super.post('thirdAppConfig/updateThirdAppConfig', ...arguments);
  }
  /**
   * 修改状态
   * @returns
   */
  thirdAppConfig_updateThirdAppConfigStatus() {
    return super.post('thirdAppConfig/updateThirdAppConfigStatus', ...arguments);
  }
}
